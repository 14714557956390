import React, { Component } from "react";

class Map extends Component {
	constructor(props) {
		super(props);

		this.geocoder = null;
		this.map = null;
		this.marker = null;

		this.state = {
			location: '',
			lnglat: ''
		}
	}

	componentDidMount() {
		this.setState({ location: this.props.location, lnglat: this.props.lnglat })

		this.map = new AMap.Map("container", {
			resizeEnable: true
		});

		this.geocoder = new AMap.Geocoder({
			city: "010", //城市设为北京，默认：“全国”
			radius: 1000 //范围，默认：500
		});

		this.marker = new AMap.Marker();;

		this.map.on('click', (e) => {
			document.getElementById('lnglat').value = e.lnglat;
			this.regeoCode();
		})
	}

	regeoCode = () => {
		let lnglat = document.getElementById('lnglat').value.split(',');
		this.map.add(this.marker);
		this.marker.setPosition(lnglat);

		this.geocoder.getAddress(lnglat, (status, result) => {
			if (status === 'complete' && result.regeocode) {
				let address = result.regeocode.formattedAddress;
				document.getElementById('address').value = address;
				this.props.getLocation(lnglat, address);
			}
		});
	}

	render() {
		return (
			<div>
				<div id="container" style={{ height: '500px', width: '850px', display: 'flex', justifyContent: 'center' }}>
				</div>
				<div class="input-item">
					<div class="input-item-prepend"><span class="input-item-text">经纬度</span></div>
					<input id='lnglat' type="text" value='116.39,39.9' style={{ width: 500 }} value={this.props.lnglat} />
				</div>
				<div class="input-item">
					<div class="input-item-prepend"><span class="input-item-text" >地址</span></div>
					<input id='address' type="text" disabled style={{ width: 500 }} value={this.props.location} />
				</div>
			</div>
		);
	}
}

export default Map;