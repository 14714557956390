import moment from 'moment';

export const disabledHours = startTime => {
	let hours = []
	let time = startTime.hour()
	for (var i = 0; i < parseInt(time); i++) {
		hours.push(i)
	}
	return hours
}

export const disabledMinutes = (startTime, selectedHour) => {
	let minutes = []
	if (selectedHour == parseInt(startTime.hour())) {
		for (var i = 0; i < parseInt(startTime.minute()); i++) {
			minutes.push(i)
		}
	}
	return minutes
}

export const disabledSeconds = (startTime, selectedHour, selectedMinute) => {
	let second = []
	if (selectedHour == startTime.hour() && selectedMinute == startTime.minute()) {
		for (var i = 0; i <= startTime.second(); i++) {
			second.push(i)
		}
	}
	return second
}


export const disabledBeginMinutes = (startTime, selectedHour, isStart) => {
	let minutes = []
	if (isStart) {
		if (moment(startTime).startOf('day').isSame(moment().startOf('day'))) {
			if (selectedHour == parseInt(moment().hour())) {
				for (var i = 0; i < parseInt(moment().minute()); i++) {
					minutes.push(i)
				}
			}
		}
	}

	return minutes
}