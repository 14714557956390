import React, { Component } from 'react';
import { Typography } from 'antd';
import pkg from '../../../package.json';
import logo from '@/images/s365logo.png';
import _ from 'lodash';
import { AppContext } from './Contexts';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation, withTranslation } from 'react-i18next';

const { Text } = Typography;


function About(props) {
	const { t } = useTranslation();
	const modules = [
		{ label: t('deviceMap'), value: 'deviceMap' },
		{ label: t('systemMap'), value: 'systemDiagram' },
		{ label: t('FileManager'), value: 'document' },
		{ label: t('health'), value: 'health' }
	];
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	let activatedModels = [];
	if (props.company && props.company.license) {
		for (let i in props.company.license) {
			let value = props.company.license[i];
			if (_.isBoolean(value) && value) {
				let model = _.find(modules, module => module.value === i);
				if (model && !_.includes(activatedModels, model.label)) {
					activatedModels.push(model);
				}
			}
		}
	}

	return (
		<div className='container'>
			<div className='main-wrapper' style={{ padding: 40 }}>
				<img src={logo} style={{ width: '128px' }} />
				<h1 style={{ fontSize: 48, marginTop: 16, marginBottom: 0 }}>{t('welcomeTitle')}</h1>
				<Text type='secondary' style={{ fontSize: 16 }}>version {pkg.version}</Text>
			</div>
		</div>
	);
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		static contextType = AppContext;
		state = {
			company: null
		}

		async loadInfomation() {
			let data = await this.transportLayer.getUserCompany(this.me.company.id);
			let company = data;
			if (company.license && _.isString(company.license)) {
				company.license = JSON.parse(company.license);
			}
			this.setState({ company });
		}

		render() {
			return <WrappedComponent company={this.state.company} />;
		}
	});
};

export default hoc(About);
