import React, { Component } from 'react';
import { List, Badge, message } from 'antd';
import { AppContext } from '../Contexts';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import AppContextJS from '../AppContext';
import emitter from '../emitter';

function NoticeCard(props) {
	return (
		<div style={{ width: 400 }}>
			<Scrollbars style={{ height: 280 }}>
				<List
					style={{ padding: 0 }}
					dataSource={props.data}
					renderItem={item =>
						<List.Item>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								{item.platform.unread ? <Badge color='red' /> : <span style={{ marginLeft: 14 }}></span>}
								<a onClick={() => props.read(item.id)}
									href={item.source.type === 'booking' ?
										`${props.homePage}/app/booking/#/local/booking/${item.source.target}/view` : `${props.homePage}/app/device/#/system/alarms`}>
									{item.platform.content}</a>
							</div>
						</List.Item>
					}
				/>
			</Scrollbars>
		</div>
	);
}

let hoc = WrappedComponent => {
	return class EnhancedComponet extends Component {
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				data: this.props.notices,
				homePage: null
			}
		}

		componentWillReceiveProps(next) {
			if (next.notices !== this.props.notices) {
				this.setState({ data: next.notices })
			}
		}

		componentDidMount = async () => {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			let homePage = AppContextJS.instance.homePage;
			this.setState({ homePage })
		}

		fetchNotices = async () => {
			let data = await this.transportLayer.fetchNotices({ page: 1, size: 20, user: this.me.id, type: 'platform', unread: true });
			this.setState({ data: data.docs });
		}

		read = async (id) => {
			try {
				await this.transportLayer.read(id, false);
				await this.fetchNotices();
			} catch (error) {
				message.error(error);
			}
		}

		render() {
			return <WrappedComponent
				data={this.state.data}
				read={this.read}
				homePage={this.state.homePage}
			/>
		}
	}
}

export default hoc(NoticeCard);