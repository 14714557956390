import React, { Component, useState, useEffect, useRef } from 'react';
import { AppContext } from '../Contexts';
import { Table, Button, Badge, Pagination, Popconfirm, Select, DatePicker, message, PageHeader } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { ApartmentOutlined, FieldTimeOutlined, ScheduleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import AppContextJS from '../AppContext';
import emitter from '../emitter';
import { useTranslation, withTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const { Option } = Select;

function Notifications(props) {
	const { t } = useTranslation();
	const [height, setHeight] = useState(0);
	const ref = useRef(null);
	useEffect(() => {
		setHeight(ref.current.clientHeight - 80)
	});

	const columns = [
		{
			title: 'platform.content', key: 'content', dataIndex: 'platform.content',
			render: (e, record) => {
				return <div>
					{record.platform.unread ? <Badge color='red' /> : <span style={{ marginLeft: 14 }}></span>}
					{record.source.type === 'booking' ? <ScheduleOutlined /> : <ApartmentOutlined />}
					<a style={{ marginLeft: 10 }}
						onClick={() => props.read(record.id, true)}
						href={record.source.type === 'booking' ?
							`${props.homePage}/app/booking/#/local/booking/${record.source.target}/view` : `${props.homePage}/app/device`}>
						{e}</a>
				</div>
			}
		},
		{
			title: 'createdAt', key: 'createdAt', dataIndex: 'createdAt', align: 'right',
			render: (e) => {
				return (
					<><FieldTimeOutlined style={{ marginRight: 10 }} />{moment.unix(e).format('YYYY-MM-DD HH:mm')}</>
				);
			}
		}
	];

	return (
		<div className='container' style={{ overflow: 'hidden' }}>
			<div style={{ paddingLeft: 24, paddingRight: 24, border: '1px solid #E3E9EE', height: '8%', background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<PageHeader
					style={{ padding: 0 }}
					onBack={() => props.history.goBack()}
					title={t('allNotications')}
				/>
				{/* <span style={{ display: 'flex', alignItem: 'center', alignContent: 'center', fontSize: 20, fontWeight: 400 }}>{t('allNotifications)}</span>
				<div>
					<Button type='primary' style={{ marginLeft: 10 }} onClick={() => props.history.goBack()}><ArrowLeftOutlined /> {t('back')}</Button>
				</div> */}
			</div>
			<div style={{ paddingLeft: 24, paddingRight: 24, border: '1px solid #E3E9EE', height: '8%', background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItem: 'spaceBetween', flexDirection: 'row' }}>
					<RangePicker style={{ marginRight: 10 }} onChange={props.onRangeChange} />
					<Select style={{ marginRight: 10, width: 100 }} value={props.unread} onChange={props.statusOnChange}>
						<Option value='all'>{t('allNotications')}</Option>
						<Option value={true}>{t('unreadNotifications')}</Option>
						<Option value={false}>{t('readNotifications')}</Option>
					</Select>
					<Popconfirm
						title={t('confirmToMarkAllAsRead')}
						onConfirm={props.readAll}
						okText={t('confirm')}
						cancelText={t('cancel')}
					>
						<Button type='primary' style={{ marginRight: 10 }}>{t('markAllAsRead')}</Button>
					</Popconfirm>
					<Popconfirm
						title={t('confirmToMarkThisPageAsRead')}
						onConfirm={props.readPages}
						okText={t('confirm')}
						cancelText={t('cancel')}
					>
						<Button>{t('markThisPageAsRead')}</Button>
					</Popconfirm>
				</div>
				<Pagination
					style={{ display: 'flex', justifyContent: 'center', float: 'Right' }}
					showTotal={total => t('itemWithCount', { count: total })}
					current={props.page}
					pageSize={props.size}
					total={props.totalDocs}
					onChange={(e) => props.fetchNotices(e)} />
			</div>
			<div ref={ref} style={{ height: '92%', background: '#fff', overflow: 'hidden', display: 'flex', flexDirection: 'column', padding: '0 20px', width: '100%' }}>
				<Table
					size='middle'
					showHeader={false}
					style={{ width: '100%' }}
					pagination={false}
					rowKey='id'
					columns={columns}
					dataSource={props.notices}
					scroll={{ y: height }}
				/>
			</div>
		</div>
	);
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponet extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				notices: [],
				page: 1,
				size: 20,
				totalDocs: 0,
				unread: 'all',
				type: 'platform'
			}
		}

		componentDidMount = async () => {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.homePage = AppContextJS.instance.homePage;

			await this.fetchNotices(this.state.page);
			emitter.on('platform_changed', this.onNewEvent);
		}

		onNewEvent = async () => {
			await this.fetchNotices({ page: this.state.page });
		}

		componentWillUnmount() {
			emitter.off('platform_changed', this.onNewEvent);
		}

		fetchNotices = async (page) => {
			let { begin, end, size, type, unread } = this.state;
			let user = this.me.id;
			let data = await this.transportLayer.fetchNotices({ page, size, user, type, begin, end, unread });
			this.setState({ notices: data.docs, page: data.page, totalDocs: data.totalDocs })
			await this.props.reload();
		}

		statusOnChange = (unread) => {
			this.setState({ unread }, async () => {
				await this.fetchNotices({ page: this.state.page });
			})
		}

		readAll = async () => {
			try {
				await this.transportLayer.readAll(this.me.id);
				message.success(this.t('markedAllAsRead'));
				await this.fetchNotices(1);
			} catch (error) {
				message.error('标记所有为已读失败')
			}
		}

		readPages = async () => {
			try {
				let idList = _.chain(_.map(this.state.notices, 'id')).join(',').value();
				await this.transportLayer.readPages(idList);
				message.success(this.t('markedAllAsRead'));
				await this.fetchNotices(1);
			} catch (error) {
				message.error('标记本页为已读失败')
			}
		}

		onRangeChange = async (e) => {
			let begin = null;
			let end = null;
			if (!_.isEmpty(e)) {
				begin = moment(_.first(e)).startOf('day').unix();
				end = moment(_.last(e)).endOf('day').unix();
			};

			this.setState({ begin, end }, async () => {
				await this.fetchNotices(1);
			})
		}

		read = async (id) => {
			try {
				await this.transportLayer.read(id, false);
				await this.fetchNotices(1);
			} catch (error) {
				message.error(error);
			}
		}

		render() {
			return <WrappedComponent
				history={this.props.history}
				page={this.state.page}
				size={this.state.size}
				totalDocs={this.state.totalDocs}
				notices={this.state.notices}
				unread={this.state.unread}
				fetchNotices={this.fetchNotices}
				statusOnChange={this.statusOnChange}
				readAll={this.readAll}
				readPages={this.readPages}
				onRangeChange={this.onRangeChange}
				homePage={this.homePage}
				read={this.read}
			/>
		}
	})
}

export default withRouter(hoc(Notifications));