import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { meetingStates } from '../meetings/Role'
import { withTranslation } from 'react-i18next';

const { Option } = Select;

function SelectMeetingState(props) {
	const { t } = props;
	return <Select
		value={props.value}
		placeholder={t('selectMeetingState')}
		style={{ width: 150 }}
		onChange={value => props.selectedState(value)}
	>
		{
			_.map(props.states, select => {
				return <Option value={select.state}> {select.name}</Option>
			})
		}
	</Select>
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends React.Component {
		state = {
			states: meetingStates
		}
		selectedState = value => {
			this.props.selectMeetingState(value)
		}

		render() {
			return <WrappedComponent
				{...this.props}
				states={this.state.states}
				selectedState={this.selectedState}
			/>
		}
	}
	)
}

export default hoc(SelectMeetingState)