import React, { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { PageHeader, Tabs } from 'antd';
import { Column, Pie, Bar, measureTextWidth } from '@ant-design/charts';
import { AppContext } from '../../Contexts';
import AppContextJS from '../../AppContext';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import DateTimePickerGroup from '../../components/DateTimePickerGroup';
import { weeks } from '../../../../device/src/share/Helper';
import { meetingSource, meetingState } from '../../share/Helper';
import { useTranslation, withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

function MeetingReports(props) {
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });

	var bookingByTimeConfig = {
		maxColumnWidth: 30,
		data: props.bookingByTime,
		xField: 'date',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var vcBookingByTimeConfig = {
		maxColumnWidth: 30,
		data: props.vcBookingByTime,
		xField: 'date',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var bookingBySpaceConfig = {
		maxBarWidth: 50,
		data: props.bookingBySpace,
		xField: 'count',
		yField: 'category',
		seriesField: 'category',
		color: ['#687595', '#BB6BD9', '#F39600', '#6E94F2', '#84D7AE'],
		legend: false,
		scrollbar: { type: 'vertical' },
		tooltip: {
			formatter: (value) => {
				return { name: t('bookingCount'), value: value.count }
			},
		}
	};

	var bookingByAdvanceConfig = {
		maxColumnWidth: 30,
		data: props.bookingByAdvance,
		xField: 'ahead',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var vcBookingByAdvanceConfig = {
		maxColumnWidth: 30,
		data: props.vcBookingByAdvance,
		xField: 'ahead',
		yField: 'count',
		seriesField: '',
		maxColumnWidth: 30,
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var bookingByDurationConfig = {
		maxColumnWidth: 30,
		data: props.bookingByDuration,
		xField: 'duration',
		yField: 'count',
		seriesField: '',
		color: '#84D7AE',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var vcBookingByDurationConfig = {
		maxColumnWidth: 30,
		data: props.vcBookingByDuration,
		xField: 'duration',
		yField: 'count',
		seriesField: '',
		color: '#84D7AE',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.count);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			value: { alias: t('bookingCount') }
		}
	};

	var bookingSortedBySpaceAscendConfig = {
		maxColumnWidth: 30,
		data: props.bookingSortedBySpaceAscend,
		xField: 'roomName',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var bookingSortedBySpaceDescendConfig = {
		maxColumnWidth: 30,
		data: props.bookingSortedBySpaceDescend,
		xField: 'roomName',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var bookingByRatioConfig = {
		appendPadding: 10,
		data: props.bookingByRatio,
		angleField: 'count',
		colorField: 'state',
		radius: 1,
		legend: true,
		innerRadius: 0.6,
		label: {
			type: 'inner',
			offset: '-50%',
			content: function content(_ref) {
				var percent = _ref.percent;
				return ''.concat((percent * 100).toFixed(0), '%');
			},
			style: {
				textAlign: 'center',
				fontSize: 14,
			},
		},
		interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
		statistic: {
			title: {
				offsetY: -4,
				customHtml: function customHtml(container, view, datum) {
					var _container$getBoundin = container.getBoundingClientRect(),
						width = _container$getBoundin.width,
						height = _container$getBoundin.height;
					var d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
					var text = `<b><h3>${t('roomSchedule')}</h3></b>`;
					return renderStatistic(d, text, { fontSize: 28 });
				}
			},
			content: {
				offsetY: 4,
				style: { fontSize: "32px" },
				customHtml: function customHtml(container, view, datum, data) {
					var _container$getBoundin2 = container.getBoundingClientRect(),
						width = _container$getBoundin2.width;
					var text = datum
						? "".concat(datum.count)
						: "".concat(
							data.reduce(function (r, d) {
								return r + d.count;
							}, 0)
						);
					return renderStatistic(width, text, { fontSize: 32 });
				}
			}
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
	};
	function renderStatistic(containerWidth, text, style) {
		var _measureTextWidth = (0, measureTextWidth)(text, style),
			textWidth = _measureTextWidth.width,
			textHeight = _measureTextWidth.height;
		var R = containerWidth / 2;
		var scale = 1;
		if (containerWidth < textWidth) {
			scale = Math.min(
				Math.sqrt(
					Math.abs(
						Math.pow(R, 2) /
						(Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
					)
				),
				1
			);
		}
		var textStyleStr = "width:".concat(containerWidth, "px;");
		return '<div style="'
			.concat(textStyleStr, ";font-size:")
			.concat(scale, "em;line-height:")
			.concat(scale < 1 ? 1 : "inherit", ';">')
			.concat(text, "</div>");
	}
	var vcBookingByRatioConfig = {
		appendPadding: 10,
		data: props.vcBookingByRatio,
		angleField: 'count',
		colorField: 'state',
		radius: 1,
		legend: true,
		innerRadius: 0.6,
		content: 'count',
		label: {
			type: 'inner',
			offset: '-50%',
			// content: '{value}',
			content: function content(_ref) {
				var percent = _ref.percent;
				return ''.concat((percent * 100).toFixed(0), '%');
			},
			style: {
				textAlign: 'center',
				fontSize: 14,
			},
		},
		interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
		statistic: {
			title: {
				offsetY: -4,
				customHtml: function customHtml(container, view, datum) {
					var _container$getBoundin = container.getBoundingClientRect(),
						width = _container$getBoundin.width,
						height = _container$getBoundin.height;
					var d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
					var text = `<b><h3>${t('videoConference')}</h3></b>`;
					return renderStatistic(d, text, { fontSize: 28 });
				}
			},
			content: {
				offsetY: 4,
				style: { fontSize: "32px" },
				customHtml: function customHtml(container, view, datum, data) {
					var _container$getBoundin2 = container.getBoundingClientRect(),
						width = _container$getBoundin2.width;
					var text = datum
						? "".concat(datum.count)
						: "".concat(
							data.reduce(function (r, d) {
								return r + d.count;
							}, 0)
						);
					return renderStatistic(width, text, { fontSize: 32 });
				}
			}
		},
		scrollbar: { type: 'horizontal' },
		meta: {
			count: { alias: t('bookingCount') }
		}
		// tooltip: {
		// 	formatter: (value) => {
		// 		return { name: t('bookingCount'), value: value.count }
		// 	},
		// }
	};

	var bookingByMethodConfig = {
		maxColumnWidth: 30,
		data: props.bookingByMethod,
		xField: 'source',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	var vcBookingByMethodConfig = {
		maxColumnWidth: 30,
		data: props.vcBookingByMethod,
		xField: 'source',
		yField: 'count',
		seriesField: '',
		color: '#5B8FF9',
		label: {
			content: function content(originData) {
				var val = parseFloat(originData.value);
				if (val < 0.05) {
					return (val * 100).toFixed(1) + '%';
				}
			},
			offset: 10,
		},
		legend: false,
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		meta: {
			count: { alias: t('bookingCount') }
		}
	};

	return (
		<div className='container'>
			<div className='main-wrapper d-flex' style={{ display: 'flex', flexDirection: 'column' }}>
				<PageHeader
					style={{ borderBottom: '1px solid #f1f1f1' }}
					title={t('reports')}
					extra={[
					]}
				>
				</PageHeader>
				<div style={{ padding: '0 24px 24px 24px', flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
					<DateTimePickerGroup onChange={props.dateChange} rangeType={props.rangeType} />
					<Scrollbars>
						<div style={{ padding: 20 }}>
							<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingByTime')}</div>
							<Tabs defaultActiveKey="1">
								<TabPane tab={t('space')} key="1">
									<Column {...bookingByTimeConfig} />
								</TabPane>
								<TabPane tab={t('videoConference')} key="2">
									<Column {...vcBookingByTimeConfig} />
								</TabPane>
							</Tabs>
						</div>
						<div style={{ padding: 20 }}>
							<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingBySpace')}</div>
							<Bar {...bookingBySpaceConfig} />
						</div>
						<div style={{ padding: 20 }}>
							<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingByAdvance')}</div>
							<Tabs defaultActiveKey="1">
								<TabPane tab={t('space')} key="1">
									<Column {...bookingByAdvanceConfig} />
								</TabPane>
								<TabPane tab={t('videoConference')} key="2">
									<Column {...vcBookingByAdvanceConfig} />
								</TabPane>
							</Tabs>
						</div>
						<div style={{ padding: 20 }}>
							<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingByDuration')}</div>
							<Tabs defaultActiveKey="1">
								<TabPane tab={t('space')} key="1">
									<Column {...bookingByDurationConfig} />
								</TabPane>
								<TabPane tab={t('videoConference')} key="2">
									<Column {...vcBookingByDurationConfig} />
								</TabPane>
							</Tabs>
						</div>
						<div style={{ padding: 20 }}>
							<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingBySpaceOrder')}</div>
							<Tabs defaultActiveKey="1">
								<TabPane tab={t('mostBooked')} key="1">
									<Column {...bookingSortedBySpaceAscendConfig} />
								</TabPane>
								<TabPane tab={t('leastBooked')} key="2">
									<Column {...bookingSortedBySpaceDescendConfig} />
								</TabPane>
							</Tabs>
						</div>
						<div style={{ display: 'flex', padding: 20, justifyContent: 'space-between' }}>
							<div style={{ width: "50%" }}>
								<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingByMethod')}</div>
								<Tabs defaultActiveKey="1" >
									<TabPane tab={t('space')} key="1">
										<Column {...bookingByMethodConfig} />
									</TabPane>
									<TabPane tab={t('videoConference')} key="2">
										<Column {...vcBookingByMethodConfig} />
									</TabPane>
								</Tabs>
							</div>
							<div style={{ width: '45%' }}>
								<div style={{ fontSize: 18, fontWeight: 600, paddingBottom: 24 }}>{t('bookingByRatio')}</div>
								<div style={{ display: 'flex', padding: 20, justifyContent: 'space-between' }} >
									<div style={{ width: "50%" }}>
										<Pie {...bookingByRatioConfig} />
									</div>
									<div style={{ width: "50%" }}>
										<Pie {...vcBookingByRatioConfig} />
									</div>
								</div>
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>
		</div>
	);
}


let hoc = (WrappedComponent) => {
	return withTranslation()(class EnhancedComponent extends Component {
		static contextType = AppContext;
		get t() { return this.props.t; }
		constructor(props) {
			super(props);
			this.state = {
				rangeType: 'month',
				selectedWeek: moment(),
				selectedMonth: moment(),
				selectedYear: moment(),
				lineData: null,
				begin: moment().startOf('month').unix(),
				end: moment().endOf('month').unix(),
				unit: null,
				group: null,
				bookingByTime: [],
				vcBookingByTime: [],
				bookingBySpace: [],
				bookingByAdvance: [],
				vcBookingByAdvance: [],
				bookingByDuration: [],
				vcBookingByDuration: [],
				bookingSortedBySpaceDescend: [],
				bookingSortedBySpaceAscend: [],
				bookingByMethod: [],
				vcBookingByMethod: [],
				bookingByRatio: [],
				vcBookingByRatio: [],
			};
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;

			this.fetchAllStats();
			// Refresh every minutes mainly considering fromNow
			this.timer = setInterval(() => {
				this.forceUpdate();
			}, 1000 * 60);
		}

		componentWillUnmount() {
			if (this.timer) {
				clearInterval(this.timer);
			}
		}

		dateChange = (data) => {
			let rangeType = '';
			let unit = '';

			switch (data.type) {
				case 'year':
					rangeType = 'year';
					unit = 'month';
					break;
				case 'month':
					rangeType = 'month';
					unit = 'day';
					break;
				case 'week':
					rangeType = 'week';
					unit = 'day';
					break;
				default:
					break;
			}

			let begin = moment(data[data.type]).startOf(data.type).unix();
			let end = moment(data[data.type]).endOf(data.type).unix();

			this.setState({ rangeType, begin, end, unit }, async () => {
				await this.fetchAllStats();
			});
		}

		fetchAllStats = async () => {
			this.fetchBookingByTime();
			this.fetchVcBookingByTime();
			this.fetchBookingBySpace();
			this.fetchBookingByAdvance();
			this.fetchVcBookingByAdvance();
			this.fetchBookingByDuration();
			this.fetchVcBookingByDuration();
			this.fetchBookingSortedBySpaceAscend();
			this.fetchBookingSortedBySpaceDescend();
			this.fetchBookingByMethod();
			this.fetchVcBookingByMethod();
			this.fetchBookingByRatio();
			this.fetchVcBookingByRatio();

		}

		fetchBookingByTime = async () => {
			let bookingByTime = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'time', this.state.unit);
			bookingByTime = _.map(bookingByTime, x => {
				return {
					'date': this.state.rangeType === 'year' ? moment.unix(x.date).format('M') + '月' : this.state.rangeType === 'month' ? moment.unix(x.date).format('D') + '日' : this.state.rangeType === 'week' ? weeks[moment.unix(x.date).isoWeekday()] : '',
					count: x.count
				}
			});
			this.setState({ bookingByTime });
		}

		fetchVcBookingByTime = async () => {
			let vcBookingByTime = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'vc', 'time', this.state.unit);
			vcBookingByTime = _.map(vcBookingByTime, x => {
				return {
					'date': this.state.rangeType === 'year' ? moment.unix(x.date).format('M') + '月' : this.state.rangeType === 'month' ? moment.unix(x.date).format('D') + '日' : this.state.rangeType === 'week' ? '周' + moment.unix(x.date).isoWeekday() : '',
					count: x.count
				}
			});
			this.setState({ vcBookingByTime });
		}

		fetchBookingBySpace = async () => {
			let bookingBySpace = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'category');
			this.setState({ bookingBySpace });
		}


		formatDataAhead(source) {
			let data = [];
			data.push({ ahead: this.t('under10Mins'), count: _.find(source, { ahead: 600 }) && _.find(source, { ahead: 600 }).count });
			data.push({ ahead: this.t('underHalfHour'), count: _.find(source, { ahead: 1800 }) && _.find(source, { ahead: 1800 }).count });
			data.push({ ahead: this.t('UnderAnHour'), count: _.find(source, { ahead: 3600 }) && _.find(source, { ahead: 3600 }).count });
			data.push({ ahead: this.t('under2Hour'), count: _.find(source, { ahead: 7200 }) && _.find(source, { ahead: 7200 }).count });
			data.push({ ahead: this.t('under6Hours'), count: _.find(source, { ahead: 21600 }) && _.find(source, { ahead: 21600 }).count });
			data.push({ ahead: this.t('underHalfDay'), count: _.find(source, { ahead: 43200 }) && _.find(source, { ahead: 43200 }).count });
			data.push({ ahead: this.t('under1Day'), count: _.find(source, { ahead: 'other' }) && _.find(source, { ahead: 'other' }).count });
			data.push({ ahead: this.t('overOneDay'), count: _.find(source, { ahead: 0 }) &&  _.find(source, { ahead: 0 }).count });
			return data; 
		}

		formatDataDuration(source) {
			let data = [];
			data.push({ duration: this.t('under10Mins'), count: _.find(source, { duration: 600 }).count });
			data.push({ duration: this.t('underHalfHour'), count: _.find(source, { duration: 1800 }).count });
			data.push({ duration: this.t('UnderAnHour'), count: _.find(source, { duration: 3600 }).count });
			data.push({ duration: this.t('under2Hour'), count: _.find(source, { duration: 7200 }).count });
			data.push({ duration: this.t('under6Hours'), count: _.find(source, { duration: 21600 }).count });
			data.push({ duration: this.t('underHalfDay'), count: _.find(source, { duration: 43200 }).count });
			data.push({ duration: this.t('under1Day'), count: _.find(source, { duration: 'other' }).count });
			return data;
		}

		fetchBookingByAdvance = async () => {
			let bookingByAdvance = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'ahead');
			let data = this.formatDataAhead(bookingByAdvance);
			this.setState({ bookingByAdvance: data });
		}

		fetchVcBookingByAdvance = async () => {
			let vcBookingByAdvance = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'vc', 'ahead');
			let data = this.formatDataAhead(vcBookingByAdvance);
			this.setState({ vcBookingByAdvance: data });
		}

		fetchBookingByDuration = async () => {
			let bookingByDuration = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'duration');
			console.log('bookingByDuration', bookingByDuration)
			let data = this.formatDataDuration(bookingByDuration);
			console.log('data', data)
			this.setState({ bookingByDuration: data });
		}

		fetchVcBookingByDuration = async () => {
			let vcBookingByDuration = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'vc', 'duration');
			let data = this.formatDataDuration(vcBookingByDuration);
			this.setState({ vcBookingByDuration: data });
		}

		fetchBookingSortedBySpaceAscend = async () => {
			let bookingSortedBySpaceAscend = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'room', null, -1);
			this.setState({ bookingSortedBySpaceAscend });
		}

		fetchBookingSortedBySpaceDescend = async () => {
			let bookingSortedBySpaceDescend = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'room', null, 1);
			this.setState({ bookingSortedBySpaceDescend });
		}

		fetchBookingByMethod = async () => {
			let bookingByMethod = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'source');
			_.map(bookingByMethod, data => {
				data.source = meetingSource[data.source];
			})
			this.setState({ bookingByMethod });
		}

		fetchVcBookingByMethod = async () => {
			let vcBookingByMethod = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'vc', 'source');
			_.map(vcBookingByMethod, data => {
				data.source = meetingSource[data.source];
			})
			this.setState({ vcBookingByMethod });
		}

		fetchBookingByRatio = async () => {
			let bookingByRatio = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'booking', 'state');
			_.map(bookingByRatio, data => {
				data.state = meetingState[data.state];
			})
			this.setState({ bookingByRatio });
		}

		fetchVcBookingByRatio = async () => {
			let vcBookingByRatio = await this.transportLayer.getBookingStat(this.state.begin, this.state.end, 'vc', 'state');
			_.map(vcBookingByRatio, data => {
				data.state = meetingState[data.state] ? meetingState[data.state] : this.t('tbd');
			})
			this.setState({ vcBookingByRatio });
		}


		render() {
			return <WrappedComponent
				rangeType={this.state.rangeType}
				selectedWeek={this.state.selectedWeek}
				selectedMonth={this.state.selectedMonth}
				selectedYear={this.state.selectedYear}

				lineData={this.state.lineData}
				dateChange={this.dateChange}

				beign={this.state.begin}
				end={this.state.end}
				unit={this.state.unit}
				group={this.state.group}

				bookingByTime={this.state.bookingByTime}
				vcBookingByTime={this.state.vcBookingByTime}
				bookingBySpace={this.state.bookingBySpace}
				bookingByAdvance={this.state.bookingByAdvance}
				vcBookingByAdvance={this.state.vcBookingByAdvance}
				bookingByDuration={this.state.bookingByDuration}
				vcBookingByDuration={this.state.vcBookingByDuration}
				bookingSortedBySpaceAscend={this.state.bookingSortedBySpaceAscend}
				bookingSortedBySpaceDescend={this.state.bookingSortedBySpaceDescend}
				bookingByMethod={this.state.bookingByMethod}
				vcBookingByMethod={this.state.vcBookingByMethod}
				bookingByRatio={this.state.bookingByRatio}
				vcBookingByRatio={this.state.vcBookingByRatio}
			/>;
		}
	});
};

export default hoc(MeetingReports);