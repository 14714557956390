import React, { Component, useCallback, useState } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import { useTranslation, withTranslation } from 'react-i18next';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { Input, Pagination, Table, Button, Popconfirm, PageHeader, DatePicker, Select, Tag, Modal } from 'antd';
import { meetingTimeStatus, bookingSource, isCancel } from './Role';
import SelectMeetingState from '../components/SelectMeetingState';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

function VideoMeeting(props) {
	const { t } = useTranslation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const [height, setHeight] = useState(0);
	const tableRef = useCallback(node => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);
	return (
		<div className='container' style={{ background: '#fff' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('myVideoConferenceBooking')}
			// 我的视频会议预订
			>
			</PageHeader>
			<div style={{ display: 'flex', flexDirection: 'column', height: '90%', background: '#FFFFFF', padding: 24, overflow: 'hidden' }}>
				<div style={{ display: 'flex', marginBottom: 24, justifyContent: 'space-between' }}>
					{/* <div className='d-flex align-center'>
						<Input.Search
							style={{ width: 240 }}
							placeholder={t('searchByMeetingTitleOrNumber')}
							onSearch={e => props.search(e)}
						/>
					</div> */}
					<div className='d-flex align-center'>
						<Input.Search
							value={props.searchText}
							style={{ width: 240 }}
							placeholder={t('searchByMeetingTitleOrNumber')}
							onChange={e => props.search(e.target.value)}
						/>
						<DatePicker
							value={props.begin}
							style={{ marginLeft: 8 }}
							placeholder='请选择开始日期'
							onChange={props.onStartChange}
						/>
						<DatePicker
							value={props.end}
							style={{ marginLeft: 8, marginRight: 8 }}
							placeholder='请选择结束日期'
							onChange={props.onEndChange}
						/>
						<Select
							value={props.selectedMeetingState}
							placeholder={t('selectMeetingState')}
							style={{ width: 150 }}
							onChange={value => props.onChangeMeetingState(value)}
						>
							<Option value='all'>所有状态</Option>
							<Option value='success'>预订成功</Option>
							<Option value='cancel'>预订取消</Option>
						</Select>
						{/* <SelectMeetingState value={props.selectedMeetingState} selectMeetingState={props.onChangeMeetingState} /> */}
						<Button style={{ marginLeft: 8 }} type='primary' onClick={props.handleQuery}>{t('search')}</Button>
					</div>
					<div>
						<Pagination
							current={props.page}
							pageSize={props.size}
							showTotal={total => t('itemWithCount', { count: total })}
							total={props.total}
							onChange={props.paginationChange}
						/>
					</div>
				</div>
				<div style={{ flex: 1 }} ref={tableRef}>
					<Table
						size='middle'
						bordered={true}
						dataSource={props.videoMeetings}
						rowKey={r => r.id}
						pagination={false}
						scroll={{ y: height - 32 }}
						columns={[
							{ title: t('index'), dataIndex: 'index', key: 'index', width: 60, fixed: 'left', align: 'center', render: (e, record, index) => (index + 1) + (props.page - 1) * props.size },
							{
								title: t('meetingTime'), dataIndex: 'beginAt', key: 'beginAt', width: 310,
								render: (begin, record) => {
									return <div><span style={{ marginRight: 8 }}>{moment(record.beginAt * 1000).format('YYYY-MM-DD HH:mm')} → {moment(record.endAt * 1000).format('HH:mm')}</span> {meetingTimeStatus(record)}</div>
								}
							},
							{
								title: t('meetingTitle'), dataIndex: 'subject', key: 'subject', ellipsis: true,
								render: (text, record) => <><Tag style={{ marginRight: 5 }}>{bookingSource(record, props.me.id)}</Tag><a onClick={() => props.toView(`/video/meeting/${record.id}/view`)}>{text}</a></>
							},
							{ title: t('meetingNumber'), dataIndex: 'accessCode', key: 'accessCode', width: '15%' },
							// { title: t('uniqueCode'), dataIndex: 'code', key: 'code', width: '20%' },
							{
								title: t('meetingPIN'), dataIndex: 'password', key: 'password', width: '10%'
							},
							{ title: t('moderatorPIN'), dataIndex: 'chairmanPassword', key: 'chairmanPassword', width: '10%', ellipsis: true },
							// { title: t('meetingSystem'), dataIndex: 'system', key: 'system', width: '10%', ellipsis: true },
							// { title: t('createdAt'), dataIndex: 'createdAt', key: 'createdAt', width: '13%', render: text => moment.unix(text).format('YYYY-MM-DD HH:mm:ss') },
							//TODO
							// {
							// 	title: t('actions'), key: 'action', width: 80,
							// 	render: (e, record) => {
							// 		return isCancel(record) && (record.createdBy&&record.createdBy.id === props.me.id) && record.state === 'success' && record.endAt >= moment().unix()
							// 			&& 
							// 			<a onClick={() => {
							// 				confirm({
							// 					title: t('thisCannotBeUndone'),
							// 					onOk: () => {
							// 						props.cancelMeeting(record)
							// 					},
							// 					onCancel() {
							// 					},
							// 				});
							// 			}}>{t('cancel')}</a>
							// 	}
							// }
						]}
					/>
				</div>
			</div>
		</div>
	)
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends React.Component {
		static contextType = AppContext;
		get t() { return this.props.t; }
		constructor(props) {
			super(props);
			this.state = {
				searchText: '',
				page: 1,
				size: 20,
				total: 0,
				range: [moment(), moment()],
				videoMeetings: [],
				selectedMeetingState: 'all',
				begin: moment(),
				end: null,
				isClear: true
			};
		}
		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;

			this.setState({ isClear: true });
			let { page, begin, end, searchText, selectedMeetingState } = this.state;

			if (localStorage.length > 0) {
				let keys = [];
				for (var k in localStorage) {
					keys.push(k);
				}
				if (localStorage.getItem('videoMeetingSearchText')) {
					searchText = localStorage.getItem('videoMeetingSearchText')
				}

				if (localStorage.getItem('videoMeetingSelectedMeetingState')) {
					selectedMeetingState = localStorage.getItem('videoMeetingSelectedMeetingState')
				}

				if (_.includes(keys, 'videoMeetingBegin')) {
					begin = localStorage.getItem('videoMeetingBegin') === 'null' ? null : moment(localStorage.getItem('videoMeetingBegin'))
				}

				if (localStorage.getItem('videoMeetingEnd')) {
					end = localStorage.getItem('videoMeetingEnd') === 'null' ? null : moment(localStorage.getItem('videoMeetingEnd'));
				}

				if (localStorage.getItem('videoMeetingCurrentPage')) {
					page = localStorage.getItem('videoMeetingCurrentPage');
				}
			}

			this.setState({ page, end, begin, selectedMeetingState, searchText }, async () => {
				await this.loadVideoMeeting(page, this.state.size, begin ? begin.startOf('day').unix() : null, end ? end.endOf('day').unix() : null, searchText, selectedMeetingState);
			})
		}

		async componentWillUnmount() {
			if (this.state.isClear) {
				localStorage.clear();
			}
		}

		async loadVideoMeeting(currentPage, pageSize, begin, end, key, state) {
			let data = await this.transportLayer.getVideoMeetings(currentPage, pageSize, begin, end, key, null, state);
			let { docs, totalDocs, limit, page } = data
			this.setState({ videoMeetings: docs, total: totalDocs, page, size: limit })
		}

		createVedioMeeting = () => {
			this.props.history.push('/video/meeting/create');
		}

		paginationChange = async (currentPage, pageSize) => {
			localStorage.setItem('videoMeetingCurrentPage', currentPage)
			this.setState({ page: currentPage, size: pageSize });
			await this.loadVideoMeeting(
				currentPage,
				pageSize,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				this.state.selectedMeetingState
			);
		}

		search = async value => {
			localStorage.setItem('videoMeetingSearchText', value)
			this.setState({ searchText: value }, async () => {
				await this.handleQuery();
			})
		}

		deleteVideoMeeting = async id => {
			await this.transportLayer.deleteVideoMeeting(id);
			message.success(this.t('vcDeletedMessage'));
			await this.loadVideoMeeting(this.state.page, this.state.size);
		}

		handleQuery = async () => {
			await this.loadVideoMeeting(
				this.state.page,
				this.state.size,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				this.state.selectedMeetingState
			)
		}

		selectMeetingState = async value => {
			this.setState({ selectedMeetingState: value })
			await this.loadVideoMeeting(this.state.page, this.state.size,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				value)
		}

		onStartChange = (begin) => {
			localStorage.setItem('videoMeetingBegin', begin)
			this.setState({ begin }, async () => {
				await this.loadVideoMeeting(this.state.page, this.state.size,
					begin ? begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.searchText,
					this.state.selectedMeetingState)
			})
		}

		onEndChange = (end) => {
			localStorage.setItem('videoMeetingEnd', end)
			this.setState({ end }, async () => {
				await this.loadVideoMeeting(this.state.page, this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					end ? end.endOf('day').unix() : null,
					this.state.searchText,
					this.state.selectedMeetingState)
			})
		}

		onChangeMeetingState = (selectedMeetingState) => {
			localStorage.setItem('videoMeetingSelectedMeetingState', selectedMeetingState)
			this.setState({ selectedMeetingState }, async () => {
				await this.loadVideoMeeting(this.state.page, this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.searchText,
					selectedMeetingState)
			})
		}

		toView = (path) => {
			this.setState({ isClear: false }, () => {
				this.props.history.push(path)
			});
		}

		render() {
			return (
				<WrappedComponent
					me={this.me}
					page={this.state.page}
					size={this.state.size}
					total={this.state.total}
					search={this.search}
					searchText={this.state.searchText}
					videoMeetings={this.state.videoMeetings}
					createVedioMeeting={this.createVedioMeeting}
					deleteVideoMeeting={this.deleteVideoMeeting}
					begin={this.state.begin}
					end={this.state.end}
					handleQuery={this.handleQuery}
					selectMeetingState={this.selectMeetingState}
					paginationChange={(currentPage, pageSize) => this.paginationChange(currentPage, pageSize)}
					onStartChange={this.onStartChange}
					onEndChange={this.onEndChange}
					selectedMeetingState={this.state.selectedMeetingState}
					onChangeMeetingState={this.onChangeMeetingState}
					toView={this.toView}
				/>
			)
		}
	})
}

export default withRouter(hoc(VideoMeeting));