import React from 'react';
import _ from 'lodash'
import '../meetings/rooms.less';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import { Modal, Tree, Input, Table } from 'antd';
import { withTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { CaretDownOutlined, HomeOutlined, FlagOutlined } from '@ant-design/icons';
import { formatBuildings } from '../share/Helper';

const { Search } = Input;
const { TreeNode } = Tree

function SelectRooms(props) {
	const { t, visible, onCancel, onConfirm } = props;
	const rowSelection = {
		type: 'radio',
		selectedRowKeys: props.selectedRowKeys,
		onChange: (selectedRowKeys, selectedRows) => props.selectRoom(selectedRowKeys, selectedRows)
	}

	return <Modal
		centered
		destroyOnClose
		className="room-modal"
		bodyStyle={{ height: 600 }}
		title={t('selectRoom')}
		visible={visible}
		okText={t('confirm')}
		cancelText={t('cancel')}
		onCancel={onCancel}
		onOk={onConfirm}
	>
		<div style={{
			height: '100%', background: '#fff', overflow: 'hidden', display: 'flex',
		}}>
			<div role='sidebar-tree' style={{ background: '#fff', flex: '0 0 600', padding: '24px 16px', borderRight: '1px solid #E3E9EE', display: 'flex', flexDirection: 'column' }}>
				<div className='d-flex' style={{ marginBottom: 8 }}>
					<Search style={{ marginRight: 8 }} placeholder={t('searchSpacePlaceholder')} value={props.searchBuildingText} onChange={(value) => props.searchBuildingChange(value)} />
				</div>
				<Scrollbars style={{ flex: 1 }} >
					<Tree style={{ height: 600 }} autoExpandParent={props.autoExpandParent} switcherIcon={<CaretDownOutlined />} loadData={props.loadDatas} onSelect={(selectedKeys, e) => props.onSelect(e)} expandedKeys={props.expandedSpaceKeys} onExpand={(e) => props.onExpand(e)}>
						{props.renderTreeNodes(props.spaces)}
					</Tree>
				</Scrollbars>
			</div>
			<div role='detail-wrapper' style={{ padding: 24, flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
				<Table
					bordered={false}
					pagination={false}
					rowKey='id'
					dataSource={props.rooms}
					rowSelection={rowSelection}
					columns={[
						{
							title: t('spaceName'), dataIndex: 'name', key: 'name', align: 'left', render: (e, record) => {
								return (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<strong><a onClick={() => props.toSpaceInfo(record)}>{record.building.name}-{record.floor}-{e}</a></strong>
										<p style={{ color: 'rgba(68,81,95,0.65)', margin: 0 }}>{record.building.city}，{record.building.district}</p>
									</div>
								)
							}
						},
						{
							title: t('status'), dataIndex: 'disable', key: 'disable', align: 'center', render: (e) => e ? t('disabled') : t('enaled') },
						{ title: t('floor'), dataIndex: 'floor', key: 'floor', align: 'center' }
					]}
				/>
			</div>
		</div>
	</Modal>
}

/**
 * 
 * @param {*}  selectSpace 选中的空间对象
 * @returns 
 */

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponet extends React.Component {
		static contextType = AppContext;
		constructor(props) {
			super(props)
			this.state = {
				buildings: [],
				roomCount: 0,
				rooms: [],
				roomPage: 0,
				size: 10,
				selectedBuilding: null,
				selectSpace: null,
				expandedSpaceKeys: [],
				spaces: [],
				treeRooms: [],
				autoExpandParent: true,
				selectedRowKeys: []
			}
			this.searchBuildingText = '';
			this.dataList = [];
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.token = AppContextJS.instance.token;
			this.httpService = AppContextJS.instance.httpService;
			this.transportLayer = AppContextJS.instance.transportLayer;
			await this.getRoomsByPage(null, null, 1);
			if (this.state.spaces) {
				this.generateList(this.state.spaces);
			}
			if (this.props.selectSpace !== "null") {
				let selectSpace = _.find(this.state.rooms, room => room.id === this.props.selectSpace)
				this.setState({
					selectSpace,
					selectedRowKeys: [selectSpace]
				}, () => {
					this.props.onConfirm(selectSpace);
				})
			}
		}

		static getDerivedStateFromProps(props, state) {
			if (props.clearRowKeys) {
				return {
					selectedRowKeys: []
				}
			}
			return null
		}

		formatBuildings = (rooms) => {
			let { spaces, expandedSpaceKeys } = formatBuildings(rooms);
			this.setState({ expandedSpaceKeys, spaces });
			this.generateList(rooms);
		}

		getRoomsByPage = async (buildingId, floorName, page) => {
			let rooms = await this.transportLayer.getRoomsByCompanyId({ page, size: -1, buildingId, floor: floorName });
			if (rooms) {
				this.setState({ roomCount: rooms.totalDocs, rooms: rooms.docs, roomPage: rooms.page })
				this.formatBuildings(rooms.docs);
			}
		}

		generateList = data => {
			for (let i = 0; i < data.length; i++) {
				const node = data[i];
				if (!_.find(this.dataList, d => d.name === node.name && d.code === node.code)) {
					this.dataList.push({ name: node.name, code: node.code });
					if (node.children) {
						this.generateList(node.children);
					}
				}
			}
		};

		renderTreeNodes(node) {
			if (node) {
				return (
					_.map(node, x => {
						{ return this.renderTrees(x); }
					})
				);
			}
		}

		renderTrees(node) {
			if (node) {
				let deviceCount = '';
				if (node.deviceCount && node.deviceCount !== 0) {
					deviceCount = ` (${node.deviceCount})`;
				}

				const index = node.name.indexOf(this.searchBuildingText);
				const beforeStr = node.name.substr(0, index);
				const afterStr = node.name.substr(index + this.searchBuildingText.length);

				const title =
					index > -1 ? (
						<span>
							{node.building ? <HomeOutlined style={{ marginRight: 8 }} /> : <FlagOutlined style={{ marginRight: 8 }} />}
							{beforeStr}
							<span style={{ color: 'red' }}>{this.searchBuildingText}</span>
							{afterStr}
						</span>
					) : (
						<span>{`${node.name}${deviceCount ? deviceCount : ''}`}</span>
					);

				return (
					<TreeNode
						selectable={node.selectable ? true : false}
						isLeaf={node.building ? true : false}
						title={
							<div className='tree-node-container'>
								{title}
							</div>
						}
						key={node.code} dataRef={node} >
						{
							node.children && node.children.length > 0 &&
							_.map(node.children, x => { return this.renderTrees(x); })
						}
					</TreeNode >
				);
			}
		}

		onSelect = async (e) => {
			let selected = e.node.props.dataRef;
			if (selected.children) { //建筑
				if (selected.hasRoom) { //选中的是楼层
					selected.parent.selectedFloor = selected.name;
					await this.getRoomsByPage(selected.parent.id, selected.name, this.state.roomPage);
				} else {//选中的是建筑
					selected.selectedFloor = null;
					await this.getRoomsByPage(selected.id, null, this.state.roomPage);
				}
				this.setState({ selectedBuilding: selected.hasRoom ? selected.parent : selected, selectSpace: null })
			}
			if (selected.building) { //会议室
				this.setState({ selectSpace: selected, selectedBuilding: null, selectedRowKeys: [selected.id] });
			}
		}

		onExpand(expandedSpaceKeys) {
			this.setState({ expandedSpaceKeys, autoExpandParent: false });
		}

		searchBuildingChange = e => {
			const { value } = e.target;
			const expandedKeys = this.dataList
				.map(item => {
					if (item.name.indexOf(value) > -1) {
						return this.getParentKey(item.code, this.state.spaces);
					}
					return null;
				})
				.filter((item, i, self) => item && self.indexOf(item) === i);
			this.setState({
				expandedSpaceKeys: expandedKeys,
				autoExpandParent: true,
			});

			this.searchBuildingText = value;
		}

		getParentKey = (key, tree) => {
			let parentKey;
			for (let i = 0; i < tree.length; i++) {
				const node = tree[i];
				if (node.children) {
					if (node.children.some(item => item.code === key)) {
						parentKey = node.code;
					} else if (this.getParentKey(key, node.children)) {
						parentKey = this.getParentKey(key, node.children);
					}
				}
			}
			return parentKey;
		}

		selectRoom = (selectedRowKeys, selectedRows) => {
			let selectSpace = _.first(selectedRows)
			this.setState({ selectedRowKeys, selectSpace })
		}

		toSpaceInfo = (space) => {
			this.setState({ selectSpace: space, selectedBuilding: null })
		}

		onCancel = () => {
			this.setState({ selectedRowKeys: [] })
			this.props.onCancel()
		}

		onConfirm = () => {
			this.props.onConfirm(this.state.selectSpace)
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					rooms={this.state.rooms}
					spaces={this.state.spaces}
					onCancel={this.onCancel}
					onConfirm={this.onConfirm}
					loadDatas={this.loadDatas}
					toSpaceInfo={this.toSpaceInfo}
					onExpand={(e) => this.onExpand(e)}
					selectedRowKeys={this.state.selectedRowKeys}
					onSelect={(space) => this.onSelect(space)}
					selectRoom={this.selectRoom}
					expandedSpaceKeys={this.state.expandedSpaceKeys}
					autoExpandParent={this.state.autoExpandParent}
					searchBuildingText={this.searchBuildingText}
					searchBuildingChange={this.searchBuildingChange}
					renderTreeNodes={(space) => this.renderTreeNodes(space)}
				/>
			)
		}
	})
}

export default hoc(SelectRooms)