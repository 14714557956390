import React, { Component, useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import _ from 'lodash'
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import { withRouter, useLocation, Link } from 'react-router-dom';
import { Button, Table, Modal, Input, Pagination, Menu, Dropdown, DatePicker, PageHeader, Popover, Select, Tag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { adminOrDispatcherOrGeneral, isCancel, meetingTimeStatus, bookingSource } from './Role'
import SelectMeetingState from '../components/SelectMeetingState';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function MyBookings(props) {
	if (!props.me) return <></>
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const [height, setHeight] = useState(0);
	const tableRef = useCallback(node => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);
	// const routes = [
	// 	{
	// 		path: '/my/booking',
	// 		breadcrumbName: t('myBookings'),
	// 	},
	// 	{
	// 		breadcrumbName: t('mySpaceBooking'),
	// 	}
	// ]
	return (
		<div className='container' style={{ background: '#fff' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={`${t('mySpaceBooking')}`}
			//我的空间预订
			// breadcrumb={{ routes }}
			>
			</PageHeader>
			<div style={{ display: 'flex', flexDirection: 'column', height: '90%', background: '#FFFFFF', padding: 24, overflow: 'hidden' }}>
				<div style={{ display: 'flex', marginBottom: 24, justifyContent: 'space-between' }}>
					<div>
						<Input.Search
							style={{ width: 240 }}
							placeholder={t('searchMeetingByTitle')}
							value={props.searchText}
							onChange={e => props.search(e.target.value)}
						/>
						<DatePicker
							value={props.begin}
							style={{ marginLeft: 8 }}
							placeholder='请选择开始日期'
							onChange={props.onStartChange}
						/>
						<DatePicker
							value={props.end}
							style={{ marginLeft: 8, marginRight: 8 }}
							placeholder='请选择结束日期'
							onChange={props.onEndChange}
						/>
						<SelectMeetingState value={props.selectedMeetingState} selectMeetingState={props.onChangeMeetingState} />
						<Button style={{ marginLeft: 8 }} type='primary' onClick={props.handleQuery}>{t('search')}</Button>
					</div>
					<Pagination
						current={props.page}
						pageSize={props.size}
						showTotal={total => t('itemWithCount', { count: total })}
						total={props.total}
						onChange={props.paginationChange}
					/>
				</div>
				<div style={{ flex: 1 }} ref={tableRef}>
					<Table
						size='middle'
						bordered={true}
						dataSource={props.meetings}
						pagination={false}
						scroll={{ y: height - 32 }}
						columns={[
							{ title: t('index'), dataIndex: 'index', key: 'index', width: 60, align: 'center', render: (e, record, index) => (index + 1) + (props.page - 1) * props.size },
							{
								title: t('meetingStatus'), dataIndex: 'state', key: 'state', width: 80, ellipsis: true,
								render: (text, record) => {
									return adminOrDispatcherOrGeneral(record);
								}
							},
							{
								title: t('meetingTime'), dataIndex: 'beginAt', key: 'beginAt', width: 280,
								render: (begin, record) => {
									return <div><span style={{ marginRight: 8 }}>{moment(record.beginAt * 1000).format('YYYY-MM-DD HH:mm')} → {moment(record.endAt * 1000).format('HH:mm')}</span> {meetingTimeStatus(record)}</div>
								}
							},
							{
								title: t('meetingLocation'), dataIndex: 'room', key: 'room', ellipsis: true,
								render: item => {
									return item && <span>{item.building.name}-{item.floor}-{item.name}</span>
								}
							},
							{
								title: t('meetingTitle'), dataIndex: 'subject', key: 'subject', width: '30%', ellipsis: true,
								render: (text, record) => <><Tag style={{ marginRight: 5 }}>{bookingSource(record, props.me.id)}</Tag><a onClick={() => props.toView(`/local/booking/${record.id}/view`)} >{text}</a></>
							},
							{
								title: t('meetingOrganizer'), dataIndex: 'organizer.name', key: 'organizer', width: 100, ellipsis: true,
								render: (e, record) =>
									<Popover content={
										<div>
											<p>{t('meetingOwner')}： {record.createdBy.name}</p>
											<p>{t('auditor')}： {record.auditors.length > 0 ? _.map(record.auditors, 'name').join(',') : t('none')}</p>
										</div>} >
										{record.organizer.name}
									</Popover>
							},
							{
								title: t('actions'), key: 'action', width: 80,
								render: (e, record) => {
									return isCancel(record) && (record.createdBy.id === props.me.id) &&
										<a onClick={() => {
											confirm({
												title: t('thisCannotBeUndone'),
												onOk: () => {
													props.cancelMeeting(record)
												},
												onCancel() {
												},
											});
										}}>{t('cancel')}</a>
								},
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
}
let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				searchText: '',
				page: 1,
				size: 20,
				total: 0,
				meetings: [],
				selectedMeetingState: 'all',
				begin: moment(),
				end: null,
				isClear: true
			};
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.setState({ isClear: true });
			let { page, begin, end, searchText, selectedMeetingState } = this.state;

			if (localStorage.length > 0) {
				let keys = [];
				for (var k in localStorage) {
					keys.push(k);
				}
				if (localStorage.getItem('myBookingSearchText')) {
					searchText = localStorage.getItem('myBookingSearchText')
				}

				if (localStorage.getItem('myBookingSelectedMeetingState')) {
					selectedMeetingState = localStorage.getItem('myBookingSelectedMeetingState')
				}

				if (_.includes(keys, 'myBookingBegin')) {
					begin = localStorage.getItem('myBookingBegin') === 'null' ? null : moment(localStorage.getItem('myBookingBegin'))
				}

				if (localStorage.getItem('myBookingEnd')) {
					end = localStorage.getItem('myBookingEnd') === 'null' ? null : moment(localStorage.getItem('myBookingEnd'));
				}

				if (localStorage.getItem('myBookingCurrentPage')) {
					page = localStorage.getItem('myBookingCurrentPage');
				}
			}

			this.setState({ page, end, begin, selectedMeetingState, searchText }, async () => {
				await this.loadBookings(page, this.state.size, begin ? begin.startOf('day').unix() : null, end ? end.endOf('day').unix() : null, searchText, selectedMeetingState);
			})
		}

		async componentWillUnmount() {
			if (this.state.isClear) {
				localStorage.clear();
			}
		}

		async loadBookings(currentPage, pageSize, begin, end, key, state) {
			let myBookings = await this.transportLayer.getMyBookings(currentPage, pageSize, begin, end, key, null, state);
			let { docs, page, totalDocs, limit } = myBookings;
			this.setState({ meetings: docs, page, total: totalDocs, page, size: limit });
		}

		onKeyChange = async value => {
			this.setState({ searchText: value });
		}

		search = value => {
			localStorage.setItem('myBookingSearchText', value)
			this.setState({ searchText: value }, async () => {
				await this.handleQuery();
			});
		}

		paginationChange = async (currentPage, pageSize) => {
			localStorage.setItem('myBookingCurrentPage', currentPage)
			await this.loadBookings(
				currentPage,
				pageSize,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				this.state.selectedMeetingState
			)
		}

		deleteMeeting = async id => {
			await this.transportLayer.deleteMeeting(id);
			await this.handleQuery()
		}

		cancelMeeting = async record => {
			await this.transportLayer.cancelMeeting(record);
			await this.handleQuery()
		}

		handleQuery = async () => {
			await this.loadBookings(
				this.state.page,
				this.state.size,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				this.state.selectedMeetingState
			)
		}

		onStartChange = (begin) => {
			localStorage.setItem('myBookingBegin', begin)
			this.setState({ begin }, async () => {
				await this.loadBookings(this.state.page, this.state.size,
					begin ? begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.searchText,
					this.state.selectedMeetingState)
			})
		}

		onEndChange = (end) => {
			localStorage.setItem('myBookingEnd', end)
			this.setState({ end }, async () => {
				await this.loadBookings(this.state.page, this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					end ? end.endOf('day').unix() : null,
					this.state.searchText,
					this.state.selectedMeetingState)
			})
		}

		onChangeMeetingState = (selectedMeetingState) => {
			localStorage.setItem('myBookingSelectedMeetingState', selectedMeetingState)
			this.setState({ selectedMeetingState }, async () => {
				await this.loadBookings(this.state.page, this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.searchText,
					selectedMeetingState)
			})
		}

		toView = (path) => {
			this.setState({ isClear: false }, () => {
				this.props.history.push(path)
			});
		}

		render() {
			return (
				<WrappedComponent
					me={this.me}
					page={this.state.page}
					size={this.state.size}
					total={this.state.total}
					meetings={this.state.meetings}
					searchText={this.state.searchText}
					begin={this.state.begin}
					end={this.state.end}
					onKeyChange={value => this.onKeyChange(value)}
					search={value => this.search(value)}
					cancelMeeting={this.cancelMeeting}
					deleteMeeting={this.deleteMeeting}
					handleQuery={this.handleQuery}
					paginationChange={(currentPage, pageSize) => this.paginationChange(currentPage, pageSize)}
					onStartChange={this.onStartChange}
					onEndChange={this.onEndChange}
					selectedMeetingState={this.state.selectedMeetingState}
					onChangeMeetingState={this.onChangeMeetingState}
					toView={this.toView}
				/>
			);
		}
	});
};

export default withRouter(hot(MyBookings));
