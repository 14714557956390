const debug = require('debug')('shgbit:space');
const _ = require('lodash');
import qs from 'qs';
import Cookies from 'js-cookie';
import AppContext from '../AppContext';

export default class OAuth {
	constructor(props) {
		let backendOrigin = AppContext.instance.httpService;
		debug('>>> ', backendOrigin);

		this.authorizeEndpoint = `${backendOrigin}/v2/oauth/authorize`;
		this.callbackEndpoint = `${backendOrigin}/v2/oauth/callback/self`;
		this.tokenEndpoint = `${backendOrigin}/v2/oauth/token`;
		this.meEndpoint = `${backendOrigin}/v2/user`;
		this.clientId = AppContext.instance.clientId;
		this.httpServiceEndpoint = AppContext.instance.httpService;
	}

	async getToken() {
		// let url = new URL(window.location.href);
		// console.dir(url);
		// let url_token = url.searchParams.get('token');
		// if (url_token) {
		// 	// save into cookies
		// 	this.token = url_token;
		// 	Cookies.set('token', url_token, { httpOnly: false });
		// 	url.searchParams.delete('token');
		// 	window.history.pushState({}, null, url.href);
		// 	return url_token;
		// }

		let token = Cookies.get('token');
		if (token) {
			this.token = token;
			return this.token;
		}

		// query new token
		try {
			let redirect_uri = `${encodeURIComponent(window.location)}`;
			let url = `${window.location.origin}/api/v2/oauth/authorize?response_type=token&redirect_uri=${redirect_uri}`;
			debug('>>>', url);
			window.location.href = url;
		} catch (error) {
			debug(error);
			if (error.isAxiosError) {
				debug(error);
			}
		}
	}

	logout(locationPathName) {
		Cookies.set('location', locationPathName, { expires: 1 });
		Cookies.remove('token');
		Cookies.remove('lang');
		localStorage.clear();
		let url = `${this.httpServiceEndpoint}/v2/oauth/logout?token=${this.token}`;
		// let url = 'http://localhost:3000/api/v2/oauth/logout';
		window.location = url;
	}

	profile() {
		let url = new URL(window.location.href);
		window.location = `${this.httpServiceEndpoint}/v2/oauth/profile?token=${this.token}&redirect_uri=${window.location.href}`;
	}

	switchCompany(companyCode) {
		Cookies.remove('token');
		Cookies.remove('location');
		localStorage.clear();
		let callback = this.getCurrentUrlWithoutToken();
		let url = `${this.httpServiceEndpoint}/v2/user/switch/${companyCode}?token=${this.token}&callback=${callback}`;
		window.location = url;
	}

	getCurrentUrlWithoutToken() {
		let url = new URL(window.location.href);
		url.searchParams.delete('token');
		return url.href;
	}
}