import React, { Component, useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import { withRouter, useLocation, Link } from 'react-router-dom';
import { Table, Input, DatePicker, Pagination, Popconfirm, Button, PageHeader, Modal, Menu, Dropdown, Form, message, Popover, Tag } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import AppPermission from '../AppPermission';
import { meetingTimeStatus, dispatchState, successState, notCancel, adminOrDispatcherOrGeneral, isCancel } from './../meetings/Role';
import SelectMeetingState from '../components/SelectMeetingState';


const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

function Auditor(props) {
	if (!props.me) return <></>
	const { t } = useTranslation();
	let location = useLocation();
	if (!props.canExecute(props.me.permission, location.pathname)) {
		window.location = '/#/noAccess';
	}
	Cookies.set('location', location.pathname, { expires: 1 });
	const [height, setHeight] = useState(0);
	const tableRef = useCallback(node => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);
	return (
		<div className='container' style={{ background: '#fff' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('myAudit')}
			>
			</PageHeader>
			<div style={{ display: 'flex', flexDirection: 'column', height: '90%', background: '#FFFFFF', padding: 24, overflow: 'hidden' }}>
				<div style={{ display: 'flex', marginBottom: 24, justifyContent: 'space-between' }}>
					<div>
						{/* <Input.Search
							style={{ width: 240, marginLeft: 8 }}
							placeholder={t('searchMeetingByTitle')}
							// value={props.searchSubject}
							onSearch={e => props.onSearchSubject(e)}
						/>
						<RangePicker
							value={props.range}
							style={{ width: 240, marginLeft: 8 }}
							format={'YYYY/MM/DD'}
							onChange={range => props.setRange(range[0], range[1])}
						/>
						<Button style={{ marginLeft: 8 }} type='primary' onClick={() => props.handleQuery()}>{t('search')}</Button>
					 */}
						<Input.Search
							value={props.searchText}
							style={{ width: 240 }}
							placeholder={t('searchMeetingByTitle')}
							onChange={e => props.search(e.target.value)}
						/>
						<DatePicker
							value={props.begin}
							style={{ marginLeft: 8 }}
							placeholder='请选择开始日期'
							onChange={props.onStartChange}
						/>
						<DatePicker
							value={props.end}
							style={{ marginLeft: 8, marginRight: 8 }}
							placeholder='请选择结束日期'
							onChange={props.onEndChange}
						/>
						<SelectMeetingState value={props.selectedMeetingState} selectMeetingState={props.onChangeMeetingState} />
						<Button style={{ marginLeft: 8 }} type='primary' onClick={props.handleQuery}>{t('search')}</Button>
					</div>
					<Pagination
						current={props.page}
						pageSize={props.size}
						showTotal={total => t('itemWithCount', { count: total })}
						total={props.total}
						onChange={props.paginationChange}
					/>
				</div>
				<div style={{ flex: 1 }} ref={tableRef}>
					<Table
						size='middle'
						bordered={true}
						rowKey={record => record.id}
						dataSource={props.meetings}
						pagination={false}
						scroll={{ y: height - 32 }}
						columns={[
							{ title: t('index'), dataIndex: 'index', key: 'index', width: 60, align: 'center', render: (e, record, index) => (index + 1) + (props.page - 1) * props.size },
							{
								title: t('meetingStatus'), dataIndex: 'state', key: 'state', width: 90, ellipsis: true,
								render: (text, record) => {
									return adminOrDispatcherOrGeneral(record);
								}
							},
							{
								title: t('meetingTime'), dataIndex: 'beginAt', key: 'beginAt', width: 310,
								render: (begin, record) => {
									return <div><span style={{ marginRight: 8 }}>{moment(record.beginAt * 1000).format('YYYY-MM-DD HH:mm')} → {moment(record.endAt * 1000).format('HH:mm')}</span> {meetingTimeStatus(record)}</div>
								}
							},
							{
								title: t('meetingLocation'), dataIndex: 'room', key: 'room', ellipsis: true,
								render: item => {
									return item && <span>{item.building.name}-{item.floor}-{item.name}</span>
								}
							},
							{
								title: t('meetingTitle'), dataIndex: 'subject', key: 'subject', width: '25%', ellipsis: true,
								render: (text, record) => <a onClick={() => props.toView(`/local/booking/${record.id}/view`)}>{text}</a>
							},
							{
								title: t('meetingOrganizer'), dataIndex: 'organizer.name', key: 'organizer', width: 100, ellipsis: true,
								render: (e, record) =>
									<Popover content={
										<div>
											<p>{t('meetingOwner')} {record.createdBy.name}</p>
											<p>{t('auditor')}： {record.auditors.length > 0 ? _.map(record.auditors, 'name').join(',') : t('none')}</p>
										</div>} >
										{record.organizer.name}
									</Popover>
							},
							{
								title: t('actions'), key: 'action', width: 80,
								render: (e, record) => {
									if (!isCancel(record)) return;
									const menus = (
										<Menu>
											<Menu.Item>
												<a onClick={() => {
													confirm({
														title: t('confirmToApprove'),
														onOk: () => {
															props.auditMeeting(record.id)
														},
														onCancel() {
														},
													});
												}}>{t('approve')}</a>
											</Menu.Item>
											<Menu.Item>
												<a onClick={() => props.auditReject(record.id)}>{t('reject')}</a>
											</Menu.Item>
										</Menu>
									);
									return <Dropdown overlay={menus} placement="bottomLeft">
										<MoreOutlined />
									</Dropdown>
								},
							}
						]}
					/>
				</div>
				<AuditedNote
					visible={props.visible}
					onOk={props.onSave}
					onCancel={props.cancel}
					booking={props.booking}
				/>
			</div>
		</div>
	);
}

const AuditedNote = withTranslation()(Form.create()(
	class extends React.Component {
		// static contextType = AppContext;
		get t() { return this.props.t; }

		constructor(props) {
			super(props);
			this.state = {
				page: 1,
				size: 20,
				total: 0,
				range: [moment(), moment().clone().add(7, 'days')],
				status: 'auditing',
			};
		}
		async componentDidMount() {
			this.transportLayer = AppContextJS.instance.transportLayer;
		}

		onSave = async e => {
			e.preventDefault();
			const { form, t } = this.props;
			form.validateFields(async (err, values) => {
				let params = {
					audit: values
				}
				if (err) return;
				try {
					await this.transportLayer.auditReject(this.props.booking, params);
					message.success(this.t('rejected'));
					this.cancel();
				} catch (error) {
					message.error(this.t('rejectError'));
				}
			});
		}
		cancel = e => {
			this.props.form.resetFields();
			this.props.onCancel();
		}

		render() {
			const { getFieldDecorator } = this.props.form;

			return (
				<Modal
					centered
					destroyOnClose
					width='960px'
					title={this.t('rejectComment')}
					visible={this.props.visible}
					onOk={this.onSave}
					onCancel={this.cancel}
				>
					<Form
					>
						<Form.Item label={this.t('rejectComment')}  >
							{getFieldDecorator('auditedNote', {
								initialValue: '',
								rules: [{ required: true, message: this.t('enterRejectComment') }]
							})(
								<TextArea rows={4} maxlength="50" placeholder={this.t('enterRejectComment')} />
							)}
						</Form.Item>
					</Form>
				</Modal>
			)
		}
	}
))

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				searchText: '',
				searchSubject: '',
				page: 1,
				size: 20,
				total: 0,
				range: [moment(), moment().clone().add(7, 'days')],
				meetings: [],
				status: 'auditing',
				visible: false,
				booking: '',
				selectedMeetingState: 'auditing',
				begin: moment(),
				end: null,
				isClear: true
			};
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;

			this.setState({ isClear: true });
			let { page, begin, end, searchText, selectedMeetingState } = this.state;

			if (localStorage.length > 0) {
				let keys = [];
				for (var k in localStorage) {
					keys.push(k);
				}
				if (localStorage.getItem('auditorSearchText')) {
					searchText = localStorage.getItem('auditorSearchText')
				}

				if (localStorage.getItem('auditorSelectedMeetingState')) {
					selectedMeetingState = localStorage.getItem('auditorSelectedMeetingState')
				}

				if (_.includes(keys, 'auditorBegin')) {
					begin = localStorage.getItem('auditorBegin') === 'null' ? null : moment(localStorage.getItem('auditorBegin'))
				}

				if (localStorage.getItem('auditorEnd')) {
					end = localStorage.getItem('auditorEnd') === 'null' ? null : moment(localStorage.getItem('auditorEnd'));
				}

				if (localStorage.getItem('auditorCurrentPage')) {
					page = localStorage.getItem('auditorCurrentPage');
				}
			}

			this.setState({ page, end, begin, selectedMeetingState, searchText }, async () => {
				await this.loadBookings(
					this.state.page,
					this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					searchText,
					selectedMeetingState
				)
			});
		}

		async componentWillUnmount() {
			if (this.state.isClear) {
				localStorage.clear();
			}
		}

		async loadBookings(currentPage, pageSize, begin, end, key, state) {
			let myBookings = await this.transportLayer.getAuditMeetings(currentPage, pageSize, begin, end, key, state);
			let { docs, page, totalDocs, limit } = myBookings;
			this.setState({ meetings: docs, page, total: totalDocs, page, size: limit });
		}

		search = value => {
			localStorage.setItem('auditorSearchText', value)
			this.setState({ searchText: value, page: 1 }, async () => {
				await this.handleQuery(value);
			});
		}

		onSearchSubject = value => {
			this.setState({ searchSubject: value, page: 1 }, async () => {
				await this.handleQuery(value);
			})
		}

		setRange = (range1, range2) => {
			this.setState({ range: [range1, range2], page: 1 })
		}

		paginationChange = async (currentPage, pageSize) => {
			localStorage.setItem('auditorCurrentPage', currentPage)
			await this.loadBookings({
				page: currentPage,
				size: pageSize,
				begin: this.state.begin ? this.state.begin.startOf('day').unix() : null,
				end: this.state.end ? this.state.end.endOf('day').unix() : null,
				state: this.state.status,
			})
		}

		auditMeeting = async bookingId => {
			try {
				await this.transportLayer.auditMeeting(bookingId);
				message.success(this.t('approved'));
				this.cancel();
			} catch (error) {
				message.error(this.t('approveError'));
			}
			await this.handleQuery()
		}

		auditReject = async booking => {
			this.setState({ visible: true, booking })
			await this.handleQuery()
		}

		cancel = async () => {
			this.setState({ visible: false })
			await this.handleQuery()
		}

		// handleQuery = async searchText => {
		// 	await this.loadBookings({
		// 		page: this.state.page,
		// 		size: this.state.size,
		// 		begin: this.state.range[0] ? this.state.range[0].startOf('day').unix() : null,
		// 		end: this.state.range[1] ? this.state.range[1].endOf('day').unix() : null,
		// 		state: this.state.status,
		// 		key: searchText ? searchText : ''
		// 	})
		// }
		handleQuery = async () => {
			await this.loadBookings(
				this.state.page,
				this.state.size,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				this.state.selectedMeetingState
			)
		}

		selectMeetingState = async value => {
			this.setState({ selectedMeetingState: value })
			await this.loadBookings(this.state.page, this.state.size,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.searchText,
				value)
		}

		onStartChange = (begin) => {
			localStorage.setItem('auditorBegin', begin)
			this.setState({ begin }, async () => {
				await this.loadBookings(this.state.page, this.state.size,
					begin ? begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.searchText,
					this.state.selectedMeetingState)
			})
		}

		onEndChange = (end) => {
			localStorage.setItem('auditorEnd', end)
			this.setState({ end }, async () => {
				await this.loadBookings(this.state.page, this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					end ? end.endOf('day').unix() : null,
					this.state.searchText,
					this.state.selectedMeetingState)
			})
		}

		onChangeMeetingState = (selectedMeetingState) => {
			localStorage.setItem('auditorSelectedMeetingState', selectedMeetingState)
			this.setState({ selectedMeetingState }, async () => {
				await this.loadBookings(this.state.page, this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.searchText,
					selectedMeetingState)
			})
		}

		toView = (path) => {
			this.setState({ isClear: false }, () => {
				this.props.history.push(path)
			});
		}

		render() {
			return (
				<WrappedComponent
					me={this.me}
					range={this.state.range}
					page={this.state.page}
					size={this.state.size}
					total={this.state.total}
					meetings={this.state.meetings}
					searchText={this.state.searchText}
					searchSubject={this.state.searchSubject}
					search={value => this.search(value)}
					onSearchSubject={value => this.onSearchSubject(value)}
					auditMeeting={this.auditMeeting}
					visible={this.state.visible}
					booking={this.state.booking}
					auditReject={this.auditReject}
					cancel={this.cancel}
					setRange={(range1, range2) => this.setRange(range1, range2)}
					begin={this.state.begin}
					end={this.state.end}
					handleQuery={this.handleQuery}
					paginationChange={(currentPage, pageSize) => this.paginationChange(currentPage, pageSize)}
					canExecute={AppPermission.instance.canExecute}
					selectMeetingState={this.selectMeetingState}
					onStartChange={this.onStartChange}
					onEndChange={this.onEndChange}
					selectedMeetingState={this.state.selectedMeetingState}
					onChangeMeetingState={this.onChangeMeetingState}
					toView={this.toView}
				/>
			);
		}
	});
};

export default withRouter(hoc(Auditor));
