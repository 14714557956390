function getErrorMessage(error) {
	if (!error) return '';
	if (error.response && error.response.data) return error.response.data;
	return error.toString();
}

const formatBuildings = (rooms, needSelectFirstRoom) => {
	let buildings = _.unionBy(_.map(rooms, 'building'), 'id');
	let buildingGroups = _.groupBy(rooms, 'building.id');
	let spaces = [];
	let countrys = _.groupBy(_.orderBy(buildings, 'weight', 'desc'), 'city');
	let firstRoom = null;
	let cityCount = 0, buildingCount = 0, floorCount = 0, roomCount = rooms.length;

	for (var city in countrys) {
		let districts = _.groupBy(countrys[city], 'district');

		let children = [];

		for (var district in districts) {
			let treeDistricts = [];

			_.each(districts[district], d => {
				d.code = d.id;
				treeDistricts.push(_.omit(d, ['children']));

				d.children = [];
				d.code = `building-${d.id}`;
				_.each(d.floors, floor => {
					let fchildren = _.filter(rooms, room => {
						if (room.building === d.id && room.floor === floor) {
							room.building = { name: d.name, city: d.city, district: d.district, id: d.id, floors: d.floors }
							return true;
						}

						if (room.building.id === d.id && room.floor === floor) return true;

						return false;
					})

					if (fchildren.length === 0) return false;

					_.each(fchildren, room => { room.selectable = true; })
					if (needSelectFirstRoom && !firstRoom) {
						firstRoom = _.first(fchildren);
					}

					d.children.push({ name: floor, code: `floor-${floor}-${d.id}`, hasRoom: true, children: fchildren, parent: d })
					floorCount++;
				})
				buildingCount++;
			})

			if (districts[district].length === 0) continue;
			cityCount++;
			children.push({ name: district, code: district, children: districts[district] })
		}

		if (children.length === 0) continue;
		spaces.push({ name: city, code: city, children })
	}

	let expandedSpaceKeys = initExpandedSpaceKeys(spaces, []);
	return { expandedSpaceKeys, spaces, firstRoom, buildingGroups, cityCount, buildingCount, floorCount, roomCount }
}

const initExpandedSpaceKeys = (spaces, expandedKeys) => {
	for (let child of spaces) {
		if (_.isEmpty(child.children)) continue;
		expandedKeys.push(child.code);
		expandedKeys = initExpandedSpaceKeys(child.children, expandedKeys);
	}
	return expandedKeys;
}

// user：用户创建的预订，device：设备创建的预订，app：第三方平台创建的预订，sync：外部系统同步过来的预订
const meetingSource = {
	user: 'web端',
	device: '信息屏',
	app: '第三方',
	sync: '外部同步'
}

const meetingState = {
	auditing: '审核中',
	reject: '审核驳回',
	success: '预订成功',
	cancel: '预订取消'
}

export { getErrorMessage, formatBuildings, meetingSource, meetingState };
