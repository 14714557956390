import _ from 'lodash';
import React, { Component } from 'react';
import { Modal, Input, Table, Pagination, Select } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';

const { Option } = Select;

function SelectUser(props) {
	const { t } = useTranslation();
	const rowSelection = {
		type: props.selectType === 'muti' ? 'checkbox' : 'radio',
		selectedRowKeys: props.selectedRowKeys,
		onChange: (selectedRowKeys, selectedRows) => props.selectUser(selectedRowKeys, selectedRows)
	}

	return (
		<Modal
			centered
			destroyOnClose
			width={1280}
			title={t('selectMember')}
			visible={props.visible}
			onCancel={props.onCancel}
			onOk={props.onConfirm}
			okText={t('confirm')}
			cancelText={t('cancel')}
		>
			<div >
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
					<Input.Search placeholder={t('searchUserPlaceholder')} style={{ width: '50%' }} onChange={e => props.change(e.target.value)} />
					<Pagination
						current={props.page}
						pageSize={props.size}
						total={props.total}
						showTotal={total => t('itemWithCount', { count: total })}
						onChange={props.paginationChange}
					/>
				</div>
				<Table
					size='middle'
					tableLayout='fixed'
					className='userSelectTable'
					bordered
					rowKey={record => record.id}
					rowSelection={rowSelection}
					columns={[
						{ title: t('personName'), dataIndex: 'name', key: 'name', width: 100, ellipsis: true },
						{ title: t('email'), dataIndex: 'email', key: 'email', width: 220, ellipsis: true },
						{ title: t('mobile'), dataIndex: 'mobile', key: 'mobile', width: 120, ellipsis: true },
						{ title: t('department'), dataIndex: 'department', key: 'department', ellipsis: true },
						{ title: t('memberCode'), dataIndex: 'jobNumber', key: 'jobNumber', width: 100, ellipsis: true },
						{ title: t('duty'), dataIndex: 'duty', key: 'duty', width: 100, ellipsis: true },
					]}
					pagination={false}
					dataSource={props.persons}
				/>
			</div>
			<div style={{ margin: '10px 0', fontWeight: 600 }}>{t('selectedUser')}</div>
			<Select
				size='large'
				style={{ marginRight: 8, width: '100%' }}
				value={_.chain(props.selectedUsers).map(x => x.id).value()}
				mode='multiple'
				open={false}
				onChange={value => props.mutiOptionDataOnChange(value)}
			>
				{_.map(props.selectedUsers, d => <Option key={d.id} value={d.id}>{d.title ? d.title : d.name}</Option>)}
			</Select>
		</Modal>
	)
}

let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				selectedRowKeys: [],
				persons: [],
				searchText: '',
				page: 1,
				size: 10,
				total: 0,
				selectedUsers: []
			};
		}

		componentWillReceiveProps = async (nextProps) => {
			if (nextProps.selectedUser) {
				let users = _.filter(nextProps.selectedUser, u => u);
				this.setState({
					selectedRowKeys: _.map(nextProps.selectedUser, 'id'),
					selectedUsers: users
				})
				await this.getUsers(1, 10);

			}
		}

		componentWillMount = async () => {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;
			if (this.props.selectedUser) {
				let users = _.filter(this.props.selectedUser, u => u);
				this.setState({
					selectedRowKeys: _.map(this.props.selectedUser, 'id'),
					selectedUsers: users
				})
			}

			await this.getUsers(this.state.page, this.state.size);// this,props.role
		}

		getUsers = async (currentPage, pageSize, key) => {
			let data;
			if (!this.props.role) {
				data = await this.transportLayer.getUsers(key, currentPage, pageSize)
			} else {
				data = await this.transportLayer.getUsers(key, currentPage, 10, this.props.role);
			}
			let { page, totalDocs } = data;
			this.setState({ persons: data.docs, page, total: totalDocs });
		}

		selectUser = selectedRowKeys => {
			let ids = _.uniqBy(selectedRowKeys);
			let users = _.filter(this.state.selectedUsers, user => _.includes(ids, user.id));
			this.setState({
				selectedRowKeys: ids
			});

			let persons = _.filter(this.state.persons, person => _.includes(ids, person.id));
			_.each(persons, person => {
				if (!_.find(users, user => user.id === person.id)) {
					users.push(person);
				}
			})

			this.setState({ selectedUsers: users })
		}

		change = value => {
			this.setState({ searchText: value }, async () => {
				await this.getUsers(this.state.page, this.state.size, value);
			});
		}

		mutiOptionDataOnChange = (selectedRowKeys) => {
			let users = [];
			_.each(this.state.selectedUsers, user => {
				if (_.includes(selectedRowKeys, user.id)) {
					users.push(user);
				}
			})
			this.setState({ selectedUsers: users, selectedRowKeys });
		}

		onCancel = () => {
			this.setState({ selectedRowKeys: [] });
			this.props.onCancel()
		}

		onConfirm = () => {
			let users = [];
			_.each(this.state.selectedUsers, user => {
				if (!_.isArray(user)) {
					users.push(user);
				}
			})
			this.props.onConfirm(users);
			this.setState({
				persons: [],
				total: 0,
				selectedUsers: []
			})
		}

		paginationChange = async (page, pageSize) => {
			await this.getUsers(page, pageSize, this.state.searchText)
		}

		render() {
			return <WrappedComponent
				{...this.props}
				page={this.state.page}
				size={this.state.size}
				total={this.state.total}
				selectedRowKeys={this.state.selectedRowKeys}
				selectedUsers={this.state.selectedUsers}
				change={value => this.change(value)}
				persons={this.state.persons}
				onCancel={this.onCancel}
				selectType={this.props.selectType}
				onConfirm={() => this.onConfirm()}
				paginationChange={this.paginationChange}
				mutiOptionDataOnChange={this.mutiOptionDataOnChange}
				selectUser={(selectedRowKeys, selectedRows) => this.selectUser(selectedRowKeys, selectedRows)}
			/>
		}
	})
}

export default hot(SelectUser);