import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import SpaceStatus from '../components/SpaceStatus';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import React, { Component } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { ArrowRightOutlined, UploadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { PageHeader, Form, Input, DatePicker, TimePicker, TreeSelect, Button, message, Divider, Select, Radio, Upload, Modal } from 'antd';
import SelectUser from '../components/SelectUser';
import { disabledHours, disabledMinutes, disabledBeginMinutes } from './DisableTime';
import AreaRoomTree from '../components/AreaRoomTree'

const { Item } = Form;
const { TreeNode } = TreeSelect;
const { Option } = Select
const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};
const ItemLayout = {
	labelCol: { span: 4, },
	wrapperCol: { span: 20 }
};

function CreateBooking(props) {
	if (!props.me) return <></>
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
	return <div className='container' style={{ background: '#fff' }}>
		<div style={{ background: '#FFFFFF', boxShadow: '0px 0px 0px rgba(221, 229, 234, 0.5)', margin: '0 auto' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				onBack={() => props.history.push('/createBooking/local')}
				title={`${t('bookRoom')}`}
			>
				<span style={{ color: 'rgba(68, 81, 95, 0.65)' }}>
					{t('applicant')}：{props.me.name} <Divider type='vertical' />
					{t('memberCode')}：{props.me.code || t('none')} <Divider type='vertical' />
					{t('mobile')}：{props.me.mobile || t('none')}<Divider type='vertical' />
					{t('email')}：{props.me.email || t('none')}</span>
			</PageHeader>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto', background: '#FFFFFF', padding: '24px 56px' }}>
				<Form {...layout} style={{ marginLeft: '-1%' }}>
					<Item label={<span style={{ marginLeft: 11 }}>{t('meetingOrganizer')}</span>} style={{ margin: 0 }} labelAlign='left'>
						<div className='d-flex'>
							<Item >
								{getFieldDecorator('username', {
									initialValue: props.selectedUser ? props.selectedUser.name : '',
									rules: [{ required: false, message: t('enterMeetingOwnerMessage') }]
								})(
									<div>
										<Input style={{ width: 150 }} value={props.selectedUser ? props.selectedUser.name : ''} /> <Button type='primary' onClick={() => props.showModal()}>{t('select')}</Button>
									</div>
								)}
							</Item>
							<div style={{ flex: 1, display: 'flex', marginLeft: 24 }}>
								<Item label={t('contactNumber')} style={{ flex: 1 }} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} labelAlign='left'>
									{getFieldDecorator('organizerMobile', {
										initialValue: props.selectedUser ? props.selectedUser.mobile : '',
										rules: [
											{ required: false, message: t('enterContactMessage') },
											{ pattern: new RegExp(/^1[0-9]\d{9}$/, "g"), message: t('enterValidMobileMessage') }
										]
									})(
										<Input style={{ width: 160 }} placeholder={t('enterContactMessage')} />
									)}
								</Item>
							</div>
						</div>
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('videoConference')}</span>} labelAlign='left'>
						{getFieldDecorator('vm', {
							initialValue: false
						})(
							<Radio.Group value={props.meetingRadio} onChange={e => props.meetingRadioChange(e.target.value)}>
								<Radio value={true}>{t('yes')}</Radio>
								<Radio value={false}>{t('no')}</Radio>
							</Radio.Group>
						)}
					</Item>
					<Item label={t('meetingTitle')} labelAlign='left'>
						{getFieldDecorator('subject', {
							rules: [{ required: true, message: t('enterMeetingTitleMessage') }]
						})(
							<Input style={{ width: '100%' }}
								onChange={e => props.setLength(e.target.value)}
								maxLength={50}
								addonAfter={<span style={{ color: 'rgba(50, 56, 62, 0.45)' }}>{props.length} / 50</span>} />
						)}
					</Item>
					<Item label={t('roomName')} labelAlign='left'>
						{getFieldDecorator('room', {
							initialValue: props.selectedLocation === 'null' ? "" : props.selectedLocation,
							rules: [{ required: true, message: t('selectSpaceMessage') }]
						})(
							<div className="d-flex">
								<AreaRoomTree time={props.planBeginAt} permission={false} onChange={e => props.onSelectRoomsConfirm(e)} selectedRoomId={props.selectedRoomId} selectedSpaces={props.selectedLocation === 'null' ? [] : [props.selectedLocation]} />
							</div>
						)}
					</Item>
					<Item className='demoRoomStatus' label={<span style={{ marginLeft: 11 }}>{t('roomStatus')}</span>} labelAlign='left'>
						{getFieldDecorator('spaceStatus', {
						})(
							<SpaceStatus
								room={props.selectedRoom}
								mainPage={false}
								meetings={props.meetings}
								timelineMode='allday'
								timelineValue={props.planBeginAt}
								resetDate={props.resetDate}
								removeClass={props.removeClass}
								resetRemoveClass={props.resetRemoveClass}
							/>
						)}
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('meetingTime')}</span>} labelAlign='left' style={{ margin: 0 }}>
						<div className='d-flex'>
							<Item>
								{getFieldDecorator('planBeginAt', {
									initialValue: moment(parseInt(props.match.params.start))
								})(
									<DatePicker disabledDate={current => current < moment().add(-1, 'day').endOf('day')} style={{ width: 260, marginRight: 40 }} format={'ll (dddd)'} allowClear={false} onChange={props.dateChange} />
								)}
							</Item>
							<div style={{ flex: 1, display: 'flex' }}>
								<Item
									style={{ flex: 1 }}
								>
									{getFieldDecorator('beginTime', {
										initialValue: moment(parseInt(props.match.params.start)),
									})(
										<TimePicker style={{ width: '100%' }}
											disabledHours={props.disabledHours}
											disabledMinutes={selectedHour => disabledBeginMinutes(getFieldValue('planBeginAt'), selectedHour, true)}
											format="HH:mm" minuteStep={1} use12Hours={false}
											allowClear={false}
											onChange={time => {
												setFieldsValue({
													endTime: moment(time).add(1, 'hour')
												});
											}}
										/>
									)}
								</Item>
								<Item>
									<ArrowRightOutlined style={{ margin: 'auto 16px' }} />
								</Item>
								<Item
									style={{ flex: 1 }}
								>
									{getFieldDecorator('endTime', {
										initialValue: moment(parseInt(props.match.params.end))
									})(
										<TimePicker style={{ width: '100%' }}
											allowClear={false}
											disabledHours={() => disabledHours(getFieldValue('beginTime'))} disabledMinutes={selectedHour => disabledMinutes(getFieldValue('beginTime'), selectedHour)}
											format="HH:mm" minuteStep={1} use12Hours={false}
										/>
									)}
								</Item>
							</div>
						</div>
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('participatingMembers')}</span>} labelAlign='left'>
						{getFieldDecorator('personals')(
							<div className='d-flex'>
								<Select
									size='large'
									style={{ marginRight: 8 }}
									value={_.chain(props.mutiOptionData).map(x => x.id).value()}
									mode='multiple'
									open={false}
									onChange={value => props.mutiOptionDataOnChange(value)}
								>
									{_.map(props.mutiOptionData, d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
								</Select>
								<div className='d-flex' style={{ flex: 1, marginLeft: 5 }}>
									<Button size='large' type='primary' onClick={() => props.showModal(true)}>{t('select')}</Button>
								</div>
							</div>
						)}
					</Item>
					{/* <Item label={<span style={{ marginLeft: 11 }}>{t('meetingAttachments')}</span>} labelAlign='left'>
						{getFieldDecorator('attachments')(
							<div style={{ display: 'flex' }}>
								<Upload
									accept='*'
									style={{ width: 480 }}
									headers={{ Authorization: `Bearer ${props.token}` }}
									action={`${props.httpServiceEndpoint}/v2/files/upload`}
									onChange={props.handleChange}
									fileList={props.attachments}
								>
									<Button>
										<UploadOutlined /> {t('uploadAttachment')}
									</Button>
								</Upload>
								<div style={{ marginLeft: 20 }}>
									<InfoCircleFilled style={{ color: '#F2994A', marginRight: 8 }} /> {t('attachmentUnder20MMessage')}
								</div>
							</div>
						)}
					</Item> */}
					<Item wrapperCol={{ offset: 4 }}>
						<Button size='large' type="primary" onClick={props.onSave}>{t('submitBooking')}</Button>
					</Item>
				</Form>

				<SelectUser
					loading={props.isLoadUser}
					selectedUser={props.selectType ? props.mutiOptionData : [props.selectedUser]}
					visible={props.selectUserVisible}
					onCancel={props.onSelectUserCancel}
					onConfirm={props.onSelectUserConfirm}
					selectType={props.selectType}
				/>
			</div>
		</div>
	</div >;
}

const formatDay = 'YYYY-MM-DD';

let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				selectedRoomId: null,
				selectedLocation: this.props.match.params.roomid,
				date: moment(),
				room: null,
				meetings: [],
				selectUserVisible: false,
				mutiOptionData: [],
				meetingRadio: false,
				selectedUser: {},
				selectedUsers: null,
				allRooms: [],
				length: 0,
				selectType: null,
				attachments: [],
				planBeginAt: moment(parseInt(this.props.match.params.start)).isSame(moment().format(formatDay), 'day') ?
					moment() : moment(parseInt(this.props.match.params.start)).startOf('day'),
				removeClass: false, // 是否移除 时间轴上的红色块
				selectRoomVisible: false,
				selectedRoom: null,
				isLoadUser: false,
				clearRowKeys: false
			};
			this.mutiOptionData = []
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.httpServiceEndpoint = AppContextJS.instance.httpService;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.token = AppContextJS.instance.token;
			this.setState({ selectedUser: this.me, selectedRoom: this.props.match.params.roomid }, async () => {
				await this.fetchRoomById(this.props.match.params.roomid)
			});
		}

		onSave = e => {
			e.preventDefault();
			this.props.form.validateFields(async (err, values) => {
				if (err) return

				if (this.state.selectedRoom && this.state.selectedRoom.forbidden) {
					if (moment(values.planBeginAt).isBetween(moment.unix(this.state.selectedRoom.forbidden.begin), moment.unix(this.state.selectedRoom.forbidden.end))) {
						message.info('该会议室在当前选中时间处于下线状态，请重新选择');
						return;
					}
				}

				let startMinute = values.beginTime.minute();
				let startHour = values.beginTime.hour();
				values.planBeginAt = values.planBeginAt.clone().set({ hour: startHour, minute: startMinute });

				let endMinute = values.endTime.minute();
				let endHour = values.endTime.hour();
				values.planEndAt = values.planBeginAt.clone().set({ hour: endHour, minute: endMinute });
				values.planBeginAt = moment(values.planBeginAt).seconds(0).unix();
				values.planEndAt = moment(values.planEndAt).seconds(0).unix();
				if (moment(values.planEndAt).hour === 0) {
					values.planEndAt = moment(values.planEndAt).hour(23).minute(59).seconds(0).unix()
				}

				values.attendees = _.map(this.state.mutiOptionData, m => { return m.id });
				values.organizer = this.state.selectedUser.id;
				// values.vcs = values.vcs ? JSON.parse(values.vcs) : false;
				values.room = this.state.selectedRoom.id;

				values.attachments = _.map(this.state.attachments, 'response.path');
				delete values.beginTime;
				delete values.endTime;
				delete values.username;
				delete values.spaceStatus;
				this.transportLayer.createBooking(values.room, values).then(res => {
					message.success(this.t('roomBookedMessage'));
					// this.props.history.push('/createBooking/local');
					this.props.history.push({ pathname: `/local/booking/${res.data.id}/view`, state: '/myBooking/local' });
				}, error => {
					if (_.includes(error.response.data.message, 'has been booked during your plan time')) {
						Modal.error({
							title: this.t('hint'),
							okText: this.t('know'),
							centered: true,
							content: this.t('meetingTimeConflictMessage')
						})
					} else {
						message.error(error.response.data.message, 5)
					}
				})
			});
		}

		async fetchBookingsByRoomIdOrCode({ roomIdOrCode }) {
			if (roomIdOrCode) {
				let meetings = await this.transportLayer.getBookingsByRoomIdOrCode({
					roomIdOrCode,
					begin: this.state.planBeginAt.startOf('day').unix(),
					end: this.state.planBeginAt.endOf('day').unix()
				});
				this.setState({ meetings: meetings.docs });
			}
		}

		async fetchRoomById(roomIdOrCode) {
			if (roomIdOrCode !== "null") {
				let room = await this.transportLayer.getRoomByIdOrCode(roomIdOrCode);
				this.setState({ selectedRoom: room }, async () => {
					await this.fetchBookingsByRoomIdOrCode({ roomIdOrCode: room.id });
				})
			}
		}

		renderTreeNodes = node => {
			let area = node && node.area && node.area === 'root' ? node.area : node.code;
			return (
				<TreeNode title={node.name} key={node.code} value={area}>
					{node.children && node.children.length > 0 &&
						_.map(node.children, x => { return this.renderTreeNodes(x); })
					}
				</TreeNode>
			);
		}

		showModal = type => {
			this.setState({ selectUserVisible: true, selectType: type ? 'muti' : '', isLoadUser: true })
		}

		mutiOptionDataOnChange = (ids) => {
			let users = [];
			_.each(this.state.mutiOptionData, user => {
				if (_.includes(ids, user.id)) {
					users.push(user);
				}
			})
			this.setState({ mutiOptionData: users });
		}

		onSelectUserConfirm = keys => {
			if (!this.state.selectType) {
				this.setState({ selectedUser: _.chain(keys).first().value() })
			} else {
				let users = [];
				_.each(keys, user => {
					users.push({
						id: user.id,
						name: user.name,
						department: user.department,
						mobile: user.mobile,
						email: user.email
					});
				})
				this.setState({ mutiOptionData: users });
			}
			this.setState({ selectUserVisible: false, isLoadUser: false })
		}

		onSelectUserCancel = () => {
			this.setState({ selectUserVisible: false, isLoadUser: false, clearRowKeys: false })
		}

		onSelectRoomsCancel = () => {
			this.setState({ selectRoomVisible: false, isLoadUser: false, clearRowKeys: false })
		}

		onSelectRoomsConfirm = async selectedLocation => {
			this.setState({ selectedRoomId: selectedLocation })
			if (!selectedLocation) {
				this.props.form.setFieldsValue({ room: null })
				return;
			}
			let room = await this.transportLayer.getSpacesById(selectedLocation);
			if (!room) return;
			this.setState({
				selectRoomVisible: false,
				isLoadUser: false,
				selectedRoom: room,
				room: room,
				selectedLocation: `${room.building.name}/${room.floor}/${room.name}`
			}, async () => {
				await this.fetchBookingsByRoomIdOrCode({
					roomIdOrCode: this.state.selectedRoom.id,
					begin: this.state.planBeginAt.startOf('day').unix(),
					end: this.state.planBeginAt.endOf('day').unix()
				})
				this.props.form.setFieldsValue({ room: room.id })
			})
		}

		setLength = value => {
			this.setState({ length: value.length });
		}

		setSelectRoomVisible = () => {
			this.setState({ selectRoomVisible: true })
		}

		handleChange = ({ file, fileList }) => {
			if (file.size > 20 * 1024 * 1024) {
				message.info(this.t('attachmentUnder20MMessage'));
				return;
			}

			this.setState({ attachments: fileList });
		}

		dateChange = async date => {
			if (!this.state.selectedRoom) {
				message.warning('请选择一个房间 可查看会议室状态!');
				return false
			}
			if (!date.isSame(moment().format('YYYY-MM-DD'), 'day')) {
				this.props.form.setFieldsValue({
					beginTime: date.clone().startOf('day').add(8, 'hour'),
					endTime: date.clone().startOf('day').add(9, 'hour')
				});
			} else {
				this.props.form.setFieldsValue({
					beginTime: moment().clone(),
					endTime: moment().clone().add(1, 'hour')
				});
			}
			if (this.props.match.params.timelineMode === 'allday') {
				this.setState({
					planBeginAt: date.isSame(moment().format(formatDay), 'day') ? moment() : date.clone().startOf('day')
					, removeClass: true
				}, async () => {
					await this.fetchBookingsByRoomIdOrCode({ roomIdOrCode: this.state.selectedRoom.id })
				})
			} else {
				this.setState({
					planBeginAt: date.isSame(moment().format(formatDay), 'day') ? moment() : date.clone().startOf('day')
					, removeClass: true
				}, async () => {
					await this.fetchBookingsByRoomIdOrCode({ roomIdOrCode: this.state.selectedRoom.id })
				})
			}

		}

		resetDate = (start, end) => {
			this.props.form.setFieldsValue({
				beginTime: start.clone(),
				endTime: end.isSame(this.state.planBeginAt.format('YYYY-MM-DD'), 'day') ? end.clone() : moment().hour(23).minute(59).second(0)
			});
		}

		forbidInput = () => {
			this.setState({ selectedLocation: null }, () => this.props.form.setFieldsValue({ room: null }))
		}

		showModal = type => {
			this.setState({ selectUserVisible: true, selectType: type ? 'muti' : '', isLoadUser: true })
		}

		meetingRadioChange = value => {
			this.setState({ meetingRadio: value })
		}

		disabledHours = () => {
			if (moment().startOf('day').isSame(moment(this.state.planBeginAt).startOf('day'))) {
				let result = [];
				for (let i = 0; i < moment().hour(); i++) {
					result.push(i);
				}
				return result;
			}
		}

		render() {
			return <WrappedComponent
				{...this.props}
				me={this.me}
				token={this.token}
				date={this.state.date}
				length={this.state.length}
				meetings={this.state.meetings}
				allRooms={this.state.allRooms}
				selectedRoom={this.state.selectedRoom}
				attachments={this.state.attachments}
				isLoadUser={this.state.isLoadUser}
				selectRoomVisible={this.state.selectRoomVisible}
				planBeginAt={this.state.planBeginAt}
				httpServiceEndpoint={this.httpServiceEndpoint}
				mutiOptionData={this.state.mutiOptionData}
				meetingRadio={this.state.meetingRadio}
				selectedUser={this.state.selectedUser}
				selectedLocation={this.state.selectedLocation}
				selectType={this.state.selectType}
				onSave={e => this.onSave(e)}
				resetDate={this.resetDate}
				forbidInput={this.forbidInput}
				removeClass={this.state.removeClass}
				resetRemoveClass={() => this.setState({ removeClass: false })}
				setLength={length => this.setLength(length)}
				showModal={flag => this.showModal(flag)}
				radioChange={value => this.radioChange(value)}
				renderTreeNodes={space => this.renderTreeNodes(space)}
				selectUserVisible={this.state.selectUserVisible}
				handleChange={this.handleChange}
				dateChange={this.dateChange}
				showModal={flag => this.showModal(flag)}
				clearRowKeys={this.state.clearRowKeys}
				timelineMode={this.props.match.params.timelineMode}
				onSelectUserCancel={() => this.onSelectUserCancel()}
				setSelectRoomVisible={this.setSelectRoomVisible}
				onSelectUserConfirm={(users) => this.onSelectUserConfirm(users)}
				onSelectRoomsCancel={this.onSelectRoomsCancel}
				onSelectRoomsConfirm={this.onSelectRoomsConfirm}
				meetingRadioChange={value => this.meetingRadioChange(value)}
				mutiOptionDataOnChange={value => this.mutiOptionDataOnChange(value)}
				selectedRoomId={this.state.selectedRoomId}
				disabledHours={this.disabledHours}
			/>;
		}
	});
};

export default withRouter(Form.create()(hot(CreateBooking)));