import React from 'react';
import { Result } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';

function NoAccess() {
	const { t } = useTranslation();
	return (
		<Result
			status="403"
			title="403"
			subTitle={t('noAccessTitle')}
		/>
	);
}

export default NoAccess;