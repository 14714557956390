import React from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import _ from 'lodash';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import SelectUser from '../components/SelectUser';
import { PageHeader, Form, Input, DatePicker, TimePicker, Button, Select, message, Divider, Row, Col } from 'antd';

const { Item } = Form;
const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};
const ItemLayout = {
	labelCol: { span: 8, },
	wrapperCol: { span: 14 }
};
function CreateVideMeeting(props) {
	if (!props.me) return <></>
	const { t } = useTranslation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const { getFieldDecorator, setFieldsValue } = props.form;
	let value = _.chain(props.mutiOptionDatas).map(x => x.id).value()
	return (<div className='container' style={{ background: '#fff' }}>
		<div style={{ background: '#FFFFFF', boxShadow: '0px 0px 0px rgba(221, 229, 234, 0.5)', margin: '0 auto' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('bookVideoConference')}
			>
				<span style={{ color: 'rgba(68, 81, 95, 0.65)' }}>
					{t('applicant')}：{props.me.name} <Divider type='vertical' />
					{t('memberCode')}：{props.me.code || t('none')} <Divider type='vertical' />
					{t('mobile')}：{props.me.mobile || t('none')}<Divider type='vertical' />
					{t('email')}：{props.me.email || t('none')}</span>
			</PageHeader>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto', background: '#FFFFFF', padding: '24px 56px' }}>
				<Form {...layout} style={{ marginLeft: '-1%' }}>
					<Item label={<span style={{ marginLeft: 11 }}>{t('meetingOrganizer')}</span>} style={{ margin: 0 }} labelAlign='left'>
						<div className='d-flex'>
							<Item >
								{getFieldDecorator('organizer', {
									initialValue: props.selectedUser ? props.selectedUser.name : '',
									rules: [{ required: true, message: t('enterMeetingOwnerMessage') }]
								})(
									<div>
										<Input style={{ width: 150 }} value={props.selectedUser ? props.selectedUser.name : ''} /> <Button type='primary' onClick={() => props.showModal()}>{t('select')}</Button>
									</div>
								)}
							</Item>
							<div style={{ flex: 1, display: 'flex', marginLeft: 24 }}>
								<Item label={t('contactNumber')} style={{ flex: 1 }} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }} labelAlign='left'>
									{getFieldDecorator('organizerMobile', {
										initialValue: props.selectedUser ? props.selectedUser.mobile : '',
										rules: [
											{ required: false, message: t('enterContactMessage') },
											{ pattern: new RegExp(/^1[0-9]\d{9}$/, "g"), message: t('enterValidMobileMessage') }
										]
									})(
										<Input style={{ width: 160 }} placeholder={t('enterContactMessage')} />
									)}
								</Item>
							</div>
						</div>
					</Item>
					<Item label={<span>{t('meetingTitle')}</span>} labelAlign='left'>
						{getFieldDecorator('subject', {
							initialValue: props.videoMeeting ? props.videoMeeting.subject : null,
							rules: [{ required: true, message: t('enterMeetingTitleMessage') }]
						})(
							<Input style={{ width: '100%' }}
								onChange={e => props.setLength(e.target.value)}
								maxLength={50}
								disabled={props.isSubmit}
								suffix={<span style={{ color: 'rgba(50, 56, 62, 0.25)' }}>{props.length} / 50</span>} />
						)}
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('meetingTime')}</span>} labelAlign='left' style={{ margin: 0 }}>
						<div className='d-flex'>
							<Item>
								{getFieldDecorator('planBeginAt', {
									initialValue: props.videoMeeting ? moment.unix(props.videoMeeting.beginAt) : moment()
								})(
									<DatePicker style={{ width: 260, marginRight: 40 }} format={'ll (dddd)'} allowClear={false} disabled={props.isSubmit} />
								)}
							</Item>
							<div style={{ flex: 1, display: 'flex' }}>
								<Item style={{ flex: 1 }}>
									{getFieldDecorator('beginTime', {
										initialValue: moment().add(15, 'minute'),
									})(
										<TimePicker style={{ width: '100%' }}
											format="A h:mm"
											minuteStep={1}
											use12Hours={false}
											disabled={props.isSubmit}
											allowClear={false}
											onChange={time => {
												setFieldsValue({
													endTime: moment(time).add(1, 'hour').isSame(moment().format('YYYY-MM-DD'), 'day') ? moment(time).add(1, 'hour') : moment().hour(23).minute(59).second(59)
												});
											}}
										/>
									)}
								</Item>
								<Item>
									<ArrowRightOutlined style={{ margin: 'auto 16px' }} />
								</Item>
								<Item style={{ flex: 1 }}>
									{getFieldDecorator('endTime', {
										initialValue: moment().add(75, 'minute')
									})(
										<TimePicker style={{ width: '100%' }}
											format="A h:mm"
											allowClear={false}
											minuteStep={1}
											use12Hours={false}
											disabled={props.isSubmit}
										/>
									)}
								</Item>
							</div>
						</div>
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('participatingMembers')}</span>} labelAlign='left'>
						{getFieldDecorator('personals')(
							<div className='d-flex'>
								<Select
									style={{ marginRight: 8 }}
									value={_.chain(props.mutiOptionData).map(x => x.id).value()}
									mode='multiple'
									open={false}
									allowClear={true}
									disabled={props.isSubmit}
									onChange={value => props.mutiOptionDataOnChange(value)}
								>
									{_.map(props.mutiOptionData, d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
								</Select>
								<div className='d-flex' style={{ flex: 1, marginLeft: 5 }}>
									<Button type='primary' disabled={props.isSubmit} onClick={() => props.showModal(true)}>{t('select')}</Button>
								</div>
							</div>
						)}
					</Item>
					<Item wrapperCol={{ offset: 4 }}>
						<Button size='large' type="primary" onClick={props.onConfirm}>{t('submitBooking')}</Button>
					</Item>
				</Form>
			</div>
			<SelectUser
				selectedUser={props.selectType === 'muti' ? props.mutiOptionData : props.mutiOptionDatas}
				visible={props.selectUserVisible}
				onCancel={props.onSelectUserCancel}
				onConfirm={props.onSelectUserConfirm}
				selectType={props.selectType}
			/>
		</div>
	</div >
	)
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends React.Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				isSubmit: false,
				videoMeeting: null,
				info: null,
				length: 0,
				mutiOptionData: [],
				mutiOptionDatas: [],
				selectUserVisible: false,
				selectType: null,
				selectedUser: {}
			}
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.setState({ selectedUser: this.me })
			await this.loadVideoMeeting();
		}
		async loadVideoMeeting() {
			if (this.props.match.params.id) {
				let videoMeeting = await this.transportLayer.getVideoMeeting(this.props.match.params.id);
				this.setState({ videoMeeting })
			}
		}

		setLength = value => {
			this.setState({ length: value.length })
		}

		showModal = type => {
			this.setState({ selectUserVisible: true, selectType: type ? 'muti' : '' })
		}

		onSelectUserCancel = () => {
			this.setState({ selectUserVisible: false })
		}

		onSelectUserConfirm = keys => {
			if (!this.state.selectType) {
				this.setState({ selectedUser: _.chain(keys).first().value(), mutiOptionDatas: keys })
				this.props.form.setFieldsValue({
					organizer: _.chain(keys).first().value()
				});
			} else {
				let users = [];
				_.each(keys, user => {
					users.push({
						id: user.id,
						name: user.name,
						department: user.department,
						mobile: user.mobile,
						email: user.email
					});
				})
				this.setState({ mutiOptionData: users });
			}
			this.setState({ selectUserVisible: false })
		}

		onConfirm = () => {
			this.props.form.validateFields(async(err, values) => {
				if (err) return
				let startMinute = values.beginTime.minute();
				let startHour = values.beginTime.hour();
				let beginAt = values.planBeginAt.clone().set({ hour: startHour, minute: startMinute });

				let endMinute = values.endTime.minute();
				let endHour = values.endTime.hour();
				let endAt = values.planBeginAt.clone().set({ hour: endHour, minute: endMinute });
				values.beginAt = beginAt.unix();
				values.endAt = endAt.unix();
				if (values.beginAt >= values.endAt) {
					message.error(this.t('meetingTimeInvlalidMessage'));
					return
				}
				let attendees = [];
				_.each(this.state.mutiOptionData, item => attendees.push(item.id));
				values.attendees = attendees
				values.organizer = this.state.selectedUser.id;
				delete values.beginTime;
				delete values.endTime;
				delete values.planBeginAt;
				delete values.personals;
				try {
					let data = await this.transportLayer.createVideoMeeting(values)
					message.success(this.t('meetingCreated'), 3);
					this.props.history.push(`/video/meeting/${data.data.id}/view`)
				} catch (error) {
					message.error('创建失败');
				}
			})
		}

		back = () => this.props.history.push('/video/meeting');

		mutiOptionDataOnChange = ids => {
			let users = [];
			_.each(this.state.mutiOptionData, user => {
				if (_.includes(ids, user.id)) {
					users.push(user);
				}
			})
			this.setState({ mutiOptionData: users });
		}
		mutiOptionDataOnChanges = ids => {
			let users = [];
			_.each(this.state.mutiOptionDatas, user => {
				if (_.includes(ids, user.id)) {
					users.push(user);
				}
			})
			this.setState({ mutiOptionDatas: users });
			let mutiOptionDatas = users
			this.props.form.setFieldsValue({
				organizer: mutiOptionDatas
			})
		}

		render() {
			return <WrappedComponent
				{...this.props}
				me={this.me}
				selectedUser={this.state.selectedUser}
				isSubmit={this.state.isSubmit}
				videoMeeting={this.state.videoMeeting}
				back={this.back}
				length={this.state.length}
				info={this.state.info}
				onConfirm={this.onConfirm}
				setLength={this.setLength}
				selectType={this.state.selectType}
				showModal={flag => this.showModal(flag)}
				mutiOptionData={this.state.mutiOptionData}
				mutiOptionDatas={this.state.mutiOptionDatas}
				selectUserVisible={this.state.selectUserVisible}
				onSelectUserCancel={() => this.onSelectUserCancel()}
				onSelectUserConfirm={(users) => this.onSelectUserConfirm(users)}
				mutiOptionDataOnChange={value => this.mutiOptionDataOnChange(value)}
				mutiOptionDataOnChanges={value => this.mutiOptionDataOnChanges(value)}
			/>
		}
	})
}

export default withRouter(Form.create()(hoc(CreateVideMeeting)))
