import React from 'react'
import _ from 'lodash';
import moment from 'moment';
import { Tag } from 'antd';
export const isAdmin = user => user && user.admin;

export const isDispatcher = user => {
	if (user) {
		return _.find(user.userRules, rule => rule.type === 'dispatcher') ? true : false
	}
}

export const isServiceStaff = user => {
	if (user) {
		return _.find(user.userRules, rule => rule.type === 'serviceStaff') ? true : false
	}
}

export const memberAudit = user => {
	if (isAdmin(user) || isDispatcher(user)) { // 是调度员和admin 可以做任何操作
		return true;
	} else {
		return false;
	}
}

export const meetingState = booking => {
	if (booking.state === 'auditing') {
		return '审核中';
	}
	if (booking.state === 'reject') {
		return '审核驳回';
	}
	if (booking.state === 'dispatching') {
		return '调度中';
	}
	if (booking.state === 'cancel') {
		return '预订取消';
	}
	if (booking.state === 'success') {
		return '预订成功';
	}
}

// 预定之后的审核状态 (不分是否有调度员 直接根据状态)
export const adminOrDispatcherOrGeneral = booking => {
	if (booking.state === 'auditing') {
		return <Tag color='#108ee9'>审核中</Tag>;
	}
	if (booking.state === 'reject') {
		return <Tag>审核驳回</Tag>;
	}
	if (booking.state === 'dispatching') {
		return <Tag color='#D4380D'>调度中</Tag>;
	}
	if (booking.state === 'cancel') {
		return <Tag>预订取消</Tag>;
	}
	if (booking.state === 'success') {
		return <Tag color='#87d068'>预订成功</Tag>
	}
}

export const meetingTimeStatus = booking => {
	if (booking.state === 'success' || !booking.state) {
		if (moment().unix() >= booking.beginAt && moment().unix() <= booking.endAt) {
			return <Tag color='#D4380D'>进行中</Tag>
		}
		if (moment().unix() >= booking.endAt) {
			return <Tag>已结束</Tag>
		}
	}

	return <></>
}

// 有无服务人员
export const serviceMember = booking => {
	if (booking.state === 'success') {
		if (booking.serviceState === true) {
			return '已完成'
		}
		if (booking.serviceState === false) {
			return '执行中'
		}
		if (booking.serviceState === null) {
			return '已完成'
		}
	}
}

// 拒绝状态
export const rejectState = booking => booking.state === 'reject' ? true : false

// 取消状态
export const cancelState = booking => booking.state === 'cancel' ? true : false

// 预订成功状态
export const successState = booking => booking.state === 'success' ? true : false

// 调度中状态
export const dispatchState = booking => booking.state === 'dispatching' ? true : false

// 审核中状态
export const auditingState = booking => booking.state === 'auditing' ? true : false

//不能取消预订 (预订成功 会议开始之后和拒绝状态 都不可预订)
export const notCancel = booking => (successState(booking) && moment().unix() >= booking.beginAt) || rejectState(booking) || cancelState(booking);

export const isCancel = booking => {
	if (moment().unix() <= booking.endAt) {
		if (rejectState(booking) || cancelState(booking)) {
			return false;
		} else {
			return true
		}
	} else {
		return false
	}
}

//根据状态 不是审批拒绝和审核中 才能取消
export const checkIsCancel = booking => !rejectState(booking) && booking.state !== 'auditing' ? true : false

// 检查当前时间是否是在会议开始时间之前
export const checkNowTime = beginAt => moment().unix() < beginAt

export const meetingStates = [
	{ name: '所有状态', state: 'all', service: null },
	{ name: '审核中', state: 'auditing', service: null },
	// { name: '调度中', state: 'dispatching', service: null },
	// { name: '会议开始', state: 'begin', service: { state: false } },
	// { name: '会议结束', state: 'end', service: { state: false } },
	{ name: '预订成功', state: 'success', service: { state: null } },
	{ name: '预订取消', state: 'cancel', service: null },
	{ name: '审核驳回', state: 'reject', service: null },
]

export const serviceStates = [
	{ name: '全部', state: '', service: null },
	{ name: '执行中', state: 'progressing', service: { state: false } },
	{ name: '已完成', state: 'finished', service: { state: true } },
]

export const bookingSource = (booking, meId) => {
	if (booking.createdBy && booking.createdBy.id === meId) {
		return '我创建的';
	}
	if (booking.organizer && booking.organizer.id === meId) {
		return '我召集的';
	}
	if (!_.isEmpty(booking.attendees)) {
		let attendee = _.find(booking.attendees, p => p.id === meId);
		if (attendee) {
			return '我参加的';
		}
	}
}