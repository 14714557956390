const _ = require('lodash');

const getErrorMessage = (error) => {
	if (!error) return '';
	if (error.response && error.response.data) return error.response.data;
	return error.toString();
}

const formatBuildings = (rooms, needSelectFirstRoom) => {
	let buildings = _.unionBy(_.map(rooms, 'building'), 'id');
	let buildingGroups = _.groupBy(rooms, 'building.id');
	let spaces = [];
	let countrys = _.groupBy(_.orderBy(buildings, 'weight', 'desc'), 'city');
	let firstRoom = null;
	let cityCount = 0, buildingCount = 0, floorCount = 0, roomCount = rooms.length;

	for (var city in countrys) {
		let districts = _.groupBy(countrys[city], 'district');

		let children = [];

		for (var district in districts) {
			let treeDistricts = [];

			_.each(districts[district], d => {
				d.code = d.id;
				treeDistricts.push(_.omit(d, ['children']));

				d.children = [];
				d.code = `building-${d.id}`;
				_.each(d.floors, floor => {
					let fchildren = _.filter(rooms, room => {
						if (room.building === d.id && room.floor === floor) {
							room.building = { name: d.name, city: d.city, district: d.district, id: d.id, floors: d.floors }
							return true;
						}

						if (room.building.id === d.id && room.floor === floor) return true;

						return false;
					})

					if (fchildren.length === 0) return false;

					_.each(fchildren, room => { room.selectable = true; })
					if (needSelectFirstRoom && !firstRoom) {
						firstRoom = _.first(fchildren);
					}

					d.children.push({ name: floor, code: `floor-${floor}-${d.id}`, hasRoom: true, children: fchildren, parent: d })
					floorCount++;
				})
				buildingCount++;
			})

			if (districts[district].length === 0) continue;
			cityCount++;
			children.push({ name: district, code: district, children: districts[district] })
		}

		if (children.length === 0) continue;
		spaces.push({ name: city, code: city, children })
	}

	let expandedSpaceKeys = initExpandedSpaceKeys(spaces, []);
	return { expandedSpaceKeys, spaces, firstRoom, buildingGroups, cityCount, buildingCount, floorCount, roomCount }
}

const initExpandedSpaceKeys = (spaces, expandedKeys) => {
	for (let child of spaces) {
		if (_.isEmpty(child.children)) continue;
		expandedKeys.push(child.code);
		expandedKeys = initExpandedSpaceKeys(child.children, expandedKeys);
	}
	return expandedKeys;
}

const grades = [
	{ max: 101, min: 100, text: 'S' },
	{ max: 100, min: 75, text: 'A' },
	{ max: 75, min: 50, text: 'B' },
	{ max: 50, min: 25, text: 'C' },
	{ max: 25, min: 0, text: 'D' }
];

const healths = [
	{ max: 101, min: 90, text: 'AAA' },
	{ max: 90, min: 80, text: 'AA' },
	{ max: 80, min: 70, text: 'A' },
	{ max: 70, min: 60, text: 'BBB' },
	{ max: 60, min: 50, text: 'BB' },
	{ max: 50, min: 40, text: 'B' },
	{ max: 40, min: 30, text: 'CCC' },
	{ max: 30, min: 20, text: 'CC' },
	{ max: 20, min: 10, text: 'C' },
	{ max: 10, min: 0, text: 'D' }
];

const weeks = {
	1: '周一',
	2: '周二',
	3: '周三',
	4: '周四',
	5: '周五',
	6: '周六',
	7: '周日'
}

export { getErrorMessage, formatBuildings, grades, healths, weeks };