import './style.less';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import SpaceStatus from '../components/SpaceStatus';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import React, { Component } from 'react';
import 'react-rrule-generator/build/styles.css';
import { withRouter, useLocation } from 'react-router-dom';
import { ArrowRightOutlined, UploadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { PageHeader, Form, Input, DatePicker, TimePicker, Button, Select, message, Row, Col, Radio, Modal, Checkbox, Divider, Upload } from 'antd';
import SelectUser from '../components/SelectUser';
import SelectRooms from '../components/SelectRooms';
import { meetingStates, memberAudit, meetingTimeStatus, adminOrDispatcherOrGeneral } from './Role';
import AreaRoomTree from '../components/AreaRoomTree'
import { disabledHours, disabledMinutes, disabledBeginMinutes } from './DisableTime';

const { Item } = Form;
const { Option } = Select;
const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const ItemLayout = {
	labelCol: { span: 2, },
	wrapperCol: { span: 22 }
};

function EditBooking(props) {
	if (!props.me) return <></>;
	if (!props.booking) return <></>;
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
	return <div className='container'>
		<div style={{ background: '#FFFFFF', boxShadow: '0px 0px 8px rgba(221, 229, 234, 0.5)', margin: '0 auto' }}>
			<div className="d-flex align-center justify-between" style={{ paddingRight: 56, borderBottom: '1px solid #f1f1f1', background: '#fafafa' }}>
				<PageHeader
					style={{ padding: '24px 56px 16px', borderBottom: '1px solid #f1f1f1', background: '#fafafa' }}
					ghost={false}
					onBack={() => props.history.goBack()}
					title={t('bookRoom')}
				>
					{
						props.booking.organizer &&
						<span style={{ color: 'rgba(68, 81, 95, 0.65)' }}>
							{t('applicant')}：{props.booking.organizer.name} <Divider type='vertical' />
							{t('mobile')}：{props.booking.organizer.mobile}<Divider type='vertical' />
							{t('email')}：{props.booking.organizer.email}</span>
					}
				</PageHeader>
				<div className="d-flex">
					{memberAudit(props.me) && adminOrDispatcherOrGeneral(props.booking)}
					{meetingTimeStatus(props.booking)}
				</div>
			</div>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto', background: '#FFFFFF', padding: '24px 56px' }}>
				<Form {...layout} style={{ marginLeft: '-1%' }}>
					<Item label={<span style={{ marginLeft: 11 }}>{t('meetingOrganizer')}</span>} style={{ margin: 0 }} labelAlign='left'>
						<div className='d-flex'>
							<Item >
								{getFieldDecorator('username', {
									initialValue: props.selectedUser ? props.selectedUser.name : '',
									rules: [{ required: false, message: t('enterMeetingOwnerMessage') }]
								})(
									<div>
										<Input style={{ width: 150 }} value={props.selectedUser ? props.selectedUser.name : ''} /> <Button type='primary' onClick={() => props.showModal()}>{t('select')}</Button>
									</div>
								)}
							</Item>
							<div style={{ flex: 1, display: 'flex', marginLeft: 24 }}>
								<Item label={t('contact')} style={{ flex: 1 }} {...ItemLayout} labelAlign='left'>
									{getFieldDecorator('organizerMobile', {
										initialValue: props.selectedUser ? props.selectedUser.mobile : '',
										rules: [
											{ required: false, message: t('enterContactMessage') },
											{ pattern: new RegExp(/^1[3|4|5|7|8][0-9]\d{4,8}$/, "g"), message: t('enterValidMobileMessage') }]
									})(
										<Input style={{ width: 160 }} placeholder={t('enterContactMessage')} />
									)}
								</Item>
							</div>
						</div>
					</Item>
					<Item label={t('meetingTitle')} labelAlign='left'>
						{getFieldDecorator('subject', {
							initialValue: props.booking.subject,
							rules: [{ required: true, message: t('enterMeetingTitleMessage') }]
						})(
							<Input style={{ width: '100%' }}
								onChange={e => props.setLength(e.target.value)}
								maxLength={50}
								addonAfter={<span style={{ color: 'rgba(50, 56, 62, 0.45)' }}>{props.length} / 50</span>} />
						)}
					</Item>

					<Item label={t('roomName')} labelAlign='left'>
						{getFieldDecorator('room', {
							initialValue: props.selectedLocation,
							rules: [{ message: t('selectSpaceMessage') }]
						})(
							<div className="d-flex">
								<AreaRoomTree time={props.planBeginAt} permission={false} onChange={e => props.onSelectRoomsConfirm(e)} selectedRoomId={props.selectedRoomId} selectedSpaces={props.selectedLocation === 'null' ? [] : [props.selectedLocation]} />
							</div>
						)}
					</Item>
					<Item className='demoRoomStatus' label={<span style={{ marginLeft: 11 }}>{t('roomStatus')}</span>} labelAlign='left'>
						{getFieldDecorator('spaceStatus', {
						})(
							<SpaceStatus
								room={props.selectedRoom}
								mainPage={false}
								meetings={props.meetings}
								timelineMode='allday'
								resetDate={props.resetDate}
								timelineValue={props.planBeginAt}
								removeClass={props.removeClass}
								resetRemoveClass={props.resetRemoveClass}
							/>
						)}
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('meetingTime')}</span>} labelAlign='left' style={{ margin: 0 }}>
						<div className='d-flex'>
							<Item>
								{getFieldDecorator('planBeginAt', {
									initialValue: moment(parseInt(props.match.params.start))
								})(
									<DatePicker disabledDate={current => current < moment().add(-1, 'day').endOf('day')} style={{ width: 260, marginRight: 40 }} format={'ll (dddd)'} allowClear={false} onChange={props.dateChange} />
								)}
							</Item>
							<div style={{ flex: 1, display: 'flex' }}>
								<Item style={{ flex: 1 }}
								>
									{getFieldDecorator('beginTime', {
										initialValue: moment(parseInt(props.match.params.start)),
									})(
										<TimePicker style={{ width: '100%' }}
											format="HH:mm" minuteStep={1} use12Hours={false}
											disabledHours={props.disabledHours}
											disabledMinutes={selectedHour => disabledBeginMinutes(getFieldValue('planBeginAt'), selectedHour, true)}
											allowClear={false}
											onChange={time => {
												setFieldsValue({
													endTime: moment(time).add(1, 'hour').isSame(moment(props.planBeginAt).format('YYYY-MM-DD'), 'day') ? moment(time).add(1, 'hour') : moment().hour(23).minute(59).second(59)
												});
											}}
										/>
									)}
								</Item>
								<Item>
									<ArrowRightOutlined style={{ margin: 'auto 16px' }} />
								</Item>
								<Item
									style={{ flex: 1 }}
								>
									{getFieldDecorator('endTime', {
										initialValue: moment(parseInt(props.match.params.end))
									})(
										<TimePicker style={{ width: '100%' }}
											disabledHours={() => disabledHours(getFieldValue('beginTime'))} disabledMinutes={selectedHour => disabledMinutes(getFieldValue('beginTime'), selectedHour)}
											allowClear={false}
											format="HH:mm" minuteStep={1} use12Hours={false}
										/>
									)}
								</Item>
							</div>
						</div>
					</Item>
					<Item label={<span style={{ marginLeft: 11 }}>{t('participatingMembers')}</span>} labelAlign='left'>
						{getFieldDecorator('personals')(
							<div className='d-flex'>
								<Select
									size='large'
									style={{ marginRight: 8 }}
									value={_.chain(props.mutiOptionData).map(x => x.id).value()}
									mode='multiple'
									open={false}
									onChange={value => props.mutiOptionDataOnChange(value)}
								>
									{_.map(props.mutiOptionData, d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
								</Select>
								<div className='d-flex' style={{ flex: 1, marginLeft: 5 }}>
									<Button size='large' type='primary' onClick={() => props.showModal(true)}>{t('select')}</Button>
								</div>
							</div>
						)}
					</Item>
					{/* <Item label={<span style={{ marginLeft: 11 }}>{t('meetingAttachments')}</span>} labelAlign='left'>
						{getFieldDecorator('attachments')(
							<div style={{ display: 'flex' }}>
								<Upload
									accept='*'
									style={{ width: 480 }}
									headers={{ Authorization: `Bearer ${props.token}` }}
									action={`${props.httpServiceEndpoint}/v2/files/upload`}
									onChange={props.handleChange}
									fileList={props.attachments}
								>
									<Button>
										<UploadOutlined /> {t('uploadAttachment')}
									</Button>
								</Upload>
								<div style={{ marginLeft: 20 }}>
									<InfoCircleFilled style={{ color: '#F2994A', marginRight: 8 }} /> {t('attachmentUnder20MMessage')}
								</div>
							</div>
						)}
					</Item> */}
					<Item wrapperCol={{ offset: 4 }}>
						<Button size='large' type="primary" onClick={props.onSave}>{t('submitBooking')}</Button>
					</Item>
				</Form>

				<SelectUser
					selectedUser={props.selectType ? props.mutiOptionData : [props.selectedUser]}
					visible={props.selectUserVisible}
					onCancel={props.onSelectUserCancel}
					onConfirm={props.onSelectUserConfirm}
					selectType={props.selectType}
				/>
				<SelectRooms
					selectSpace={props.selectedRoom}
					visible={props.selectRoomVisible}
					onCancel={props.onSelectRoomsCancel}
					onConfirm={props.onSelectRoomsConfirm}
					clearRowKeys={props.clearRowKeys}
				/>
			</div>
		</div>
	</div >;
}

let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				selectedRoomId: null,
				selectedLocation: this.props.match.params.roomid,
				date: moment(),
				radio: 2,
				meetingRadio: false,
				selectedUser: {},
				selectMutiUser: [],
				allRooms: [],
				booking: null,
				selectUserVisible: false,
				mutiOptionData: [],
				selectType: null,
				length: 0,
				attachments: [],
				planBeginAt: moment(parseInt(this.props.match.params.start)).isSame(moment().format('YYYY-MM-DD'), 'day') ?
					moment() : moment(parseInt(this.props.match.params.start)).startOf('day'),
				removeClass: false, // 是否移除 时间轴上的红色块
				selectRoomVisible: false,
				selectedRoom: null,
				isLoadUser: false,
				clearRowKeys: false,
			};
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.httpServiceEndpoint = AppContextJS.instance.httpService;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.token = AppContextJS.instance.token;
			await this.fetchBookingsByRoomIdOrCode({ roomIdOrCode: this.props.match.params.roomid });
			await this.fetchBookingById(this.props.match.params.bookingid);
		}

		onSave = e => {
			e.preventDefault();
			this.props.form.validateFields(async (err, values) => {
				if (err) return

				if (this.state.selectedRoom && this.state.selectedRoom.forbidden) {
					if (moment(values.planBeginAt).isBetween(moment.unix(this.state.selectedRoom.forbidden.begin), moment.unix(this.state.selectedRoom.forbidden.end))) {
						message.info('该会议室在当前选中时间处于下线状态，请重新选择');
						return;
					}
				}

				let startMinute = values.beginTime.minute();
				let startHour = values.beginTime.hour();
				values.planBeginAt = values.planBeginAt.clone().set({ hour: startHour, minute: startMinute });

				let endMinute = values.endTime.minute();
				let endHour = values.endTime.hour();
				values.planEndAt = values.planBeginAt.clone().set({ hour: endHour, minute: endMinute });
				values.beginAt = moment(values.planBeginAt).second(0).unix();
				values.endAt = moment(values.planEndAt).second(0).unix();
				if (moment(values.endAt).hour === 0) {
					values.endAt = moment(values.endAt).hour(23).minute(59).seconds(0).unix()
				}

				values.organizer = this.state.selectedUser.id;
				values.room = this.state.selectedRoom.id;
				// values.hasVM = values.hasVM ? JSON.parse(values.hasVM) : false;
				values.attendees = _.map(this.state.mutiOptionData, user => { return user.id });

				if (this.state.attachments && this.state.attachments.length > 0) {
					let attachments = [];
					_.map(this.state.attachments, attachment => {
						if (attachment.response) {
							attachments.push(attachment.response.path);
						} else {
							attachments.push(attachment.name);
						}
					})
					values.attachments = attachments;
				} else {
					values.attachments = [];
				}
				delete values.beginTime;
				delete values.endTime;
				delete values.username;
				delete values.spaceStatus;
				delete values.planBeginAt;
				delete values.planEndAt;
				this.transportLayer.editBooking(this.props.match.params.bookingid, values).then(res => {
					message.success(this.t('meetingModificationSuccessMessage'));
					this.props.history.push({ pathname: `/local/booking/${res.data.id}/view`, state: '/dispatch' });
				}, error => {
					if (_.includes(error.response.data, 'has been booked during your plan time')) {
						Modal.error({
							title: this.t('meetingTimeConflictMessage'),
							okText: this.t('closeMeeting'),
							centered: true
						})
					} else {
						message.error('meetingModificationFailureMessage', 5)
					}
				});
			});
		}

		async fetchBookingById(id) {
			if (id) {
				let booking = await this.transportLayer.getBookingById(id);
				// let attachments = [];
				// if (booking && booking.attachments) {
				// 	_.each(booking.attachments, item => {
				// 		attachments.push({
				// 			uid: item,
				// 			name: item,
				// 			url: `${this.httpServiceEndpoint}/v2${item}`,
				// 			status: 'done'
				// 		})
				// 	})
				// }
				this.setState({
					booking,
					mutiOptionData: booking.attendees,
					selectedUser: booking.organizer,
					selectedLocation: booking.room.id,
					selectedRoom: booking.room,
					meetingRadio: booking.vcs,
					// radio: booking.type,
					// attachments: attachments,
					length: booking.subject.length
				});
			}
		}

		async fetchBookingsByRoomIdOrCode({ roomIdOrCode }) {
			if (roomIdOrCode) {
				let meetings = await this.transportLayer.getBookingsByRoomIdOrCode({
					roomIdOrCode,
					begin: this.state.planBeginAt.startOf('day').unix(),
					end: this.state.planBeginAt.endOf('day').unix()
				});
				this.setState({ meetings: meetings.docs });
			}
		}

		radioChange = value => {
			this.setState({ radio: value })
		}

		showModal = (type) => {
			this.setState({ selectUserVisible: true, selectType: type ? 'muti' : '' })
		}

		onSelectUserConfirm = keys => {
			if (!this.state.selectType) {
				this.setState({ selectedUser: _.chain(keys).first().value() })
			} else {
				let users = [];
				_.each(keys, user => {
					users.push({
						id: user.id,
						name: user.name,
						department: user.department,
						mobile: user.mobile,
						email: user.email
					});
				})
				this.setState({ mutiOptionData: users });
			}
			this.setState({ selectUserVisible: false, isLoadUser: false })
		}

		onSelectUserCancel = () => {
			this.setState({ selectUserVisible: false, isLoadUser: false, clearRowKeys: false })
		}
		onSelectRoomsCancel = () => {
			this.setState({ selectRoomVisible: false, isLoadUser: false, clearRowKeys: false })
		}

		onSelectRoomsConfirm = async selectedLocation => {
			this.setState({ selectedRoomId: selectedLocation })
			if (!selectedLocation) {
				this.props.form.setFieldsValue({ room: null })
				return;
			}
			let room = await this.transportLayer.getSpacesById(selectedLocation);
			if (!room) return;
			this.setState({
				selectRoomVisible: false,
				isLoadUser: false,
				selectedRoom: room,
				room: room,
				selectedLocation: `${room.building.name}/${room.floor}/${room.name}`
			}, async () => {
				await this.fetchBookingsByRoomIdOrCode({
					roomIdOrCode: this.state.selectedRoom.id,
					begin: this.state.planBeginAt.startOf('day').unix(),
					end: this.state.planBeginAt.endOf('day').unix()
				})
				this.props.form.setFieldsValue({ room: room.id })
			})
		}

		mutiOptionDataOnChange = (ids) => {
			let users = [];
			_.each(this.state.mutiOptionData, user => {
				if (_.includes(ids, user.id)) {
					users.push(user);
				}
			})
			this.setState({ mutiOptionData: users });
		}

		meetingRadioChange = value => {
			this.setState({ meetingRadio: value })
		}

		setLength = (value) => {
			this.setState({ length: value.length });
		}

		handleChange = ({ file, fileList }) => {
			if (file.size > 20 * 1024 * 1024) {
				message.info(this.t('attachmentUnder20MMessage'));
				return;
			}

			this.setState({ attachments: fileList });
		}

		dateChange = async date => {
			if (!this.state.selectedRoom) {
				message.warning(this.t('selectRoomToCheckStat'));
				return false
			}
			if (!date.isSame(moment().format('YYYY-MM-DD'), 'day')) {
				this.props.form.setFieldsValue({
					beginTime: date.clone().startOf('day').add(8, 'hour'),
					endTime: date.clone().startOf('day').add(9, 'hour')
				});
			} else {
				this.props.form.setFieldsValue({
					beginTime: moment().clone().add(15, 'minute'),
					endTime: moment().clone().add(1, 'hour').add(15, 'minute')
				});
			}
			if (this.props.match.params.timelineMode === 'allday') {
				this.setState({
					planBeginAt: date.isSame(moment().format('YYYY-MM-DD'), 'day') ? moment() : date.clone().startOf('day')
					, removeClass: true
				}, async () => {
					await this.fetchBookingsByRoomIdOrCode({ roomIdOrCode: this.state.selectedRoom.id })
				})
			} else {
				this.setState({
					planBeginAt: date.isSame(moment().format('YYYY-MM-DD'), 'day') ? moment() : date.clone().startOf('day')
					, removeClass: true
				}, async () => {
					await this.fetchBookingsByRoomIdOrCode({ roomIdOrCode: this.state.selectedRoom.id })
				})
			}
		}

		resetDate = (start, end) => {
			this.props.form.setFieldsValue({
				beginTime: start.clone(),
				endTime: end.isSame(this.state.planBeginAt.format('YYYY-MM-DD'), 'day') ? end.clone() : moment().hour(23).minute(59).second(0)
			});
		}

		setSelectRoomVisible = () => {
			this.setState({ selectRoomVisible: true })
		}

		disabledHours = () => {
			if (moment().startOf('day').isSame(moment(this.state.planBeginAt).startOf('day'))) {
				let result = [];
				for (let i = 0; i < moment().hour(); i++) {
					result.push(i);
				}
				return result;
			}
		}

		render() {
			return <WrappedComponent
				{...this.props}
				me={this.me}
				// token={this.token}
				date={this.state.date}
				radio={this.state.radio}
				length={this.state.length}
				meetings={this.state.meetings}
				booking={this.state.booking}
				allRooms={this.state.allRooms}
				planBeginAt={this.state.planBeginAt}
				attachments={this.state.attachments}
				selectType={this.state.selectType}
				isLoadUser={this.state.isLoadUser}
				clearRowKeys={this.state.clearRowKeys}
				meetingRadio={this.state.meetingRadio}
				selectedUser={this.state.selectedUser}
				selectRoomVisible={this.state.selectRoomVisible}
				selectedLocation={this.state.selectedLocation}
				// httpServiceEndpoint={this.httpServiceEndpoint}
				selectedRoom={this.state.selectedRoom}
				meetingRadioChange={value => this.meetingRadioChange(value)}
				onSave={e => this.onSave(e)}
				resetDate={this.resetDate}
				removeClass={this.state.removeClass}
				resetRemoveClass={() => this.setState({ removeClass: false })}
				setLength={length => this.setLength(length)}
				radioChange={value => this.radioChange(value)}
				showModal={(flag) => this.showModal(flag)}
				selectUserVisible={this.state.selectUserVisible}
				onSelectUserCancel={() => this.onSelectUserCancel()}
				onSelectUserConfirm={(users) => this.onSelectUserConfirm(users)}
				onSelectUserChange={() => this.onSelectUserChange()}
				mutiOptionData={this.state.mutiOptionData}
				handleChange={this.handleChange}
				dateChange={this.dateChange}
				setSelectRoomVisible={this.setSelectRoomVisible}
				onSelectRoomsCancel={this.onSelectRoomsCancel}
				onSelectRoomsConfirm={this.onSelectRoomsConfirm}
				mutiOptionDataOnChange={value => this.mutiOptionDataOnChange(value)}
				selectedRoomId={this.state.selectedRoomId}
				disabledHours={this.disabledHours}
			/>;
		}
	});
};

export default withRouter(Form.create()(hot(EditBooking)));
