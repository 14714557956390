import React from 'react';
import './style.less';
import Cookies from 'js-cookie';
import { withRouter, useLocation } from 'react-router-dom';
import { Row, Col, PageHeader, message, Popover } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import moment from 'moment';

function VedioMeetingDetail(props) {
	if (!props.videoMeeting) return <></>
	if (!props.me) return <></>
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	// const routes = [
	// 	{
	// 		path: '/video/meeting',
	// 		breadcrumbName: t('videoConference'),
	// 	},
	// 	{
	// 		breadcrumbName: t('viewVideoConference'),
	// 	},
	// ];

	let attendees = _.map(props.videoMeeting.attendees, attendee => attendee.name);
	const organizerContent = (
		<div>
			<p>手机号：{props.videoMeeting.organizer?.mobile}</p>
			<p>邮箱：{props.videoMeeting.organizer?.email}</p>
			<p>部门：{props.videoMeeting.organizer?.department}</p>
		</div>
	)
	const createdByContent = (
		<div>
			<p>手机号：{props.videoMeeting.createdBy?.mobile}</p>
			<p>邮箱：{props.videoMeeting.createdBy?.email}</p>
			<p>部门：{props.videoMeeting.createdBy?.department}</p>
		</div>
	)
	return <div className='container'>
		<div style={{ background: '#FFFFFF', boxShadow: '0px 0px 8px rgba(221, 229, 234, 0.5)', margin: '0 auto' }}>
			<div className='d-flex justify-between' style={{ backgroundColor: '#fafafa', borderBottom: '1px solid #f1f1f1' }}>
				<PageHeader
					ghost={false}
					style={{ padding: '24px 56px 16px', backgroundColor: '#fafafa' }}
					onBack={() => props.history.goBack()}
					title={t('viewVideoConference')}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', background: '#FFFFFF', padding: '24px 56px' }}>
				<Row type="flex" justify="space-between" gutter={24}>
					<Col span={14} xxl={14}>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('meetingTitle')}：</span>
							<span style={{ width: 400 }}>{props.videoMeeting.subject}</span>
						</div>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('meetingTime')}：</span>
							<span style={{ width: 400 }}>{moment(props.videoMeeting.beginAt * 1000).format('ll')}{moment(props.videoMeeting.beginAt * 1000).format(' HH:mm')} → {moment(props.videoMeeting.endAt * 1000).format('HH:mm')}</span>
						</div>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('meetingNumber')}：</span>
							<span style={{ width: 400 }}>{props.videoMeeting.accessCode}</span>
						</div>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('meetingPIN')}：</span>
							<span style={{ width: 400 }}>{props.videoMeeting.password}</span>
						</div>
						{
							props.videoMeeting.user && props.videoMeeting.user.email === props.me.email &&
							<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
								<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('moderatorPIN')}：</span>
								<span style={{ width: 400 }}>{props.videoMeeting.chairmanPassword}</span>
							</div>
						}
						{
							props.videoMeeting.organizer && props.videoMeeting.organizer.name && <>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('meetingOrganizer')}：</span>
									<Popover content={organizerContent} placement="right">
										<span style={{ cursor: 'pointer' }}>{props.videoMeeting.organizer.name}&nbsp;&nbsp;</span>
									</Popover>
								</div>
							</>
						}
						{
							props.videoMeeting.createdBy && props.videoMeeting.createdBy.name && <>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('meetingOwner')}：</span>
									<Popover content={createdByContent} placement="right">
										<span style={{ cursor: 'pointer' }}>{props.videoMeeting.createdBy.name}&nbsp;&nbsp;</span>
									</Popover>
								</div>
							</>
						}
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 160 }}>{t('participatingMembers')}：</span>
							{
								!_.isEmpty(attendees) ? attendees.join(' , ') : t('none')
							}
						</div>
						{/* <div className='meeting-button' style={{ marginTop: 64, display: 'flex', flexDirection: 'row' }}>
							{moment().isBefore(moment(props.videoMeeting.beginAt * 1000))
								&& <Button size="large" type='primary' onClick={() => props.history.push('/schedule/control')}>{t('startMeeting')}</Button>}
							<Popconfirm
								title={t('confirmToDeleteVCMessage')}
								onConfirm={() => props.deleteVideoMeeting(props.videoMeeting.id)}
								okText={t('confirm')}
								cancelText={t('cancel')}
							>
								<Button size="large">{t('delete')}</Button>
							</Popconfirm>
						</div> */}
					</Col>
				</Row>
			</div>
		</div >
	</div>
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponet extends React.Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				videoMeeting: null
			}
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;
			await this.loadVideoMeeting()
		}

		async loadVideoMeeting() {
			let videoMeeting = await this.transportLayer.getVideoMeeting(this.props.match.params.meetingId);
			this.setState({ videoMeeting });
		}

		deleteVideoMeeting = async id => {
			await this.transportLayer.deleteVideoMeeting(id);
			message.success(this.t('vcDeleteMessage'));
			this.props.history.push('/video/meeting')
		}

		render() {
			return <WrappedComponent
				{...this.props}
				me={this.me}
				videoMeeting={this.state.videoMeeting}
				deleteVideoMeeting={this.deleteVideoMeeting}
			/>
		}
	})
}

export default withRouter(hoc(VedioMeetingDetail));