import Debug from 'debug';
import OAuth from './share/OAuth';
import TransportLayer from './share/TransportLayer';

const debug = Debug('app:context');

class AppContext {
	static get instance() {
		if (!this._instance) {
			this._instance = new AppContext();
		}
		return this._instance;
	}

	constructor() {
		if (AppContext._instance) {
			return AppContext._instance;
		}

		this.init();
	}

	noop() {
		debug(this.httpService);
		debug(this.wsService);
		debug(this.mqttService);
		debug(this.clientId);
		debug(this.homePage);
	}

	async init() {
		let url = new URL(window.location.href);
		let originValue = `${url.origin}/api`;
		this.httpService = originValue;

		// https -> wss, http -> ws
		this.wsService = originValue.replace('http', 'ws');

		// mqtt, port: 18886, always ws
		let hostname = originValue.match(/:\/\/([^:?#]+)/)[1];
		this.mqttService = `ws://${hostname}:18886`;

		this.clientId = 's365-booking';
		this.homePage = url.origin;
	}

	parse(origin, homePage) {
		let originValue = origin ? origin : 'https://cloud.space365.live/api';
		this.httpService = originValue;

		// https -> wss, http -> ws
		this.wsService = originValue.replace('http', 'ws');

		// mqtt, port: 18886, always ws
		let hostname = originValue.match(/:\/\/([^:?#]+)/)[1];
		this.mqttService = `ws://${hostname}:18886`;

		this.clientId = 's365-booking';
		this.homePage = homePage ? homePage : 'https://cloud.space365.live/app/device';
	}

	async getToken() {
		this.oauth = new OAuth();
		let token = await this.oauth.getToken();
		this.transportLayer = new TransportLayer(token);
		this.token = token;
		return token;
	}

	async getDeviceTypes() {
		// this.deviceTypes = await this.transportLayer.getDeviceTypes();
	}

	async getAreas(spaceId) {
		this.areas = [];
		await this.findArea(spaceId);
		this.areas = _.reverse(this.areas);
	}

	async findArea(spaceId) {
		let space = await this.transportLayer.getSpacesById(spaceId);
		if (space.parent) {
			this.areas.push(space.parent.name);
			await this.findArea(space.parent.id);
		}
	}
}

export default AppContext;