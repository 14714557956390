import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import { useTranslation, withTranslation } from 'react-i18next';
import { PageHeader, Divider, Col, Row, message, Descriptions } from 'antd';
import moment from 'moment';
import { adminOrDispatcherOrGeneral, meetingTimeStatus } from './Role'

function ViewBooking(props) {
	const { t } = useTranslation();
	if (!props.booking) { return <></>; }
	if (!props.user) { return <></> }
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });

	let attendees = [];
	if (props.booking.attendees.length > 0) {
		_.map(props.booking.attendees, x => {
			attendees.push(x.name)
		})
	}
	let rule = _.find(props.user.userRules, r => r.type === 'dispatcher');
	// const routes = [
	// 	{
	// 		path: '/booking/local',
	// 		breadcrumbName: t('roomSchedule'),
	// 	},
	// 	{
	// 		breadcrumbName: t('bookRoom'),
	// 	},
	// ]
	let _meetingType = props.booking.type;
	if (props.booking.type === 1) {
		_meetingType = '内部会议1（副总裁以上领导与会选择）';
	} else if (props.booking.type === 2) {
		_meetingType = '内部会议2（普通会议）';
	} else if (props.booking.type === 3) {
		_meetingType = '来访接待';
	}
	let _hasVM = '否';
	if (props.booking.hasVM) {
		_hasVM = '是';
	}

	return <div className='container' >
		<div style={{ background: '#FFFFFF', boxShadow: '0px 0px 8px rgba(221, 229, 234, 0.5)', margin: '0 auto' }}>
			<div className='d-flex justify-between' style={{ backgroundColor: '#fafafa', borderBottom: '1px solid #f1f1f1' }}>
				<PageHeader
					style={{ padding: '24px 56px 16px', backgroundColor: '#fafafa' }}
					ghost={false}
					onBack={() => props.pathFrom ? props.history.push(props.pathFrom) : props.history.goBack()}
					title={t('viewMeeting')}
				// breadcrumb={{ routes }}
				>
					{
						props.booking.createdBy &&
						<span style={{ color: 'rgba(68, 81, 95, 0.65)' }}>
							{t('meetingOwner')}：{props.booking.createdBy.name}
							<Divider type='vertical' />
							{t('contactNumber')}：{props.booking.createdBy.mobile}
							<Divider type='vertical' />
							{t('email')}：{props.booking.createdBy.email}</span>
					}
				</PageHeader>
				{
					<div className="d-flex align-center meeting-button" style={{ paddingTop: 15, paddingRight: 10 }}>
						{adminOrDispatcherOrGeneral(props.booking)}
					</div>
				}
			</div>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto', background: '#FFFFFF', padding: '24px 56px' }}>
				<Row type="flex" justify="space-between" gutter={24}>
					<Col span={18} xxl={18}>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('meetingTitle')}：</span>
							<span style={{ width: 400 }}>{props.booking.subject}</span>
						</div>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('meetingRoom')}：</span>
							<span style={{ width: 400 }}>{props.booking.room.name}</span>
						</div>
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('meetingTime')}：</span>
							<span style={{ width: 400 }}>{moment(props.booking.beginAt * 1000).format('ll')}
								{moment(props.booking.beginAt * 1000).format(' HH:mm')} → {moment(props.booking.endAt * 1000).format('HH:mm')}
								<span style={{ marginLeft: 10 }}>{meetingTimeStatus(props.booking)}</span>
							</span>
						</div>
						{
							props.booking.organizer && <>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('meetingOrganizer')}：</span>
									<span style={{ width: 400 }}>{props.booking.organizer.name}&nbsp;&nbsp;{props.booking.organizer.mobile}&nbsp;&nbsp;{props.booking.organizer.email}</span>
								</div>
							</>
						}
						{
							props.booking.createdBy && <>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('meetingOwner')}：</span>
									<span style={{ width: 400 }}>{props.booking.createdBy.name}&nbsp;&nbsp;{props.booking.createdBy.mobile}&nbsp;&nbsp;{props.booking.createdBy.email}</span>
								</div>
							</>
						}
						{
							props.booking.organizer && <>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('auditor')}：</span>
									<span style={{ width: 400 }}>{!_.isEmpty(props.booking.auditors) ? _.map(props.booking.auditors, 'name').join(',').valueOf() : t('none')}</span>
								</div>
							</>
						}
						<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
							<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>{t('participatingMembers')}：</span>
							<span style={{ width: 400 }}>{attendees.length > 0 ? attendees.join(' , ') : t('none')}</span>
						</div>
						{
							props.booking.state === 'reject' &&
							<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
								<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>驳回理由：</span>
								<span style={{ width: 400 }}>{props.booking.auditResult.auditedNote}</span>
							</div>
						}
						{
							props.booking.vm && props.booking.vcs &&
							<div>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>视频会议号：</span>
									<span style={{ width: 400 }}>{props.booking.vcs.accessCode}</span>
								</div>
								<div style={{ marginBottom: 32, display: 'flex', flexDirection: 'row' }}>
									<span style={{ color: 'rgba(68, 81, 95, 0.65)', width: 120 }}>视频会议密码：</span>
									<span style={{ width: 400 }}>{props.booking.vcs.password}</span>
								</div>
							</div>
						}
					</Col>
				</Row>
			</div>
		</div>
	</div >
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;

		constructor(props) {
			super(props);
			this.state = {
				booking: null,
				pathFrom: null
			}
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;

			await this.loadBooking();
		}

		async loadBooking() {
			let pathFrom = this.props.location.state;
			let booking = await this.transportLayer.getBookingById(this.props.match.params.bookingId);
			this.setState({ booking, pathFrom })
		}

		editMeeting = () => {
			this.props.history.push(`/local/booking/edit/${this.state.booking.space.id}/${this.state.booking.id}/${this.state.booking.beginAt * 1000}/${this.state.booking.endAt * 1000}/0`)
		}

		cancelMeeting = async () => {
			await this.transportLayer.cancelMeeting(this.state.booking);
			message.success(this.t('MeetingCancelSuccessMessage'));
			this.props.history.push('/');
		}

		earlyEndMeeting = async () => {
			await this.transportLayer.earlyEndMeeting(this.props.match.params.bookingId);
			message.success(this.t('meetingEndedMessage'));
			this.props.history.push('/');
		}

		deleteMeeting = async () => {
			await this.transportLayer.deleteMeeting(this.props.match.params.bookingId);
			message.success(this.t('meetingDeletedMessage'));
			this.props.history.push('/');
		}

		render() {
			return <WrappedComponent
				history={this.props.history}
				user={this.me}
				booking={this.state.booking}
				editMeeting={this.editMeeting}
				cancelMeeting={this.cancelMeeting}
				earlyEndMeeting={this.earlyEndMeeting}
				deleteMeeting={this.deleteMeeting}
				pathFrom={this.state.pathFrom}
			/>
		}
	})
}

export default withRouter(hoc(ViewBooking));