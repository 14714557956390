import './rooms.less';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import SpaceStatus from '../components/SpaceStatus';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import React, { Component } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import {
	InfoCircleFilled, UserOutlined, ClockCircleOutlined, EnvironmentOutlined, UnorderedListOutlined,
	VideoCameraOutlined, DownOutlined, TableOutlined, TeamOutlined, DisconnectOutlined, AuditOutlined,
	TagsOutlined, LinkOutlined
} from '@ant-design/icons';
import { Popover, DatePicker, TreeSelect, Button, Divider, PageHeader, Tooltip } from 'antd';
const { TreeNode } = TreeSelect;


function Bookings(props) {
	const { t } = useTranslation();
	if (!props.rooms) { return <></> }
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	let start = 8, end = 20;

	let bookingButtonBeginDate = null, bookingButtonEndDate = null;

	if (!props.selectedDate.isSame(moment().format('YYYY-MM-DD'), 'day')) {
		bookingButtonBeginDate = props.selectedDate.clone().unix() * 1000;  // 不是同一天 就用当前时间  space status下的button
		bookingButtonEndDate = moment(bookingButtonBeginDate).clone().add(1, 'hour').unix() * 1000;
	} else {
		bookingButtonBeginDate = props.selectedDate.isBefore(moment()) ? moment().add(1, 'hour').unix() * 1000 : props.selectedDate.clone().unix() * 1000;
		bookingButtonEndDate = moment(bookingButtonBeginDate).clone().add(1, 'hour').unix() * 1000;
	}

	return (
		<div className='container' style={{ background: '#fff' }} >
			<div className='d-flex flex-column'>
				<PageHeader
					style={{ borderBottom: '1px solid #f1f1f1' }}
					title={t('bookSpace')}
					// breadcrumb={{ routes }}
					extra={[<Button type="primary" style={{ width: 120 }} onClick={props.create}> {t('createBooking')}</Button>]}
				>
				</PageHeader>
				<div className='filterWrapper mb-2'>

					<div className='filterRow'>
						<p className='filterRowTitle'>{t('belongCampus')}：</p>
						{
							props.space && _.map(props.space, area =>
								<Button
									className='filterBtns'
									size='small'
									type={area.active ? 'primary' : 'default'}
									key={area.code} value={area.code}
									onClick={e => props.selectArea(e.target.value, props.space)}
								>
									{area.name}
								</Button>)
						}
					</div>
					<div className='filterRow'>
						<p className='filterRowTitle'>{t('belongBuilding')}：</p>
						{
							_.map(props.buildings, building =>
								<Button
									className='filterBtns'
									size='small'
									type={building.active ? 'primary' : 'default'}
									key={building.code} value={building.code}
									onClick={e => props.selectBuildings(e.target.value, building)}
								>
									{building.name}
								</Button>)
						}
					</div>
					<div className='filterRow'>
						<p className='filterRowTitle'>{t('belongFloor')}：</p>
						{!props.whole.disable && <Button
							className='filterBtns'
							size='small'
							type={props.whole.active ? 'primary' : 'default'}
							key={props.whole.code} value={props.whole.code}
							onClick={e => props.selectFloor(e.target.value, props.whole)}
						>
							{t('all')}
						</Button>}
						{
							_.map(props.floors, floor =>
								<Button
									className='filterBtns'
									size='small'
									type={floor.active ? 'primary' : 'default'}
									key={floor.code} value={floor.code}
									onClick={e => props.selectFloor(e.target.value, floor)}
								>
									{floor.name}
								</Button>)
						}
					</div>
					<div className='filterRow' style={{ borderBottom: 'none' }}>
						<p className='filterRowTitle'>{t('capacity')}:</p>
						{
							_.map(props.capacities, capacity =>
								<Button
									className='filterBtns'
									size='small'
									type={capacity.active ? 'primary' : 'default'}
									key={capacity.key} value={capacity.value}
									onClick={e => props.setCapacity(e.target.value, capacity)}
								>
									{capacity.name}
								</Button>)
						}
					</div>
					<div className='filterRow' style={{ borderBottom: 'none' }}>
						<p className='filterRowTitle'>{t('amenities')}：</p>
						{
							_.map(props.deviceConfigs, config =>
								<Button
									className='filterBtns'
									size='small'
									type={config.active ? 'primary' : 'default'}
									key={config.value} value={config.value}
									onClick={e => props.selectConfig(e.target.value, config)}
								>
									{config.name}
								</Button>)
						}
					</div>
				</div>
				<div style={{
					background: '#fff', boxShadow: ' 0px 0px 0px rgba(221, 229, 234, 0.5)'
				}}>
					<div className='d-flex justify-between' style={{ padding: '4px 24px' }}>
						<div>
							<div className="use-date">{t('deployedDate')}</div>
							<DatePicker
								style={{ width: 150 }}
								value={props.selectedDate}
								format={'LL'}
								className="date-picker"
								allowClear={false}
								suffixIcon={<DownOutlined style={{ cursor: 'pointer', right: 8 }} />}
								onChange={date => props.datePickerChange(date)}
							// disabledDate={currentDate => currentDate.isBefore(moment().startOf('day'))}
							/>
						</div>
						<div className='d-flex'>
							<div className='d-flex align-center mr-3'>
								<Button
									type={props.selectedView === 'table' ? 'primary' : 'default'}
									onClick={() => props.selectView('table')}>
									<TableOutlined /></Button>
								<Button
									type={props.selectedView === 'list' ? 'primary' : 'default'}
									onClick={() => props.selectView('list')}>
									<UnorderedListOutlined /></Button>
							</div>
							<div className='d-flex align-center'>
								<Button style={{ width: 100 }} type={props.timelineMode === 'worktime' && 'primary'} onClick={() => props.setTimelineMode('worktime')}>{t('workHour')}</Button>
								<Button style={{ width: 100 }} type={props.timelineMode === 'allday' && 'primary'} onClick={() => props.setTimelineMode('allday')}>{t('allDay')}</Button>
							</div>
						</div>
					</div>
					<div>
						{
							props.selectedView === 'table' && <div style={{ padding: '0px 24px 24px' }}>
								<div className='d-flex align-center mb-2'>
									<InfoCircleFilled style={{ color: '#F2994A', marginRight: 8 }} />
									<span style={{ color: 'rgba(50, 56, 62, 0.65)' }}>{t('bookingHint')}</span>
								</div>
								<div className='contentBody'>
									<div className='timelineWrapper'>
										<div className='timelineHeaderWrapper'>
											<div className='timelineHeaderSider' style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
												{t('deviceSpaceName')}
											</div>
											<div className='headerOuter'>
												{
													props.timelineMode === 'worktime' ? _.times(end - start, (index) => {
														let width = end - start;
														return (
															<div key={`t-${index}`} className='timeLabel12' style={{ width: `calc(100%/${width})` }}>
																<span style={{ fontSize: 14, color: 'rgba(0,0,0,0.6)' }}>{index + start}</span>
															</div>
														);
													}) : _.times(24, (index) => {
														return (
															<div key={`t-${index}`} className='timeLabel24'>
																<span style={{ fontSize: 14, color: 'rgba(0,0,0,0.6)' }}>{index}</span>
															</div>
														);
													})
												}
											</div>
										</div>
										<div style={{ display: 'flex', height: '100%', position: 'relative' }}>
											<div className='timelineSidebar'>
												{props.rooms && _.map(props.rooms, x => {
													return (
														<div key={x.id} className='sidebarRow'>{x.name}</div>
													);
												})}
											</div>
											<div style={{ flex: 1 }} id="time-table">
												{props.rooms && _.map(props.rooms, x =>
													<div key={x.id} className='timelineOuter'>
														{props.renderTimebar()}
														{
															props.timelineMode === 'worktime' ?
																_.times((end - start), index => {

																	let width = end - start;
																	let offset = (props.timelineValue.clone().hour() - 8) * 60 + props.timelineValue.clone().minute();
																	// let disableValue = 100 * offset / (12 * 60) * 12 / 100;   //和时间线一样的偏移值
																	let disableValue = offset / (12 * 60) * 12;
																	let percent = `${disableValue.toFixed(2).substr(disableValue.toString().indexOf('.') + 1, 2) / 100}`;
																	if (percent <= 0.5) {
																		disableValue += 1;
																	}
																	if (!props.timelineValue.isSame(moment(), 'day') && props.timelineValue.isBefore(moment()) && index === 0) {
																		return <div
																			key={index}
																			id={`${x.id}-${index}`}
																			className='timeGrid'
																			style={{ width: `calc(100%/${width})`, cursor: 'not-allowed' }}
																		>
																			<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																		</div>
																	}

																	if (index + 1 == disableValue.toFixed()) {
																		return (
																			<div
																				className='timeGrid'
																				key={index}
																				style={{ width: `calc(100%/${width})`, display: 'flex' }}
																				id={`${x.id}-${index}`}
																			>
																				<div
																					id={`${x.id}-${index}-not`}
																					style={{ width: `calc(100%/${width}* ${percent * 100})`, cursor: 'not-allowed' }}
																				/>
																				<div
																					id={`${x.id}-${index}-allowed`}
																					style={{ width: `calc(100%/${width}* ${(1 - percent) * 100})` }}
																					onMouseDown={() => props.mouseDown(index, x, percent)}
																					onMouseUp={() => props.mouseUp(index, x, percent)}
																					onMouseMove={() => props.mouseMove(index, percent)}
																				/>
																			</div>
																		)
																	} else {
																		if (index < disableValue.toFixed() - 1) {
																			return <div
																				key={index}
																				id={`${x.id}-${index}`}
																				className='timeGrid'
																				style={{ width: `calc(100%/${width})`, cursor: 'not-allowed' }}
																			>
																				<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																			</div>

																		} else {
																			if (props.timelineValue.isSame(moment(), 'day')) {
																				return <div
																					key={index}
																					id={`${x.id}-${index}`}
																					className='timeGrid'
																					style={{ width: `calc(100%/${width})` }}
																					onMouseDown={() => props.mouseDown(index, x)}
																					onMouseUp={() => props.mouseUp(index, x)}
																					onMouseMove={() => props.mouseMove(index)}
																				>
																					<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																				</div>

																			} else {
																				if (props.timelineValue.isAfter(moment())) {
																					return <div
																						key={index}
																						id={`${x.id}-${index}`}
																						className='timeGrid'
																						style={{ width: `calc(100%/${width})` }}
																						onMouseDown={() => props.mouseDown(index, x)}
																						onMouseUp={() => props.mouseUp(index, x)}
																						onMouseMove={() => props.mouseMove(index)}
																					>
																						<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																					</div>
																				} else {
																					return <div
																						key={index}
																						id={`${x.id}-${index}`}
																						className='timeGrid'
																						style={{ width: `calc(100%/${width})`, cursor: 'not-allowed' }}
																					>
																						<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																					</div>
																				}
																			}
																		}

																	}
																}) : (
																	_.times(24, index => {
																		let offset = props.timelineValue.hour() * 60 + props.timelineValue.minute();
																		// let disableValue = 100 * offset / (12 * 60) * 12 / 100;   //和时间线一样的偏移值
																		let disableValue = offset / (24 * 60) * 24;
																		let percent = `${disableValue.toFixed(2).substr(disableValue.toString().indexOf('.') + 1, 2) / 100}`;
																		if (percent <= 0.5) {
																			disableValue += 1;
																		}
																		if (!props.timelineValue.isSame(moment(), 'day') && props.timelineValue.isBefore(moment()) && index === 0) {
																			return <div
																				key={index}
																				id={`${x.id}-${index}`}
																				className='timeGrid'
																				style={{ width: `calc(100%/${24})`, cursor: 'not-allowed' }}
																			>
																				<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																			</div>
																		}

																		if (index + 1 == disableValue.toFixed()) {
																			return <div
																				className='timeGrid'
																				key={index}
																				style={{ width: `calc(100%/${24})`, display: 'flex' }}
																				id={`${x.id}-${index}`}
																			>
																				<div
																					id={`${x.id}-${index}-not`}
																					style={{ width: `calc(100%/${24}* ${percent * 100})`, cursor: 'not-allowed' }}
																				/>
																				<div
																					id={`${x.id}-${index}-allowed`}
																					style={{ width: `calc(100%/${24}* ${(1 - percent) * 100})` }}
																					onMouseDown={() => props.mouseDown(index, x, percent)}
																					onMouseUp={() => props.mouseUp(index, x, percent)}
																					onMouseMove={() => props.mouseMove(index, percent)}
																				/>
																			</div>
																		} else {
																			if (index < disableValue.toFixed() - 1) {
																				return <div
																					key={index}
																					id={`${x.id}-${index}`}
																					className='timeGrid'
																					style={{ width: `calc(100%/${24})`, cursor: 'not-allowed' }}
																				>
																					<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																				</div>
																			} else {
																				if (props.timelineValue.isSame(moment(), 'day')) {
																					return <div
																						key={index}
																						id={`${x.id}-${index}`}
																						className='timeGrid'
																						style={{ width: `calc(100%/${24})` }}
																						onMouseDown={() => props.mouseDown(index, x)}
																						onMouseUp={() => props.mouseUp(index, x)}
																						onMouseMove={() => props.mouseMove(index)}
																					>
																						<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																					</div>

																				} else {
																					if (props.timelineValue.isAfter(moment())) {
																						return <div
																							key={index}
																							id={`${x.id}-${index}`}
																							className='timeGrid'
																							style={{ width: `calc(100%/${24})` }}
																							onMouseDown={() => props.mouseDown(index, x)}
																							onMouseUp={() => props.mouseUp(index, x)}
																							onMouseMove={() => props.mouseMove(index)}
																						>
																							<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																						</div>
																					} else {
																						return <div
																							key={index}
																							id={`${x.id}-${index}`}
																							className='timeGrid'
																							style={{ width: `calc(100%/${24})`, cursor: 'not-allowed' }}
																						>
																							<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																						</div>
																					}
																				}
																			}
																		}
																	})
																)
														}
														{props.meetings &&
															_.map(props.getMeetings(props.meetings, x), meeting => props.renderMeetingBlock(meeting))
														}
													</div>
												)
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						{
							props.selectedView === 'list' &&
							<div>
								{
									props.rooms && _.map(props.rooms, room =>
										<div key={room.id}>
											<div style={{ display: 'flex', padding: 24, alignItems: 'center' }}>
												{room.thumbnail == null ? <img className='roomThumbnail' src={room.avatar ? `${props.httpService}/v2${room.avatar}` : require('../images/placeholder.png')} /> : <img className='roomThumbnail' src={require(`../images/${room.thumbnail}.png`)} />}
												<div style={{ flex: 1 }}>
													<div className='d-flex justify-between' style={{ marginBottom: 8 }}>
														<div style={{ lineHeight: '28px', fontSize: 20, fontWeight: 'bold', marginBottom: 8 }}>
															{room.name}
														</div>

														<Button type='primary' style={{ width: 120, height: 32 }}>
															<Link to={`/local/booking/create/${room.id}/${bookingButtonBeginDate}/${bookingButtonEndDate}/${props.timelineMode}`}>{t('book')}</Link>
														</Button>
													</div>
													<div style={{ display: 'flex', alignItems: 'center', lineHeight: '22px', marginBottom: 8 }}>
														<div style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}>
															<span style={{ color: 'rgba(50, 56, 62, 0.65)' }}><TeamOutlined style={{ marginRight: 6 }} /> {t('roomCapacity', { count: room.capacity || 0 })}</span>
														</div>
														<div style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}>
															{room.forbidden ? <span style={{ color: '#D4380D' }}><DisconnectOutlined style={{ marginRight: 6, color: '#D4380D' }} />{t('offline')}</span> : <span style={{ color: 'rgba(50, 56, 62, 0.65)' }}><LinkOutlined style={{ marginRight: 6 }} />{t('online')}</span>}
														</div>
														<div style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}>
															<span style={{ color: 'rgba(50, 56, 62, 0.65)' }}><AuditOutlined style={{ marginRight: 6 }} /> {_.isEmpty(room.auditors) ? t('auditNotNeeded') : t('auditNeeded')}</span>
														</div>
														<div style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}>
															<span style={{ color: 'rgba(50, 56, 62, 0.65)' }}>	<TagsOutlined style={{ marginRight: 6 }} /> {room.deviceTag ? room.deviceTag.split(',').join(' ') : t('noDevice')}</span>
														</div>
													</div>
													<div>
														<SpaceStatus
															meetings={props.getMeetings(props.meetings, room)}
															room={room}
															mainPage={true}
															timelineMode={props.timelineMode}
															timelineValue={props.timelineValue}
														/>
													</div>
												</div>
											</div>
											<Divider style={{ margin: 0, background: '#f1f1f1' }} />
										</div>
									)
								}
							</div>
						}
					</div>
				</div>
			</div>
		</div >
	);
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				meetings: [],
				selectedDate: moment().startOf('day'),
				timelineValue: moment(),
				timelineMode: 'worktime',
				begin: moment().startOf('day'),
				end: moment().endOf('day'),
				pageSize: 100,
				selectPage: 1,
				allRooms: [],
				button: {
					table: { type: 'primary', visible: true },
					list: { type: '', visible: false }
				},
				previewDialog: false,
				space: null,
				selectedLocation: null,
				bookingsAreas: null,// 全部园区
				area: null, // 选中的园区
				building: null, // 选中的大厦
				floor: null,// 选中的楼层
				floors: [],
				buildings: [],
				capacities: [{ name: this.t('all'), key: 0, value: '', active: true },
				{ name: this.t('capacityUnder5'), key: 1, value: '0,5', active: false },
				{ name: this.t('capacity5To10'), key: 2, value: '5,11', active: false },
				{ name: this.t('capacityOver10'), key: 3, value: '11,1000', active: false }
				],
				deviceConfigs: [{ name: this.t('projector'), value: '投影', active: false },
				{ name: this.t('whiteboard'), value: '白板' },
				{ name: this.t('pc'), value: '电脑', active: false },
				{ name: this.t('speaker'), value: '扩声', active: false },
				{ name: this.t('videoConference'), value: '视频会议', active: false },
				],
				selectedView: 'table',
				whole: {},
				configs: [],
				selectedCapacity: '',
				outerWidth: 0
			};
			this.rooms = [];
			this.target = { click: false, downValue: '' };
			this.countIndex = { start: 0, end: 0 };
		}

		async componentDidMount() {

			this.currentUser = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.httpService = AppContextJS.instance.httpService;
			this.currentCompany = this.context.userContext.me.company;
			this.setState({ outerWidth: document.getElementById('time-table').clientWidth });
			window.addEventListener('resize', this.update);
			await this.load();
		}

		async componentWillUnmount() {
			window.removeEventListener('resize', this.update);
		}

		update = () => {
			let outerWidth = document.getElementById('time-table').clientWidth;
			this.setState({ outerWidth })
		}

		async load() {
			await this.loadBuildings()
			await this.loadRooms({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: this.state.building.id,
				floorName: this.state.whole.active ? '' : this.state.floor.name,
				forbidden: this.state.selectedDate.clone().startOf('day')
			});
			await this.loadBookings({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: this.state.building.id,
				begin: this.state.begin.unix(),
				end: this.state.end.unix()
			})
		}

		async loadBuildings() {
			let data = await this.transportLayer.getSpaces({ page: this.state.selectPage, size: this.state.pageSize });
			this.formatBuildings(data.docs);
		}

		async loadRooms({ page, size, key, buildingId, floorName, deviceTag, capacity, forbidden }) {
			let data = await this.transportLayer.getRoomsByParams({ page, size, key, buildingId, floorName, deviceTag, capacity });
			let docs = _.chain(data.docs)
				.filter(room => !room.disable)
				.filter(room => {
					if (room.forbidden) {
						if (forbidden) {
							if (moment(forbidden).unix() >= room.forbidden.begin && moment(forbidden).unix() <= room.forbidden.end) {
								return;
							}
							return room;
						}
						return room;
					} else {
						return room
					}
				})
				.value();

			this.setState({ allRooms: docs })
		}

		async loadBookings({ page, size, buildingId, begin, end, floor, capacity, deviceTag }) {
			let bookings = await this.transportLayer.getBookingsByBuildingId({ page, size, buildingId, begin, end, floor, capacity, deviceTag });
			let meetings = _.filter(bookings.docs, booking => booking.state !== 'cancel' && booking.state !== 'reject')
			this.setState({ meetings });
		}

		getMeetings = (meetings, room) => {
			return _.filter(meetings, x => x.room.id === room.id);
		};

		formatBuildings = spaces => {
			let space = [];
			let rooms = this.state.allRooms;
			let districts = _.groupBy(spaces, 'district');

			for (let district in districts) {
				_.each(districts[district], d => {
					d.code = d.id;
					d.children = [];
					_.each(d.floors, floor => {
						let fchildren = _.filter(rooms, room => {
							if (room.building === d.id && room.floor === floor) {
								room.building = { name: d.name, country: d.country, district: d.district, id: d.id, floors: d.floors }
								return true;
							}

							if (room.building.id === d.id && room.floor === floor) return true;
							return false;
						})

						_.each(fchildren, room => { room.selectable = true; })
						d.children.push({ name: floor, code: `floor-${floor}-${d.id}`, hasRoom: true, children: fchildren, parent: d, selectable: false })

					})
				})
				space.push({ name: district, code: district, children: districts[district], })
			}

			this.setState({ space }, () => this.initArea())
		}

		// 初始化园区
		async initArea() {
			let space = this.state.space;
			space = space && _.map(space, (x, i) => {
				if (i === 0) {
					x['active'] = true
				} else {
					x['active'] = false
				}
				return x
			})
			this.setState({ space, area: _.first(space) }, () => {
				this.initBuildings();
			});
		}

		// 初始化大厦
		async initBuildings() {
			if (!this.state.area) return
			let buildings = _.map(this.state.area.children, (item, index) => {
				if (index === 0) {
					item.active = true
				} else {
					item.active = false;
				}
				return item;
			})
			this.setState({ buildings, building: _.first(buildings) }, () => {
				this.initFloors();
			});
		}

		// 初始化楼层
		async initFloors() {
			if (!this.state.building) return
			let floors = _.cloneDeep(this.state.building.children);
			let whole = { code: this.state.building.code, data: this.state.building.data, name: this.t('all'), children: this.state.building.children, active: true }; // 默认选中的楼层的房间
			this.setState({ floors, floor: _.first(floors), selectedLocation: whole.code, whole });
		}

		renderTimebar = () => {
			let offset, value, result;
			if (this.state.timelineMode === 'allday') {
				if (this.state.timelineValue.clone().isSame(moment(), 'day')) {
					offset = moment().clone().hour() * 60 + moment().clone().minute();
				} else {
					offset = this.state.timelineValue.clone().hour() * 60 + this.state.timelineValue.clone().minute();
				}
				value = 100 * offset / (24 * 60);
			} else {
				if (this.state.timelineValue.clone().isSame(moment(), 'day')) {
					offset = (moment().clone().hour() - 8) * 60 + moment().clone().minute();
				} else {
					offset = (this.state.timelineValue.clone().hour() - 8) * 60 + this.state.timelineValue.clone().minute();
				}
				value = 100 * offset / (12 * 60);
			}
			result = !this.state.timelineValue.clone().isSame(moment().format('YYYY-MM-DD'), 'day') ? <div></div> : <div className='currentTimeBar' style={{ left: `${value}%` }}></div>
			return result;
			// return <div className='currentTimeBar' style={{ left: `${value}%` }}></div>;
		}
		renderMeetingTime = meeting => {
			const allDay = this.t('allDay');
			let timeStyle = {};
			if (meeting.allday) {
				return <span style={timeStyle}>{allDay}</span>;
			} else {
				return <span style={timeStyle}>{`${moment(meeting.beginAt * 1000).format('LT')} → ${moment(meeting.endAt * 1000).format('LT')}`}</span>;
			}
		}

		renderMeetingBlock = meeting => {
			let duration, offset, left, width, freeDuration, freeOffset, freeLeft, freeWidth;

			duration = moment(meeting.endAt * 1000).diff(meeting.beginAt * 1000, 'minutes') / 60.0;
			offset = (moment(meeting.beginAt * 1000).diff(moment(meeting.beginAt * 1000).clone().startOf('day'), 'minutes') / 60.0);
			freeDuration = moment(meeting.endAt * 1000).add(30, 'minute').diff(meeting.endAt * 1000, 'minutes') / 60.0;
			freeOffset = (moment(meeting.beginAt * 1000).add(30, 'minute').diff(moment(meeting.beginAt * 1000).clone().startOf('day'), 'minutes') / 60.0);

			// 防止设置打扫时间 会出现色块超出表格
			let tidyTime = ((meeting.tidyEndAt - meeting.endAt) / 60).toFixed();
			if (this.state.timelineMode === 'allday') {
				if (moment(meeting.endAt * 1000).add(parseInt(tidyTime) || 0, 'minute') >= this.state.selectedDate.hour(23).minute(59).second(59)) {
					tidyTime = 0
				}
			} else {
				if (moment(meeting.endAt * 1000).add(parseInt(tidyTime) || 0, 'minute') >= this.state.selectedDate.hour(20).minute(0).second(0)) {
					tidyTime = 0
				}
			}

			if (this.state.timelineMode === 'allday') {
				left = `${offset * (100 / 24.0)}%`;
				width = `${duration * (100 / 24.0)}%`;
				freeLeft = `${freeOffset * (100 / 24.0)}%`;
				freeWidth = this.state.outerWidth / 24.0 * ((tidyTime || 0) / 60);  // 根据设置的预留时间 去显示对应的色块长度
			} else { // workTime

				let start = 8, end = 20;
				if (offset + duration <= start || offset >= end) {
					return <div key={meeting.id}></div>;
				}
				if (offset < start && offset + duration > start) {
					duration = offset + duration - start;
					offset = start;
				}

				if (offset + duration > end) {
					duration = end - offset;
				}
				freeLeft = `${freeOffset * (100 / 12.0)}%`;
				freeWidth = this.state.outerWidth / 12.0 * ((tidyTime || 0) / 60);  // 根据设置的预留时间 去显示对应的色块长度
				left = (offset - start) * (100 / (end - start));
				width = duration * (100 / (end - start));
				left = left + '%';
				width = width + '%';
			}
			const anonymous = this.t('anonymous');
			let _organizerName = anonymous;
			if (meeting.organizer) {
				_organizerName = meeting.organizer.name;
				if (meeting.organizer.email) {
					_organizerName += `(${meeting.organizer.email})`;
				}
			}

			return (
				<Popover
					key={meeting.id}
					title={<span style={{ fontSize: 16 }}>{meeting.subject}</span>}
					content={
						<div>
							<p><EnvironmentOutlined style={{ marginRight: 4 }} /> {meeting.room.name}</p>
							{meeting.hasVM && <p><VideoCameraOutlined style={{ marginRight: 4 }} /> {this.t('videoConference')}</p>}
							<p><ClockCircleOutlined style={{ marginRight: 4 }} /> {this.renderMeetingTime(meeting)}</p>
							<p style={{ margin: 0 }}><UserOutlined style={{ marginRight: 4 }} /> {_organizerName}</p>
							<p style={{ marginTop: 16, marginBottom: 0 }}><Link to={`/local/booking/${meeting.id}/view`}>{this.t('meetingDetail')}</Link></p>
						</div>
					}>

					<div key={meeting.id} className='meetingBlock' style={{
						left: left,
						width: `${Number(width.replace("%", "") / 100 * this.state.outerWidth) + freeWidth}px`,
						display: 'flex',
						position: 'absolute'
					}}>
						<span className="meetingInfo" style={{ color: '#fff', width: `${Number(width.replace("%", "") / 100 * this.state.outerWidth)}px` }}>{meeting.subject}</span>
						<Tooltip placement='right' title={this.t('roomReservedForCleanup')} >
							<div style={{
								height: 32,
								marginTop: 3,
								left: freeLeft,
								width: freeWidth,
								background: '#f5cf9c'
							}}>
							</div>
						</Tooltip>
					</div>
				</Popover>
			);
		}

		datePickerChange = async date => {
			let time = moment().clone();
			let timelineMode = this.state.timelineMode;
			await this.loadRooms({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: this.state.building.id,
				floorName: this.state.whole.active ? '' : this.state.floor.name,
				forbidden: date.clone().startOf('day')
			});
			await this.loadBookings({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: this.state.building.id,
				deviceTag: this.state.configs.join(','),
				capacity: this.state.selectedCapacity,
				begin: date.clone().startOf('day').unix(),
				end: date.clone().endOf('day').unix()
			})
			this.setState({
				begin: date.clone().startOf('day'),
				end: date.clone().endOf('day')
			}, async () => {
				if (time.isSame(date, 'day')) { // 判断是不是当天
					this.setState({
						selectedDate: time,
						timelineValue: time
					}, () => {
						this.setTimelineMode(timelineMode) // 切换另外一天 在切换回当天 解决红线后面的会出现禁用 
					});
				} else {
					this.setState({
						selectedDate: this.state.timelineMode === 'allday' ? date.clone().startOf('day') : date.clone().startOf('day').add(8, 'hour'),
						timelineValue: this.state.timelineMode === 'allday' ? date.clone().startOf('day') : date.clone().startOf('day').add(8, 'hour'),
					});
				}
			})
		}

		renderTreeNodes = (node) => {
			let area = node && node.area && node.area === 'root' ? node.area : node.code;
			return (
				<TreeNode title={node.name} key={node.code} value={area}>
					{node.children && node.children.length > 0 &&
						_.map(node.children, x => { return this.renderTreeNodes(x); })
					}
				</TreeNode>
			);
		}

		mouseDown = (index, x, percent) => {
			this.target.click = true;
			this.target.downValue = x.id;
			this.countIndex.start = index;
			if (percent) {
				document.getElementById(`${this.target.downValue}-${index}-not`).classList.add('mouseMoveBlocks-disable')
			}
		}

		mouseMove = (index) => {
			if (this.target.click && this.target.downValue) {
				document.getElementById(`${this.target.downValue}-${index}`).classList.add('mouseMoveBlocks');
			}
		}

		mouseUp = (index, x, percent) => {
			this.countIndex.end = index + 1;
			if (percent) {
				document.getElementById(`${this.target.downValue}-${index}-not`).classList.add('mouseMoveBlocks-disable')
				this.setState({ percent }, () => {
					this.onCreateMeeting(x, this.countIndex.start, index + 1);
				})
			} else {
				this.onCreateMeeting(x, this.countIndex.start, index + 1);
			}
		}

		onCreateMeeting = (room, startIndex, endIndex, isButtonClick) => {
			let copyStart = startIndex;
			let copyEnd = endIndex;
			if (copyStart >= copyEnd) {
				startIndex = copyEnd - 1;
				endIndex = copyStart + 1;
			}
			let start = this.state.timelineMode === 'worktime' ? 8 : 0;
			let startTime = this.state.selectedDate.startOf('day').clone();
			let endTime = this.state.selectedDate.startOf('day').clone();

			if (isButtonClick) {
				startTime.startOf('day').add(startIndex * 60, 'minute');
				endTime.startOf('day').add(endIndex * 60, 'minute');
			} else {
				if (this.state.selectedDate.isSame(moment(), 'day')) {
					if (this.state.timelineMode === 'worktime') {
						startTime.add(parseInt(start), 'hour').add(startIndex * 60, 'minute');
						endTime.add(parseInt(start), 'hour').add(endIndex * 60, 'minute');
					} else {
						startTime.add(startIndex * 60, 'minute');
						endTime.add(endIndex * 60, 'minute');
					}
				} else {
					if (this.state.timelineMode === 'worktime') {
						startTime.add(parseInt(start), 'hour').add(startIndex * 60, 'minute');
						endTime.add(parseInt(start), 'hour').add(endIndex * 60, 'minute');
					} else {
						startTime.add(startIndex * 60, 'minute');
						endTime.add(endIndex * 60, 'minute');
					}
				}
			}

			endTime.clone().hour() === 0 ? endTime.hour(23).minute(59).second(59) : endTime.clone();
			this.props.history.push(`/local/booking/create/${room ? room.id : null}/${startTime.unix() * 1000}/${endTime.unix() * 1000}/${this.state.timelineMode}`);
		}

		create = () => {
			if (this.state.timelineMode === 'worktime') {
				this.onCreateMeeting(null, moment().hour() + 1, moment().hour() + 2, true)
			} else {
				moment().startOf('day').hour()
				this.onCreateMeeting(null, moment().startOf('day').hour(), moment().startOf('day').hour() + 1, true)
			}
		}

		selectArea = async (value, area) => {
			this.setState({ bookingsAreas: area })
			let selectedBuilding, selectedFloor;
			let whole = _.cloneDeep(this.state.whole)
			whole.active = true;
			area = _.map(area, a => { a.active = a.code == value ? true : false; return a });
			selectedBuilding = _.chain(area).filter(a => a.code === value).first().value();  // 选中的大厦
			if (selectedBuilding) {
				selectedFloor = _.chain(selectedBuilding.children)
					.filter((build, index) => {
						if (index === 0) {
							build.active = true;
						} else {
							build.active = false
						}
						return build;
					})
					.first()
					.value();  // 选中的楼层
				whole.active = true;
				whole.code = _.chain(selectedBuilding.children).first().value().code;
				whole.children = _.chain(selectedBuilding.children).first().value().children;
			} else {
				whole.active = true; // 没有所属大楼  隐藏 楼层 全部按钮
				whole.children = [];
				whole.code = area.code;
			}

			if (selectedFloor && selectedFloor.children.length > 0) {
				selectedFloor.children = _.chain(selectedFloor.children).map((child, index) => {
					child.active = false;
					return child;
				}).value()
			}
			await this.loadRooms({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: selectedBuilding && _.first(selectedBuilding.children).id,
				forbidden: this.state.selectedDate.clone().startOf('day')
			})
			this.setState({
				space: area,
				area: _.chain(area).filter(a => a.active).first().value(),
				buildings: selectedBuilding ? selectedBuilding.children : [],
				building: selectedBuilding ? _.first(selectedBuilding.children) : [],
				floors: selectedFloor ? selectedFloor.children : [],
				floor: selectedFloor ? _.first(selectedFloor.children) : [],
				selectedLocation: value,
				whole
			}, async () => {
				this.resetCapacityAndConfig()
				await this.loadBookings({
					page: this.state.selectPage,
					size: this.state.pageSize,
					buildingId: this.state.building.id,
					begin: this.state.begin.unix(),
					end: this.state.end.unix()
				})
			});
		}

		selectBuildings = async (value, building) => {
			let area = _.chain(this.state.space).filter(a => a.code === this.state.area.code).first().value();
			let buildings = _.chain(area.children).map(a => {
				a.active = a.code == value ? true : false;
				return a;
			}).value();
			let whole = _.cloneDeep(this.state.whole)
			whole.active = true;
			whole.code = value;
			let floors = _.chain(building.children).map((build, index) => {
				build.active = false
				return build;
			}).value();
			await this.loadRooms({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: value,
				forbidden: this.state.selectedDate.clone().startOf('day')
			})
			this.setState({ buildings, floors, building, selectedLocation: whole.code, whole }, async () => {
				this.resetCapacityAndConfig()
				await this.loadBookings({
					page: this.state.selectPage,
					size: this.state.pageSize,
					buildingId: value,
					begin: this.state.begin.unix(),
					end: this.state.end.unix()
				})
			})
		}

		selectFloor = async (value, floor) => {
			let building = _.clone(this.state.building);
			let floors = _.chain(building.children).map(f => {
				f.active = f.code === value ? true : false;
				return f;
			}).value();

			let whole = _.cloneDeep(this.state.whole);
			if (value !== this.state.whole.code) {
				whole.active = false
			} else {
				whole.active = true
			}
			await this.loadRooms({
				page: this.state.selectPage,
				size: this.state.pageSize,
				buildingId: this.state.building.id,
				floorName: whole.active ? '' : floor.name,
				forbidden: this.state.selectedDate.clone().startOf('day')
			})
			this.setState({ floors, floor, selectedLocation: floor.code, whole }, async () => {
				this.resetCapacityAndConfig()
				await this.loadBookings({
					page: this.state.selectPage,
					size: this.state.pageSize,
					buildingId: this.state.building.id,
					floor: whole.active ? '' : this.state.floor.name,
					begin: this.state.begin.unix(),
					end: this.state.end.unix()
				})
			});
		}

		selectConfig = async value => {
			let configs = _.cloneDeep(this.state.configs);
			let deviceConfigs = _.cloneDeep(this.state.deviceConfigs);
			if (_.includes(configs, value)) {
				configs.splice(_.indexOf(configs, value), 1);
			} else {
				configs.push(value)
			}

			_.map(deviceConfigs, cfg => {
				if (_.includes(configs, cfg.value)) {
					cfg.active = true;
				} else {
					cfg.active = false;
				}
				return cfg
			})

			this.setState({ configs, deviceConfigs }, async () => {
				await this.loadRooms({
					page: this.state.selectPage,
					size: this.state.pageSize,
					buildingId: this.state.building.id,
					floorName: this.state.whole.active ? '' : this.state.floor.name,
					deviceTag: configs.join(','),
					capacity: this.state.selectedCapacity,
					forbidden: this.state.selectedDate.clone().startOf('day')
				})
			})
		}

		setCapacity = async value => {
			let cas = _.cloneDeep(this.state.capacities);
			_.map(cas, c => {
				if (c.value === value) {
					c.active = true;
				} else {
					c.active = false;
				}
			})
			this.setState({ selectedCapacity: value, capacities: cas }, async () => {
				await this.loadRooms({
					page: this.state.selectPage,
					size: this.state.pageSize,
					buildingId: this.state.building.id,
					floorName: this.state.whole.active ? '' : this.state.floor.name,
					deviceTag: this.state.configs.join(','),
					capacity: value,
					forbidden: this.state.selectedDate.clone().startOf('day')
				})
			})
		}

		resetCapacityAndConfig = () => {
			let deviceConfigs = _.cloneDeep(this.state.deviceConfigs);
			_.map(deviceConfigs, cfg => {
				cfg.active = false
				return cfg
			})
			let capacities = _.cloneDeep(this.state.capacities);
			_.map(capacities, (c, index) => {
				if (index === 0) {
					c.active = true;
				} else {
					c.active = false;
				}
				return c
			})
			this.setState({ deviceConfigs, capacities, configs: [], capacity: null })
		}

		selectView = type => this.setState({ selectedView: type })

		setTimelineMode = type => {
			this.setState({ timelineMode: type }, () => {
				if (moment().isSame(this.state.selectedDate.clone().format('YYYY-MM-DD'), 'day')) { // 判断是不是当天
					this.setState({
						begin: this.state.selectedDate.clone().startOf('day'),
						end: this.state.selectedDate.clone().endOf('day'),
						selectedDate: this.state.timelineMode === 'allday' ? this.state.selectedDate.clone().startOf('day') : this.state.selectedDate.clone().startOf('day').add(8, 'hour'),
						// timelineValue: this.state.timelineValue.clone()
						timelineValue: moment().clone()
					});
				} else {
					this.setState({
						begin: this.state.selectedDate.clone().startOf('day'),
						end: this.state.selectedDate.clone().endOf('day'),
						selectedDate: this.state.timelineMode === 'allday' ? this.state.selectedDate.clone().startOf('day') : this.state.selectedDate.clone().startOf('day').add(8, 'hour'),
						timelineValue: this.state.timelineMode === 'allday' ? this.state.selectedDate.clone().startOf('day') : this.state.selectedDate.clone().startOf('day').add(8, 'hour')
					});
				}
			})
		}

		render() {
			return <WrappedComponent
				whole={this.state.whole}
				rooms={this.state.allRooms}
				meetings={this.state.meetings}
				space={this.state.space}
				floors={this.state.floors}
				button={this.state.button}
				buildings={this.state.buildings}
				capacities={this.state.capacities}
				httpService={this.httpService}
				currentUser={this.currentUser}
				currentCompany={this.currentCompany}
				timelineValue={this.state.timelineValue}
				timelineMode={this.state.timelineMode}
				selectedView={this.state.selectedView}
				renderTimebar={this.renderTimebar}
				selectedDate={this.state.selectedDate}
				deviceConfigs={this.state.deviceConfigs}
				meetingRoomTypes={this.state.meetingRoomTypes}
				create={this.create}
				selectView={type => this.selectView(type)}
				setTimelineMode={value => this.setTimelineMode(value)}
				selectArea={(value, area) => this.selectArea(value, area)}
				datePickerChange={date => this.datePickerChange(date)}
				renderTreeNodes={space => this.renderTreeNodes(space)}
				mouseMove={(index, percent) => this.mouseMove(index, percent)}
				mouseUp={(index, x, percent) => this.mouseUp(index, x, percent)}
				mouseDown={(index, x, percent) => this.mouseDown(index, x, percent)}
				selectFloor={(value, floor) => this.selectFloor(value, floor)}
				renderMeetingBlock={meeting => this.renderMeetingBlock(meeting)}
				getMeetings={(meetings, room) => this.getMeetings(meetings, room)}
				selectConfig={(value, config) => this.selectConfig(value, config)}
				setCapacity={(value, capacity) => this.setCapacity(value, capacity)}
				selectBuildings={(value, building) => this.selectBuildings(value, building)}
			/>;
		}
	});
};
export default withRouter(hoc(Bookings));
