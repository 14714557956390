import React, { Component, useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { withRouter, useLocation, Link } from 'react-router-dom';
import { Table, Input, DatePicker, Pagination, message, Spin, PageHeader, Select, Button, Modal, TreeSelect, Popover, Popconfirm, Menu, Dropdown } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import moment from 'moment';
import './style.less'
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import SelectUser from '../components/SelectUser';
import { adminOrDispatcherOrGeneral, meetingState } from './Role'
import SelectMeetingState from '../components/SelectMeetingState';
import { meetingTimeStatus, dispatchState, successState, notCancel, isCancel } from './Role';
import { SearchOutlined, UpOutlined, DownOutlined, MobileOutlined, TeamOutlined, LoadingOutlined, MailOutlined, ClearOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons';
import AreaRoomTree from '../components/AreaRoomTree';
import { meetingStates } from '../meetings/Role'
const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;
const { confirm } = Modal;

function MyBookings(props) {
	if (!props.me) return <></>
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const routes = [
		{
			path: '/operation/meeting/schedule',
			breadcrumbName: t('operations'),
		},
		{
			breadcrumbName: t('meetingSchedule'),
		}
	]
	return (
		<div id='scrollTable' className='container' style={{ background: '#fff' }}>
			<PageHeader
				className="page-header"
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('meetingSchedule')}
			>
			</PageHeader>
			<div id="scrollTable1" style={{ display: 'flex', flexDirection: 'column', height: '91%', background: '#FFF', padding: 24, overflow: 'hidden' }}>
				<Spin
					tip={<div style={{ fontSize: 20, margin: '10px 0' }}>{t('searching')}</div>}
					indicator={<LoadingOutlined style={{ fontSize: 28 }} />}
					spinning={props.loading}>
					<div >
						<div className='d-flex align-center mb-2 query1' >
							<div style={{ flex: 1, marginRight: 16 }}>
								<Input.Search
									style={{ width: props.filter ? 550 : 240 }}
									value={props.query}

									onChange={e => props.onKeyChange(e.target.value)}
									placeholder={t('searchMeetingByTitle')}
									onSearch={e => props.search(e)}
								/>
								<DatePicker
									value={props.begin}
									style={{ marginLeft: 8 }}
									placeholder='请选择开始日期'
									onChange={props.onStartChange}
								/>
								<DatePicker
									value={props.end}
									style={{ marginLeft: 8, marginRight: 8 }}
									placeholder='请选择结束日期'
									onChange={props.onEndChange}
								/>
								<Button style={{ marginLeft: 8 }} type='primary' onClick={() => props.handleQueryAll()}><SearchOutlined />{t('search')}</Button>
								<Button style={{ marginLeft: 8 }} type='link' onClick={() => props.toggleFilter()}>{props.filter ? <UpOutlined /> : < DownOutlined />}{props.filter ? t('collapseFilter') : t('expandFilter')}</Button>
							</div>
							{!props.filter &&
								<Pagination
									current={props.page}
									pageSize={props.size}
									showTotal={total => t('itemWithCount', { count: total })}
									total={props.total}
									onChange={props.paginationChange}
								/>
							}
						</div>

						{props.filter &&
							<div className='d-flex justify-between align-center query2'>
								<div className='d-flex' style={{ flex: 1, margin: '0 0 16px 0' }}>
									<div>
										{/* <SelectMeetingState style={{}} selectMeetingState={(e)=>props.selectMeetingState(e)} scheduleSelectMeetingValue={props.scheduleSelectMeetingValue} type='schedule' /> */}
										<Select
											placeholder={t('selectMeetingState')}
											style={{ width: 150 }}
											value={props.scheduleSelectMeetingValue}
											onChange={value => props.selectMeetingState(value)}
										>
											{
												_.map(meetingStates, select => {
													return <Option value={select.state}> {select.name}</Option>
												})
											}
										</Select>
										<AreaRoomTree permission={true} treeCheckable={true} style={{ width: 300, marginLeft: 8, marginRight: 8 }} onChange={(spaces) => props.onSelectedSpacesChange(spaces)} selectedSpaces={props.selectedArea} />
										<Select
											placeholder={t('SelectOrganizerOrOwner')}
											style={{ width: 300 }}
											value={_.chain(props.mutiOptionData).map(x => x.id).value()}
											mode='multiple'
											open={false}
											onChange={value => props.mutiOptionDataOnChange(value)}
											maxTagCount={2}
										>
											{_.map(props.mutiOptionData, d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
										</Select>
										<Button style={{ marginLeft: 8 }} type='primary' onClick={() => props.showSelectModal(true)}>{t('organizer') + '/' + t('owner')}</Button>
										<Button style={{ marginLeft: 8 }} onClick={() => props.clearCondition()}><ClearOutlined />{t('clearCondition')}</Button>
									</div>
								</div>
								<Pagination
									current={props.page}
									pageSize={props.size}
									showTotal={total => t('itemWithCount', { count: total })}
									total={props.total}
									onChange={props.paginationChange}
								/>
							</div>
						}

					</div>

					{/* <div className='d-flex' style={{ justifyContent: 'flex-end', margin: '16px 0' }}>
						<Pagination
							current={props.page}
							pageSize={props.size}
							showTotal={total => t('itemWithCount', { count: total })}
							total={props.total}
							onChange={props.paginationChange}
						/>
					</div> */}

					<div id='myTable' style={{ flex: 1, overflow: 'hidden' }}>
						<Table
							pagination={false}
							size='middle'
							tableLayout='fixed'
							bordered={true}
							dataSource={props.meetings}
							scroll={{ y: props.height - 360 }}
							columns={[
								{
									title: t('index'), dataIndex: 'index', key: 'index', width: 60, align: 'center', render: (e, record, index) => (index + 1) + (props.page - 1) * props.size
								},
								{
									title: `${t('book')}${t('state')}`, dataIndex: 'state', key: 'state', width: 120, ellipsis: true,
									render: (text, record) => {
										return adminOrDispatcherOrGeneral(record);
									}
								},
								{
									title: t('meetingTime'), dataIndex: 'beginAt', key: 'beginAt', width: 310,
									render: (begin, record) => {
										return <div><span style={{ marginRight: 8 }}>{moment(record.beginAt * 1000).format('YYYY-MM-DD HH:mm')} → {moment(record.endAt * 1000).format('HH:mm')}</span> {meetingTimeStatus(record)}</div>
									}
								},
								{
									title: t('meetingLocation'), dataIndex: 'room', key: 'room', ellipsis: true,
									render: item => {
										return item && <span>{item.building.name}-{item.floor}-{item.name}</span>
									}
								},
								{
									title: t('meetingTitle'), dataIndex: 'subject', key: 'subject', ellipsis: true,
									// render: (text, record) => <Link to={`/local/booking/${record.id}/view/schedule`}>{text}</Link>
									render: (text, record) => <a onClick={() => props.toView(`/local/booking/${record.id}/view`)}>{text}</a>
								},
								{
									title: t('meetingOrganizer'), dataIndex: 'organizer.name', key: 'organizer', width: 100, ellipsis: true,
									render: (e, record) =>
										<Popover content={
											<div>
												<p>{t('meetingOwner')} {record.createdBy.name}</p>
												<p>{t('auditor')}： {record.auditors.length > 0 ? _.map(record.auditors, 'name').join(',') : t('none')}</p>
											</div>} >
											{record.organizer.name}
										</Popover>
								},
								{
									title: t('actions'), key: 'action', width: 80,
									render: (e, record) => {
										if (!isCancel(record)) return;

										const menus = (
											<Menu>
												<Menu.Item>
													<Link to={`/local/booking/edit/${record.room.id}/${record.id}/${record.beginAt * 1000}/${record.endAt * 1000}/0`} style={{ marginRight: 8 }}>{t('modify')}</Link>
												</Menu.Item>
												<Menu.Item>
													<a onClick={() => {
														confirm({
															title: t('confirmToCancelMeetingMessage'),
															content: t('thisCannotBeUndone'),
															onOk: () => {
																props.cancelMeeting(record)
															},
															onCancel() {
															},
														});
													}}>{t('cancel')}</a>
												</Menu.Item>
											</Menu>
										);
										return <Dropdown overlay={menus} placement="bottomLeft">
											<MoreOutlined />
										</Dropdown>
									},
								},
							]}
						/>
					</div>
				</Spin>
			</div>
			<SelectUser
				loading={props.isLoadUser}
				selectedUser={props.selectType ? props.mutiOptionData : [props.serviceStaff]}
				visible={props.selectUserVisible}
				onCancel={props.onSelectUserCancel}
				onConfirm={props.onSelectUserConfirm}
				selectType={props.selectType}
			/>
		</div >
	);
}
let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				page: 1,
				size: 20,
				total: 0,
				meetings: [],
				visible: false,
				bookingId: null,
				selectedUser: null,
				selectUserVisible: false,
				selectedMeetingState: 'all',
				selectedServiceState: null,
				scheduleSelectMeetingValue: 'all',
				scheduleSelectedServiceValue: '',
				serviceStaff: null,
				areaDatas: [],
				totalHeight: 0,
				selectedArea: [],
				selectedSpaces: [],
				selectedUsers: [],
				selectType: 'muti',
				mutiOptionData: [],
				query: '',
				loading: true,
				queryAll: false,
				meetingType: '',
				isLoadUser: false,
				filter: false,
				height: window.outerHeight,
				begin: moment(),
				end: null,
				isClear: true
			};

			this.state.query = '';
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			await this.handleQuery();
			this.updateHeight();
			window.addEventListener('resize', this.updateHeight.bind(this));
			this.setState({ isClear: true });
			let { page, begin, end, query, selectedMeetingState, filter, selectedArea, mutiOptionData } = this.state;

			if (localStorage.length > 0) {
				let keys = [];
				for (var k in localStorage) {
					keys.push(k);
				}
				if (localStorage.getItem('meetingSchedulesQuery')) {
					query = localStorage.getItem('meetingSchedulesQuery')
				}

				if (localStorage.getItem('meetingSchedulesSelectedMeetingState')) {
					selectedMeetingState = localStorage.getItem('meetingSchedulesSelectedMeetingState')
				}

				if (localStorage.getItem('meetingSchedulesBegin')) {
					begin = localStorage.getItem('meetingSchedulesBegin') === 'null' ? null : moment(localStorage.getItem('meetingSchedulesBegin'))
				}

				if (localStorage.getItem('meetingSchedulesEnd')) {
					end = localStorage.getItem('meetingSchedulesEnd') === 'null' ? null : moment(localStorage.getItem('meetingSchedulesEnd'));
				}

				if (localStorage.getItem('meetingSchedulesCurrentPage')) {
					page = localStorage.getItem('meetingSchedulesCurrentPage');
				}

				if (localStorage.getItem('meetingSchedulesFilter')) {
					filter = eval(localStorage.getItem('meetingSchedulesFilter').toLowerCase());
				}

				if (JSON.parse(localStorage.getItem('meetingSchedulesSelectedArea'))) {
					selectedArea = JSON.parse(localStorage.getItem('meetingSchedulesSelectedArea'));
				}
				if (JSON.parse(localStorage.getItem('meetingSchedulesMutiOptionData'))) {
					mutiOptionData = JSON.parse(localStorage.getItem('meetingSchedulesMutiOptionData'));
				}
			}

			if (JSON.stringify(AppContextJS.instance.meetingSchedule) !== '{}') {
				this.setState({ page, end, begin, selectedMeetingState, query, filter, selectedArea, mutiOptionData }, async () => {
					await this.handleQuery(page, this.state.size, begin, end, query, selectedMeetingState, filter, selectedArea, _.chain(mutiOptionData).map(x => x.id).value());
				})
				console.log(mutiOptionData)
			}
		}
		async componentWillUnmount() {
			window.removeEventListener('resize', this.updateHeight.bind(this));
			if (this.state.isClear) {
				localStorage.clear();
			}
		}

		async updateHeight() {
			// let table = document.getElementById('scrollTable1');
			// let pageHeader = document.getElementsByClassName('page-header')[0].clientHeight;
			// let query1 = document.getElementsByClassName('query1')[0].clientHeight;
			// let query2 = document.getElementsByClassName('query2')[0].clientHeight;
			// this.setState({ totalHeight: table.clientHeight - 24 * 2 - pageHeader - query1 - query2 - 120 });
		}

		async loadBookings(currentPage, pageSize, begin, end, key, state, serviceState, space, userId, meetingType, queryAll) {
			this.setState({ loading: true });
			let data = await this.transportLayer.getMyBookingsByCondition(begin, end, key, currentPage, pageSize, state, serviceState, space, userId, meetingType, true);
			let { docs, page, limit, totalDocs } = data;
			this.setState({ meetings: docs, page, size: limit, total: totalDocs, loading: false });
		}

		setRooms(room, allrooms) {
			let rooms = [];
			_.each(allrooms, x => {
				if (x.parent && x.parent.id === room.id) {
					let r = { title: x.name, value: x.id, key: x.id, children: [] };
					r.children = this.setRooms(x, allrooms);
					rooms.push(r);
				}
			});
			return rooms;
		}

		locationPath = (room) => {
			let picked = [];
			picked.unshift(room.name);
			_.each(this.state.spaces, (x) => {
				if (typeof room.parent === "string") {
					if (room.parent === x.id) {
						picked.unshift(...this.locationPath(x));
					}
				} else {
					if (room.parent && room.parent.id === x.id) {
						picked.unshift(...this.locationPath(x));
					}
				}
			});
			return picked;
		};

		search = async value => {
			localStorage.setItem('meetingSchedulesQuery', value)
			this.setState({ query: value })
			this.handleQuery();
		}

		paginationChange = async (currentPage, pageSize) => {
			localStorage.setItem('meetingSchedulesCurrentPage', currentPage)
			if (this.state.queryAll) {
				await this.handleQueryAll(
					currentPage,
					pageSize,
					this.state.begin,
					this.state.end,
					this.state.query,
					this.state.selectedMeetingState,
					this.state.selectedServiceState,
					this.state.selectedSpaces,
					this.state.selectedUsers,
					this.state.meetingType,
					true
				)
			} else {
				await this.handleQuery(
					currentPage,
					pageSize,
					this.state.begin,
					this.state.end,
					this.state.query,
					this.state.selectedMeetingState,
					this.state.selectedServiceState,
					this.state.selectedSpaces,
					this.state.selectedUsers,
					this.state.meetingType
				)
			}
		}

		mutiOptionDataOnChange = async (ids) => {
			let users = [];
			_.each(this.state.mutiOptionData, user => {
				if (_.includes(ids, user.id)) {
					users.push(user);
				}
			})
			this.setState({ mutiOptionData: users, selectedUsers: users });
		}

		onSelectUserConfirm = async keys => {
			let selectedUsers = [];
			if (!this.state.selectType) {
				await this.transportLayer.editBooking(this.state.bookingId, { serviceStaff: _.chain(keys).first().value().id })
				selectedUsers = this.state.selectedUsers.join(',');
				this.setState({ selectUserVisible: false }, async () => {
					await this.loadBookings(
						this.state.page,
						this.state.size,
						this.state.begin ? this.state.begin.startOf('day').unix() : null,
						this.state.end ? this.state.end.endOf('day').unix() : null,
						this.state.query,
						this.state.selectedMeetingState,
						this.state.selectedServiceState,
						this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
						this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
						this.state.meetingType,
						this.state.queryAll
					);
				});
			} else {
				let users = [];
				_.each(keys, user => {
					users.push({
						id: user.id,
						name: user.name,
						department: user.department,
						mobile: user.mobile,
						email: user.email,
						code: user.code
					});
					selectedUsers.push(user.id);
				})
				this.setState({ mutiOptionData: users, selectedUsers, selectUserVisible: false, isLoadUser: false });
				localStorage.setItem('meetingSchedulesMutiOptionData', JSON.stringify(users));
			}
		}

		onSelectUserCancel = () => {
			this.setState({ selectUserVisible: false, isLoadUser: false })
		}

		onCancel = () => {
			this.setState({ visible: false })
		}

		showSelectModal = (isMuti, booking) => {
			this.setState({
				selectUserVisible: true,
				bookingId: booking && booking.id,
				serviceStaff: booking && booking.serviceStaff,
				selectType: isMuti ? 'muti' : '',
				isLoadUser: true
			})
		}

		cancelMeeting = async record => {
			await this.transportLayer.cancelMeeting(record);
			message.success(this.t('MeetingCancelSuccessMessage'));
			await this.loadBookings(
				this.state.page,
				this.state.size,
				this.state.begin ? this.state.begin.startOf('day').unix() : null,
				this.state.end ? this.state.end.endOf('day').unix() : null,
				this.state.query,
				this.state.selectedMeetingState,
				this.state.selectedServiceState,
				this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
				this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
				this.state.meetingType,
				this.state.queryAll
			);
		}

		handleQuery = async (page, size, range1, range2, query, selectedMeetingState, selectedServiceState, selectedSpaces, selectedUsers, meetingType) => {
			this.setState({ queryAll: false }, async () => {
				await this.loadBookings(
					page ? page : this.state.page,
					size ? size : this.state.size,
					range1 ? range1.unix() : this.state.begin ? this.state.begin.startOf('day').unix() : null,
					range2 ? range2.unix() : this.state.end ? this.state.end.endOf('day').unix() : null,
					query ? query : this.state.query,
					selectedMeetingState ? selectedMeetingState : this.state.selectedMeetingState,
					selectedServiceState ? selectedServiceState : this.state.selectedServiceState,
					this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
					this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
					meetingType ? meetingType : this.state.meetingType,
					false
				);
			})
		}

		handleQueryAll = async (page, size, range1, range2, query, selectedMeetingState, selectedServiceState, selectedSpaces, selectedUsers, meetingType) => {
			selectedUsers = JSON.parse(localStorage.getItem('mutiOptionData'));
			localStorage.setItem('meetingSchedulesQuery', this.state.query)
			selectedUsers = _.chain(selectedUsers).map(x => x.id).value().join(',')
			this.setState({ queryAll: true }, async () => {
				await this.loadBookings(
					page ? page : this.state.page,
					size ? size : this.state.size,
					range1 ? range1.unix() : this.state.begin ? this.state.begin.startOf('day').unix() : null,
					range2 ? range2.unix() : this.state.end ? this.state.end.endOf('day').unix() : null,
					query ? query : this.state.query,
					selectedMeetingState ? selectedMeetingState : this.state.selectedMeetingState,
					selectedServiceState ? selectedServiceState : this.state.selectedServiceState,
					selectedSpaces ? selectedSpaces.join(',') : this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
					this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
					meetingType ? meetingType : this.state.meetingType,
					true
				)
			})
		}

		clearCondition = async () => {
			localStorage.clear();
			this.setState({
				query: '',
				meetingType: '',
				selectedArea: [],
				selectedSpaces: [],
				// selectedUsers: [],
				mutiOptionData: [],
				// selectedMeetingState: null,
				selectedServiceState: null,
				scheduleSelectMeetingValue: '',
				scheduleSelectedServiceValue: '',
				begin: moment(),
				end: ''
			}, async () => {
				await this.handleQuery();
			})
			console.log(this.state)
		}


		getCharCol(n) {
			let s = '', m = 0;
			while (n > 0) {
				m = n % 26 + 1;
				s = String.fromCharCode(m + 64) + s;
				n = (n - m) / 26;
			}
			return s;
		}

		strToStream(s) {
			var buf = new ArrayBuffer(s.length);
			var view = new Uint8Array(buf);
			for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
			return buf;
		}

		dispatchMeeting = bookingId => {
			Modal.confirm({
				title: this.t('confirmMeetingScheduleMessage'),
				okText: this.t('ok'),
				cancelText: this.t('cancel'),
				onCancel: () => Modal.destroyAll(),
				onOk: async () => {
					await this.transportLayer.dispatchMeeting(bookingId);
					await this.loadBookings(
						this.state.page,
						this.state.size,
						this.state.begin ? this.state.begin.startOf('day').unix() : null,
						this.state.end ? this.state.end.endOf('day').unix() : null,
						this.state.query,
						this.state.selectedMeetingState,
						this.state.selectedServiceState,
						this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
						this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
						this.state.meetingType,
						this.state.queryAll
					);
					Modal.destroyAll();
				},
				centered: true
			})
		}

		selectMeetingState = value => {
			localStorage.setItem('meetingSchedulesSelectedMeetingState', value)
			this.setState({ selectedMeetingState: value });
		}

		onSelectedSpacesChange = async e => {
			localStorage.setItem('meetingSchedulesSelectedArea', JSON.stringify(e));
			this.setState({ selectedSpaces: e });
		}

		deleteMeeting = async id => {
			await this.transportLayer.deleteMeeting(id);
			await this.handleQuery()
		}

		toggleFilter = () => {
			console.log(!this.state.filter)
			let filter = !this.state.filter
			localStorage.setItem('meetingSchedulesFilter', filter)
			this.setState({ filter, height: filter ? window.outerHeight - 80 : window.outerHeight })
		}

		onKeyChange(value) {
			this.setState({ query: value })
		}

		onStartChange = (begin) => {
			localStorage.setItem('meetingSchedulesBegin', begin)
			this.setState({ begin }, async () => {
				await this.loadBookings(
					this.state.page,
					this.state.size,
					begin ? begin.startOf('day').unix() : null,
					this.state.end ? this.state.end.endOf('day').unix() : null,
					this.state.query,
					this.state.selectedMeetingState,
					this.state.selectedServiceState,
					this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
					this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
					this.state.meetingType,
					this.state.queryAll
				);
			})
		}

		onEndChange = (end) => {
			localStorage.setItem('meetingSchedulesEnd', end)
			this.setState({ end }, async () => {
				await this.loadBookings(
					this.state.page,
					this.state.size,
					this.state.begin ? this.state.begin.startOf('day').unix() : null,
					end ? end.endOf('day').unix() : null,
					this.state.query,
					this.state.selectedMeetingState,
					this.state.selectedServiceState,
					this.state.selectedSpaces ? this.state.selectedSpaces.join(',') : null,
					this.state.mutiOptionData ? _.map(this.state.mutiOptionData, 'id').join(',') : null,
					this.state.meetingType,
					this.state.queryAll
				);
			})
		}

		toView = (path) => {
			this.setState({ isClear: false }, () => {
				this.props.history.push(path)
			});
		}

		render() {
			return (
				<WrappedComponent
					me={this.me}
					page={this.state.page}
					size={this.state.size}
					total={this.state.total}
					query={this.state.query}
					initail={this.state.initail}
					loading={this.state.loading}
					begin={this.state.begin}
					end={this.state.end}
					totalHeight={this.state.totalHeight}
					visible={this.state.visible}
					persons={this.state.persons}
					meetings={this.state.meetings}
					meetingType={this.state.meetingType}
					areaDatas={this.state.areaDatas}
					selectedSpaces={this.state.selectedSpaces}
					serviceStaff={this.state.serviceStaff}
					selectType={this.state.selectType}
					mutiOptionData={this.state.mutiOptionData}
					scheduleSelectMeetingValue={this.state.selectedMeetingState}
					scheduleSelectedServiceValue={this.state.scheduleSelectedServiceValue}
					export={this.export}
					onCancel={this.onCancel}
					handleQuery={() => { this.handleQuery(1, 20); }}
					handleQueryAll={() => { this.handleQueryAll(1, 20); }}
					clearCondition={this.clearCondition}
					cancelMeeting={this.cancelMeeting}
					search={this.search}
					locationPath={this.locationPath}
					dispatchMeeting={this.dispatchMeeting}
					showSelectModal={this.showSelectModal}
					selectMeetingState={(value) => this.selectMeetingState(value)}
					selectUserVisible={this.state.selectUserVisible}
					setRange={(range1, range2) => this.setRange(range1, range2)}
					onSelectUserCancel={() => this.onSelectUserCancel()}
					onSelectUserConfirm={(users) => this.onSelectUserConfirm(users)}
					mutiOptionDataOnChange={value => this.mutiOptionDataOnChange(value)}
					onSelectedSpacesChange={e => this.onSelectedSpacesChange(e)}
					paginationChange={(currentPage, pageSize) => this.paginationChange(currentPage, pageSize)}
					deleteMeeting={this.deleteMeeting}
					isLoadUser={this.state.isLoadUser}
					filter={this.state.filter}
					toggleFilter={this.toggleFilter}
					height={this.state.height}
					onKeyChange={(key) => this.onKeyChange(key)}
					selectedArea={this.state.selectedArea}
					onStartChange={this.onStartChange}
					onEndChange={this.onEndChange}
					toView={this.toView}
				/>
			);
		}
	});
};

export default withRouter(hot(MyBookings));



