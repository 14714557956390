// import './style.less';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import SpaceStatus from '../components/SpaceStatus';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import React, { Component } from 'react';
import 'react-rrule-generator/build/styles.css';
import { withRouter, useLocation } from 'react-router-dom';
import { ArrowRightOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Form, Input, DatePicker, TimePicker, TreeSelect, Descriptions, Button, Select, message, Radio, Modal, InputNumber } from 'antd';

const { Item } = Form;
const { TreeNode } = TreeSelect;
const { Option } = Select;
const layout = {
	labelCol: { span: 2, },
	wrapperCol: { span: 16 },
};

function EditSchedule(props) {
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const type = props.match.params.type;
	const { getFieldDecorator, isFieldTouched, getFieldError } = props.form;
	return <div className='container' style={{ overflow: 'auto' }}>
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', background: '#FFFFFF', margin: 24, padding: 24, boxShadow: '0px 0px 8px rgba(221, 229, 234, 0.5)' }}>
			<h3 style={{ fontSize: 16, fontWeight: 500, lineHeight: '28px', marginBottom: 24, fontWeight: 'bold' }}>{t('meetingSchedule')}</h3>
			{/* <div style={{ overflow: 'auto' }}> */}
			<Form {...layout}>
				<Item label={t('meetingType')} labelAlign='left'>
					{getFieldDecorator('meetingType', {
						// rules: [{ required: true, message: t('enterMeetingTitleMessage') }]
						initialValue: props.radio
					})(
						<Radio.Group onChange={props.radioChange}>
							<Radio value={1}>{t('reception')}</Radio>
							<Radio value={2}>{t('internalMeeting')} 1</Radio>
							<Radio value={3}>{t('internalMeeting')} 2</Radio>
						</Radio.Group>
					)}
				</Item>
				<Item label={t('meetingTitle')} labelAlign='left'>
					{getFieldDecorator('subject', {
						rules: [{ required: true, message: t('enterMeetingTitleMessage') }]
					})(
						<Input />
					)}
				</Item>
				<Item label={t('meetingOrganizer')} labelAlign='left'>
					{getFieldDecorator('organization', {
						initialValue: '1'
					})(
						<Select
							allowClear
							showArrow={false}
							onChange={props.selectChange}
							value={props.selectOrganiztion}
						>
							<Option value={'1'}>1</Option>
							<Option value={'2'}>2</Option>
							<Option value={'3'}>3</Option>
							<Option value={'4'}>4</Option>
							<Option value={'5'}>5</Option>
							<Option value={'6'}>6</Option>
							<Option value={'7'}>7</Option>
						</Select>
					)}
				</Item>
				<Item label={t('bookingSpace')} labelAlign='left'>
					{getFieldDecorator('space', {
						initialValue: props.selectedLocation,
						rules: [{ required: true, message: t('selectSpaceMessage') }]
					})(
						<TreeSelect
							value={props.selectedLocation}
							allowClear
							onChange={value => props.onLocationChanged(value)}
						>
							{props.spaces && props.renderTreeNodes(props.spaces)}
						</TreeSelect>
					)}
				</Item>
				<Item label={t('spaceStatus')} labelAlign='left'>
					{getFieldDecorator('spaceStatus', {
					})(
						<div>
							<SpaceStatus
								room={props.room}
								meetings={props.meetings}
							/>
						</div>
					)}
				</Item>
				<Item label={t('meetingTime')} labelAlign='left' style={{ margin: 0 }}>
					<div className='d-flex'>
						<Item>
							{getFieldDecorator('planBeginAt', {
								initialValue: moment(parseInt(props.match.params.start))
							})(
								<DatePicker style={{ width: 260, marginRight: 40 }} format={'ll (dddd)'} allowClear={false} />
							)}
						</Item>
						<div style={{ flex: 1, display: 'flex' }}>
							<Item
								style={{ flex: 1 }}
								validateStatus={props.isAllDay ? 'success' : (isFieldTouched('beginTime') && getFieldError('beginTime') ? 'error' : 'success')}
								help={isFieldTouched('beginTime') && getFieldError('beginTime') || ''}
							>
								{getFieldDecorator('beginTime', {
									initialValue: moment(parseInt(props.match.params.start)),
								})(
									<TimePicker style={{ width: '100%' }} defaultOpenValue={moment('09:00', 'HH:mm')}
										format="h:mm A" minuteStep={1} use12Hours={false} disabled={props.isAllDay}
									/>
								)}
							</Item>
							<Item>
								<ArrowRightOutlined style={{ margin: 'auto 16px' }} />
							</Item>
							<Item
								style={{ flex: 1 }}
								validateStatus={props.isAllDay ? 'success' : (isFieldTouched('endTime') && getFieldError('endTime') ? 'error' : 'success')}
								help={isFieldTouched('endTime') && getFieldError('endTime') || ''}
							>
								{getFieldDecorator('endTime', {
									initialValue: moment(parseInt(props.match.params.end))
								})(
									<TimePicker style={{ width: '100%' }} defaultOpenValue={moment('09:00', 'HH:mm')}
										format="h:mm A" minuteStep={1} use12Hours={false} disabled={props.isAllDay}
									/>
								)}
							</Item>
						</div>
					</div>
				</Item>
				{/* <Item label={t('periodicMeeting')} labelAlign='left'>
						{getFieldDecorator('repeatMeeing')(
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Select defaultValue='no' onChange={value => props.repeatMeeting(value)} style={{ width: 260, marginRight: 16 }}>
									<Option value='no'>{t('nonRepeating')}</Option>
									<Option value='yes'>{t('repeating')}</Option>
								</Select>
								<Checkbox onChange={e => props.checked(e.target.value)}>{t('allDay')}</Checkbox >
							</div>
						)}
					</Item> */}
				<Item label={t('videoConference')} labelAlign='left'>
					{getFieldDecorator('name')(
						!props.isAddVc ? <Button onClick={() => props.toogle(true)} type="primary"><PlusOutlined />{t('addVideoConference')}</Button> :
							<div>
								<Descriptions bordered size='small' column={4}>
									<Descriptions.Item label={t('meetingSystem')}>{t('huawei')}</Descriptions.Item>
									<Descriptions.Item label={t('meetingNumber')}>456 775 321</Descriptions.Item>
									<Descriptions.Item label={t('meetingPIN')}>4999</Descriptions.Item>
									<Descriptions.Item label={t('hostPIN')}>7250</Descriptions.Item>
									{/* <Descriptions.Item label={t('meeintingLink')}>
										<div className='d-flex' style={{ justifyContent: 'space-between' }}>
											<span>https://uv.vc.com/j/79621153405?pwd=ZFFMb0l6QTVmcWI3TnlnMWVsWDY3dz09</span>
											<a>{t('copyLink')}</a>
										</div>
									</Descriptions.Item> */}
								</Descriptions>
								<Button onClick={() => props.toogle(false)} type="primary" style={{ marginTop: 16 }}><MinusOutlined />{t('deleteVideoConference')}</Button>
							</div>
					)}
				</Item>
				<Item label={t('participatingLeaders')} labelAlign='left'>
					{getFieldDecorator('leader')(
						<Select
							mode='multiple'
							onChange={props.selectChange}
						>
							<Option value={'1'}>1</Option>
							<Option value={'2'}>2</Option>
							<Option value={'3'}>3</Option>
							<Option value={'4'}>4</Option>
							<Option value={'5'}>5</Option>
							<Option value={'6'}>6</Option>
							<Option value={'7'}>7</Option>
						</Select>
					)}
				</Item>
				<Item label={t('participants')} labelAlign='left'>
					{getFieldDecorator('personals')(
						<Select
							mode='multiple'
							onChange={props.selectChange}
						>
							<Option value={'1'}>1</Option>
							<Option value={'2'}>2</Option>
							<Option value={'3'}>3</Option>
							<Option value={'4'}>4</Option>
							<Option value={'5'}>5</Option>
							<Option value={'6'}>6</Option>
							<Option value={'7'}>7</Option>
						</Select>
					)}
				</Item>
				<Item label={t('participantNumber')} labelAlign='left'>
					{getFieldDecorator('personNumber')(
						<InputNumber precision={0} style={{ width: '100%' }} />
					)}
				</Item>
				<Item label={t('booth')} labelAlign='left'>
					{getFieldDecorator('roster')(
						<Input placeholder='如有多个卡座名单，请用Tab键分隔' />
					)}
				</Item>
				<div style={{ marginTop: 24, marginBottom: 24 }}><Button type="primary" onClick={props.onSave}>{t('submit')}</Button></div>
			</Form>
		</div>
	</div>;
}

let hot = WrappedComponent => {
	return class EnhancedComponent extends Component {
		get t () { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				// selectedLocation: props.match.params.roomid,
				selectedLocation: this.t('sany'),
				spaces: [],
				date: moment(),
				room: null,
				meetings: [],
				isAddVc: false,
				allDay: false,
				visible: false,
				radio: 1,
				selectOrganiztion: '1'
			};
		}

		async componentDidMount () {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;
			await this.fetchAllSpaces();
			await this.fetchSpaceById(this.props.match.params.roomid);
			await this.fetchBookingBySpaceId(this.props.match.params.roomid);
		}

		onSave = e => {
			e.preventDefault();
			this.props.form.validateFields(async (err, values) => {
				if (err) return
				if (values.space === 'root') values.space = this.state.spaces.code;

				let startMinute = values.beginTime.minute();
				let startHour = values.beginTime.hour();
				values.planBeginAt = values.planBeginAt.clone().set({ hour: startHour, minute: startMinute });

				let endMinute = values.endTime.minute();
				let endHour = values.endTime.hour();
				values.planEndAt = values.planBeginAt.clone().set({ hour: endHour, minute: endMinute });
				values.planBeginAt = moment(values.planBeginAt).unix();
				values.planEndAt = moment(values.planEndAt).unix();
				let owner = {
					name: values.name,
					department: values.department,
					mobile: values.mobile,
					email: values.email
				};
				values.owner = owner;
				delete values.beginTime;
				delete values.endTime;
				delete values.name;
				delete values.department;
				delete values.mobile;
				delete values.email;
				// await this.transportLayer.createBooking(values.space, values);
				// message.success(this.t('meetingCreationSuccessMessage'));
				// this.props.history.push('/');
			});
		}

		async fetchSpaceById (spaceId) {
			// let room = await this.transportLayer.getSpacesById(spaceId);
			let room = spaceDetail;
			this.setState({ room });
		}

		async fetchBookingBySpaceId (spaceId) {
			// let meetings = await this.transportLayer.getBookings(1, 10000, spaceId, null, null);
			let meetings = bookings;
			this.setState({ meetings: meetings.items });
		}

		async fetchAllSpaces () {
			// let data = await this.transportLayer.getSpaces(this.me.company.id, 1, -1);
			let data = spaces;
			// let spaces = data.items;
			let root = [];
			_.each(_.filter(data.items, p => !p.parent), x => {
				let r = { code: x.id, name: x.name, children: [], deviceCount: x.deviceCount, data: x, area: 'root' };
				r.children = this.setRooms(x, data.items);
				root.push(r);
			});
			this.setState({ spaces: _.first(root) });
		}

		setRooms (room, allrooms) {
			let rooms = [];
			_.each(allrooms, x => {
				if (x.parent && x.parent.id === room.id) {
					let r = { code: x.id, name: x.name, children: [], deviceCount: x.deviceCount, data: x };
					r.children = this.setRooms(x, allrooms);
					rooms.push(r);
				}
			});
			return rooms;
		}

		toogle = bool => {
			this.setState({
				isAddVc: bool
			});
		}

		checked = bool => {
			this.setState({
				allDay: bool
			});
		}

		radioChange = () => {

		}

		// repeatMeeting = value => {
		// 	this.setState({ visible: value === 'yes' ? true : false });
		// }

		// setRepeatMeeting = () => {

		// }

		onLocationChanged = async selectedLocation => {
			this.setState({ selectedLocation });
		}

		renderTreeNodes = node => {
			let area = node && node.area && node.area === 'root' ? node.area : node.code;
			return (
				<TreeNode title={node.name} key={node.code} value={area}>
					{node.children && node.children.length > 0 &&
						_.map(node.children, x => { return this.renderTreeNodes(x); })
					}
				</TreeNode>
			);
		}
		selectChange = (value, option) => {
			// console.log(value, option)

			this.setState({ selectOrganiztion: [_.last(value)] })
		}

		render () {
			return <WrappedComponent
				{...this.props}
				date={this.state.date}
				room={this.state.room}
				radio={this.state.radio}
				allDay={this.state.allDay}
				spaces={this.state.spaces}
				isAddVc={this.state.isAddVc}
				visible={this.state.visible}
				meetings={this.state.meetings}
				selectedLocation={this.state.selectedLocation}
				selectOrganiztion={this.state.selectOrganiztion}
				onSave={e => this.onSave(e)}
				toogle={bool => this.toogle(bool)}
				checked={value => this.checked(value)}
				cancel={() => this.setState({ visible: false })}
				radioChange={() => this.radioChange()}
				selectChange={(value, option) => this.selectChange(value, option)}
				// setRepeatMeeting={() => this.setRepeatMeeting()}
				// repeatMeeting={value => this.repeatMeeting(value)}
				renderTreeNodes={space => this.renderTreeNodes(space)}
				allDayCheckChange={checked => this.allDayCheckChange(checked)}
				repeatCheckChange={checked => this.repeatCheckChange(checked)}
				onLocationChanged={selectedLocation => this.onLocationChanged(selectedLocation)}
			/>;
		}
	};
};

export default withRouter(Form.create()(hot(EditSchedule)));