/*
 * @Author: your name
 * @Date: 2020-08-28 10:33:59
 * @LastEditTime: 2020-08-29 21:08:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \s365\apps\s365-booking\src\components\SpaceStatus.js
 * @Params: percent 用于区分时间线左右两边 是否禁用的百分比
 * 					timelineValue 时间线 所需要的时间 来控制位移的距离
 */
import React, { Component } from 'react';
import '../meetings/rooms.less';
import _ from 'lodash';
import moment from 'moment';
import Cookies from 'js-cookie';
import { Popover, Tooltip } from 'antd';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import { UserOutlined, ClockCircleOutlined, EnvironmentOutlined, InfoCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

function SpaceStatus(props) {
	if (!props.meetings) return <></>
	const { t } = useTranslation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const start = 8, end = 20
	return (
		<div style={{ flex: 1, overflow: 'hidden' }}>
			<div className='contentBody'>
				<div className='timelineWrapper'>
					<div className='timelineHeaderWrapper'>
						<div className='headerOuter'>
							{
								props.timelineMode === 'worktime' ? _.times(12, (index) => {
									return (
										<div key={`t-${index}`} className='timeLabel12' style={{ width: `calc(100%/${12})` }}>
											<span style={{ fontSize: 14, color: 'rgba(0,0,0,0.6)' }}>{index + 8}</span>
										</div>
									);
								}) : _.times(24, (index) => {
									return (
										<div key={`t-${index}`} className='timeLabel24'>
											<span style={{ fontSize: 14, color: 'rgba(0,0,0,0.6)' }}>{index}</span>
										</div>
									);
								})
							}
						</div>
					</div>
					<div style={{ display: 'flex', height: '100%', position: 'relative' }}>
						<div style={{ flex: 1 }}>
							{
								props.room && <div key={props.room.id} id="timelineOuter1" className='timelineOuter1' onMouseLeave={props.parentMouseUp}>
									{props.renderTimebar()}
									{
										props.timelineMode === 'worktime' ?
											_.times((end - start), index => {

												let width = end - start;
												let offset = (props.timelineValue.hour() - 8) * 60 + props.timelineValue.minute();
												// let disableValue = 100 * offset / (12 * 60) * 12 / 100;   //和时间线一样的偏移值
												let disableValue = offset / (12 * 60) * 12;
												let percent = `${disableValue.toFixed(2).substr(disableValue.toString().indexOf('.') + 1, 2) / 100}`;
												if (percent <= 0.5) {
													disableValue += 1;
												}
												if (!props.timelineValue.isSame(moment(), 'day') && props.timelineValue.isBefore(moment()) && index === 0) {
													return <div
														key={index}
														id={`${props.room.id}-${index}`}
														className='timeGrid'
														style={{ width: `calc(100%/${width})`, cursor: 'not-allowed' }}
													>
														<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
													</div>
												}
												if (index + 1 == disableValue.toFixed()) {
													return (
														<div
															className='timeGrid'
															key={`${props.room.id}-${index}`}
															style={{ width: `calc(100%/${width})`, display: 'flex' }}
															id={`${props.room.id}-${index}`}
														>
															<div
																key={`${props.room.id}-${index}-not`}
																id={`${props.room.id}-${index}-not`}
																style={{ width: `calc(100%/${width}* ${percent * 100})`, cursor: 'not-allowed' }}
															/>
															<div
																key={`${props.room.id}-${index}-allowed`}
																id={`${props.room.id}-${index}-allowed`}
																style={{ width: `calc(100%/${width}* ${(1 - percent) * 100})` }}
																onMouseDown={() => props.mouseDown(index, props.room, percent)}
																onMouseUp={() => props.mouseUp(index, props.room, percent)}
																onMouseMove={() => props.mouseMove(index, percent)}
															// onMouseEnter={() => props.mouseEnter(index)}
															/>
														</div>
													)
												} else {
													if (index < disableValue.toFixed() - 1) {
														return <div
															key={`${props.room.id}-${index}-not-allowed`}
															id={`${props.room.id}-${index}`}
															className='timeGrid'
															style={{ width: `calc(100%/${width})`, cursor: 'not-allowed' }}
														>
															<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
														</div>
													} else {
														if (props.timelineValue.isSame(moment(), 'day')) {
															return <div
																key={`${props.room.id}-${index}-12`}
																id={`${props.room.id}-${index}`}
																className='timeGrid'
																style={{ width: `calc(100%/${width})` }}
																onMouseDown={() => props.mouseDown(index, props.room)}
																onMouseUp={() => props.mouseUp(index, props.room)}
																onMouseMove={() => props.mouseMove(index)}
															>
																<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
															</div>
														} else {
															if (props.timelineValue.isAfter(moment())) {
																return <div
																	key={`${props.room.id}-${index}-12`}
																	id={`${props.room.id}-${index}`}
																	className='timeGrid'
																	style={{ width: `calc(100%/${width})` }}
																	onMouseDown={() => props.mouseDown(index, props.room)}
																	onMouseUp={() => props.mouseUp(index, props.room)}
																	onMouseMove={() => props.mouseMove(index)}
																>
																	<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																</div>
															} else {
																return <div
																	key={`${props.room.id}-${index}-not-allowed`}
																	id={`${props.room.id}-${index}`}
																	className='timeGrid'
																	style={{ width: `calc(100%/${width})`, cursor: 'not-allowed' }}
																>
																	<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																</div>
															}
														}
													}
												}
											}) : (
												_.times(24, index => {
													let offset = props.timelineValue.hour() * 60 + props.timelineValue.minute();
													// let disableValue = 100 * offset / (12 * 60) * 12 / 100;   //和时间线一样的偏移值
													let disableValue = offset / (24 * 60) * 24;
													let percent = `${disableValue.toFixed(2).substr(disableValue.toString().indexOf('.') + 1, 2) / 100}`;
													if (percent <= 0.5) {
														disableValue += 1;
													}
													if (!props.timelineValue.isSame(moment(), 'day') && props.timelineValue.isBefore(moment()) && index === 0) {
														return <div
															key={index}
															id={`${props.room.id}-${index}`}
															className='timeGrid'
															style={{ width: `calc(100%/${24})`, cursor: 'not-allowed' }}
														>
															<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
														</div>
													}

													if (index + 1 == disableValue.toFixed()) {
														return <div
															className='timeGrid'
															key={`${props.room.id}-${index}`}
															style={{ width: `calc(100%/${24})`, display: 'flex' }}
															id={`${props.room.id}-${index}`}
														>
															<div
																key={`${props.room.id}-${index}-not`}
																id={`${props.room.id}-${index}-not`}
																style={{ width: `calc(100%/${24}* ${percent * 100})`, cursor: 'not-allowed' }}
															/>
															<div
																key={`${props.room.id}-${index}-allowed`}
																id={`${props.room.id}-${index}-allowed`}
																style={{ width: `calc(100%/${24}* ${(1 - percent) * 100})` }}
																onMouseDown={() => props.mouseDown(index, props.room, percent)}
																onMouseUp={() => props.mouseUp(index, props.room, percent)}
																onMouseMove={() => props.mouseMove(index, percent)}
															// onMouseEnter={() => props.mouseEnter(index)}
															/>
														</div>
													} else {
														if (index < disableValue.toFixed() - 1) {
															return <div
																key={`${props.room.id}-${index}-not-allowed`}
																id={`${props.room.id}-${index}`}
																className='timeGrid'
																style={{ width: `calc(100%/${24})`, cursor: 'not-allowed' }}
															>
																<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
															</div>
														} else {
															if (props.timelineValue.isSame(moment(), 'day')) {
																return <div
																	key={`${props.room.id}-${index}-24`}
																	id={`${props.room.id}-${index}`}
																	className='timeGrid'
																	style={{ width: `calc(100%/${24})` }}
																	onMouseDown={() => props.mouseDown(index, props.room)}
																	onMouseUp={() => props.mouseUp(index, props.room)}
																	onMouseMove={() => props.mouseMove(index)}
																>
																	<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																</div>
															} else {
																if (props.timelineValue.isAfter(moment())) {
																	return <div
																		key={`${props.room.id}-${index}-24`}
																		id={`${props.room.id}-${index}`}
																		className='timeGrid'
																		style={{ width: `calc(100%/${24})` }}
																		onMouseDown={() => props.mouseDown(index, props.room)}
																		onMouseUp={() => props.mouseUp(index, props.room)}
																		onMouseMove={() => props.mouseMove(index)}
																	>
																		<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																	</div>
																} else {
																	return <div
																		key={`${props.room.id}-${index}-not-allowed`}
																		id={`${props.room.id}-${index}`}
																		className='timeGrid'
																		style={{ width: `calc(100%/${24})`, cursor: 'not-allowed' }}
																	>
																		<input type='hidden' value={`${moment(start).format('HH:mm')}`} />
																	</div>
																}
															}
														}
													}
												})
											)
									}
									{props.meetings &&
										_.map(props.getMeetings(props.meetings, props.room), meeting => props.renderMeetingBlock(meeting))
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				selectedDate: moment().startOf('day'),
				timelineValue: moment(),
				allRooms: [],
				space: [],
				meetings: [],
				page: 1,
				size: 100,
				percent: 0,
				outerWidth: 0
			};
			this.target = { click: false, downValue: '' };
			this.countIndex = { start: 0, end: 0 };
		}

		async componentDidMount() {
			this.currentUser = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			this.currentCompany = this.context.userContext.me.company;
			this.setState({ outerWidth: document.getElementById('timelineOuter1').clientWidth });
			window.addEventListener('resize', this.update);
		}

		async componentWillUnmount() {
			window.removeEventListener('resize', this.update);
		}

		update = () => {
			let outerWidth = document.getElementById('timelineOuter1').clientWidth;
			this.setState({ outerWidth })
		}

		getMeetings = (meetings, room) => {
			meetings = _.chain(meetings).filter(meeting => meeting.state !== 'reject' && meeting.state !== 'cancel')
				.filter(x => x.room.id === room.id).valueOf()

			if (this.props.removeClass) {
				for (let index = this.countIndex.start; index <= this.countIndex.end; index++) {
					if (document.getElementById(`${this.target.downValue}-${index}`)) {  // 判断 getElementById 是否能取到节点
						document.getElementById(`${this.target.downValue}-${index}`).classList.remove('mouseMoveBlocks')
					}
				}
				this.props.resetRemoveClass()
			}
			return meetings
		};

		renderMeetingTime = meeting => {
			return <span >{`${moment(meeting.beginAt * 1000).format('HH:mm')} → ${moment(meeting.endAt * 1000).format('HH:mm')}`}</span>;
		}

		renderTimebar = () => {
			let offset, value, result;
			if (this.props.mainPage) { // 是否是首页
				if (this.props.timelineMode === 'allday') {
					offset = this.props.timelineValue.hour() * 60 + this.props.timelineValue.minute();
					value = 100 * offset / (24 * 60);
				} else {
					offset = (this.props.timelineValue.hour() - 8) * 60 + this.props.timelineValue.minute();

					value = 100 * offset / (12 * 60);
				}
			} else {
				if (this.props.timelineValue.isSame(moment(), 'day')) { // 判断新增或者编辑 时间线是不是同一天 
					offset = moment().clone().hour() * 60 + moment().clone().minute();
				} else {
					offset = this.props.timelineValue.clone().startOf('day').hour() * 60 + this.props.timelineValue.clone().startOf('day').minute();
				}
				value = 100 * offset / (24 * 60);
			}
			result = !this.props.timelineValue.isSame(moment().format('YYYY-MM-DD'), 'day') ? <div></div> : <div className='currentTimeBar' style={{ left: `${value}%` }}></div>
			return result;
			// return <div className='currentTimeBar' style={{ left: `${value}%` }}></div>;
		}

		renderMeetingBlock = (meeting) => {
			let duration, offset, left, width, freeDuration, freeOffset, freeLeft, freeWidth;

			duration = moment(meeting.endAt * 1000).diff(meeting.beginAt * 1000, 'minutes') / 60.0;
			offset = (moment(meeting.beginAt * 1000).diff(moment(meeting.beginAt * 1000).clone().startOf('day'), 'minutes') / 60.0);
			freeDuration = moment(meeting.endAt * 1000).add(30, 'minute').diff(meeting.endAt * 1000, 'minutes') / 60.0;
			freeOffset = (moment(meeting.beginAt * 1000).add(30, 'minute').diff(moment(meeting.beginAt * 1000).clone().startOf('day'), 'minutes') / 60.0);
			if (this.props.timelineMode === 'allday') {
				left = `${offset * (100 / 24.0)}%`;
				width = `${duration * (100 / 24.0)}%`;
				freeLeft = `${freeOffset * (100 / 24.0)}%`;
				freeWidth = this.state.outerWidth / 24.0 * ((meeting.room.tidyTime || 0) / 60);  // 根据设置的预留时间 去显示对应的色块长度
			} else { // workTime

				let start = 8, end = 20;
				if (offset + duration <= start || offset >= end) {
					return <div key={meeting.id}></div>;
				}
				if (offset < start && offset + duration > start) {
					duration = offset + duration - start;
					offset = start;
				}

				if (offset + duration > end) {
					duration = end - offset;
				}
				freeLeft = `${freeOffset * (100 / 12.0)}%`;
				freeWidth = this.state.outerWidth / 12.0 * ((meeting.room.tidyTime || 0) / 60);  // 根据设置的预留时间 去显示对应的色块长度
				left = (offset - start) * (100 / (end - start));
				width = duration * (100 / (end - start));
				left = left + '%';
				width = width + '%';
			}
			const anonymous = this.t('anonymous');
			let _organizerName = anonymous;
			if (meeting.organizer) {
				_organizerName = meeting.organizer.name;
				if (meeting.organizer.email) {
					_organizerName += `(${meeting.organizer.email})`;
				}
			}

			return (
				<Popover
					key={meeting.id}
					title={<span style={{ fontSize: 16 }}>{meeting.subject}</span>}
					content={
						<div>
							<p><EnvironmentOutlined style={{ marginRight: 4 }} /> {meeting.room.name}</p>
							{meeting.hasVM && <p><VideoCameraOutlined style={{ marginRight: 4 }} /> {this.t('videoConference')}</p>}
							<p><ClockCircleOutlined style={{ marginRight: 4 }} /> {this.renderMeetingTime(meeting)}</p>
							<p style={{ margin: 0 }}><UserOutlined style={{ marginRight: 4 }} /> {_organizerName}</p>
							<p style={{ marginTop: 16, marginBottom: 0 }}><Link to={`/local/booking/${meeting.id}/view`}>{this.t('meetingDetail')}</Link></p>
						</div>
					}>

					<div key={meeting.id} className='meetingBlock' style={{
						left: left,
						width: `${Number(width.replace("%", "") / 100 * this.state.outerWidth) + freeWidth}px`,
						display: 'flex'
					}}>
						<span className={`meetingInfo`} style={{ color: '#fff', width: `${Number(width.replace("%", "") / 100 * this.state.outerWidth)}px` }}>{meeting.subject}</span>
						<Tooltip placement='right' title={this.t('roomReservedForCleanup')} >
							<div style={{
								height: 32,
								marginTop: 3,
								left: freeLeft,
								width: freeWidth,
								background: '#f5cf9c'
							}}>
							</div>
						</Tooltip>
					</div>
				</Popover>
			);
		}

		mouseDown = (index, x, percent) => {
			if (!this.props.mainPage) {
				if (this.countIndex.start >= this.countIndex.end) {
					for (let index = 0; index <= 23; index++) {
						if (document.getElementById(`${this.target.downValue}-${index}`)) {  // 判断 getElementById 是否能取到节点
							document.getElementById(`${this.target.downValue}-${index}`).classList.remove('mouseMoveBlocks')
						}
					}
				} else {
					for (let index = 0; index <= 23; index++) {
						if (document.getElementById(`${this.target.downValue}-${index}`)) {  // 判断 getElementById 是否能取到节点
							document.getElementById(`${this.target.downValue}-${index}`).classList.remove('mouseMoveBlocks')
						}
					}
				}

			}
			this.target.click = true;
			this.target.downValue = x.id;
			this.countIndex.start = index;
			if (percent) {
				document.getElementById(`${this.target.downValue}-${index}-not`).classList.add('mouseMoveBlocks-disable')
				this.setState({ percent })
			}
		}

		mouseMove = (index) => {
			if (this.target.click && this.target.downValue) {
				document.getElementById(`${this.target.downValue}-${index}`).classList.add('mouseMoveBlocks');
			}
		}

		mouseEnter = (end) => {
			if (this.target.click && this.target.downValue) {
				if (end < this.countIndex.start) {
					for (let index = end; index <= this.countIndex.start; index++) {
						let classList = document.getElementById(`${this.target.downValue}-${index}`);
						if (!_.includes(classList.value, 'mouseMoveBlocks')) {
							document.getElementById(`${this.target.downValue}-${index}`).classList.add('mouseMoveBlocks');
						}
					}
				} else {
					for (let index = this.countIndex.start; index <= end; index++) {
						let classList = document.getElementById(`${this.target.downValue}-${index}`);
						if (!_.includes(classList.value, 'mouseMoveBlocks')) {
							document.getElementById(`${this.target.downValue}-${index}`).classList.add('mouseMoveBlocks');
						}
					}
				}
			}
		}

		mouseUp = (index, x, percent) => {
			this.countIndex.end = index + 1;
			if (percent) {
				document.getElementById(`${this.target.downValue}-${index}-not`).classList.add('mouseMoveBlocks-disable')
				this.setState({ percent }, () => {
					this.onCreateMeeting(x, this.countIndex.start, index + 1, percent);
				})
			} else {
				this.onCreateMeeting(x, this.countIndex.start, index + 1);
			}
			this.target.click = false;
		}


		renderBlock = (beginTime, endTime) => {
			if (this.countIndex.code) {
				for (let i = 0; i <= 23; i++) {
					document.getElementById(`${this.countIndex.code}-${i}`).classList.remove('mouseMoveBlocks');
				}
			}
			if (document.getElementById('meetingOccupy')) {
				document.getElementById('meetingOccupy').classList.remove('mouseMoveBlocks');
				document.getElementById('timelineOuter1').removeChild(document.getElementById('meetingOccupy'))
			}
			let duration, offset, left, width;
			duration = endTime.diff(beginTime, 'minutes') / 60.0;
			offset = beginTime.diff(beginTime.clone().startOf('day'), 'minutes') / 60.0;
			left = `${offset * (100 / 24.0)}%`;
			width = `${duration * (100 / 24.0)}%`;
			let div = document.createElement('div');
			let span = document.createElement('span');
			span.classList.add('meetingInfo');
			span.classList.add('mouseMoveBlocks');
			div.classList.add('meetingOccupy');
			div.classList.add('mouseMoveBlocks');
			div.id = 'meetingOccupy';
			div.style.left = left;
			div.style.width = `calc(${width})`;
			div.style.height = '40px';
			div.style.lineHeight = '40px';
			div.appendChild(span);
			document.getElementById('timelineOuter1').appendChild(div);
		}

		onCreateMeeting = (room, startIndex, endIndex, percent) => {
			let copyStart = startIndex;
			let copyEnd = endIndex;
			if (copyStart >= copyEnd) {
				startIndex = copyEnd - 1;
				endIndex = copyStart + 1;
			}
			let start = this.props.timelineMode === 'worktime' ? 8 : 0;
			let startTime = this.props.timelineValue.clone();
			let endTime = this.props.timelineValue.clone();
			if (parseInt(this.state.percent)) {
				startTime.add(start, 'hour').add(startIndex * 60, 'minute').add(60 * Math.ceil(this.state.percent), 'minute');
				endTime.add(parseInt(start), 'hour').add(endIndex * 60, 'minute');
			} else {
				if (parseInt(percent)) {
					startTime.add(parseInt(start), 'hour').add(startIndex * 60, 'minute').add(60 * parseInt(percent), 'minute');
					endTime.add(parseInt(start), 'hour').add(endIndex * 60, 'minute');
				} else {
					startTime.startOf('day').add(parseInt(start), 'hour').add(startIndex * 60, 'minute').add(60 * parseInt(percent), 'minute');
					endTime.startOf('day').add(parseInt(start), 'hour').add(endIndex * 60, 'minute');
				}
			}
			endTime.clone().hour() === 0 ? endTime.hour(23).minute(59).second(59) : endTime.clone()
			if (this.props.mainPage) {
				this.props.history.push(`/local/booking/create/${room.id}/${startTime.unix() * 1000}/${endTime.unix() * 1000}/${this.props.timelineMode}`);
			} else {
				this.props.resetDate(startTime, endTime)
			}
		}

		parentMouseUp = () => {
			this.target.click = false
		}

		render() {
			return <WrappedComponent
				room={this.props.room}
				meetings={this.props.meetings}
				mainPage={this.props.mainPage}
				timelineMode={this.props.timelineMode}
				timelineValue={this.props.timelineValue}
				renderTimebar={this.renderTimebar}
				mouseEnter={this.mouseEnter}
				parentMouse={this.parentMouseUp}
				mouseMove={(index, percent) => !this.props.match.params.roomid && this.mouseMove(index, percent)}
				mouseUp={(index, x, percent) => !this.props.match.params.roomid && this.mouseUp(index, x, percent)}
				mouseDown={(index, x, percent) => !this.props.match.params.roomid && this.mouseDown(index, x, percent)}
				renderMeetingBlock={meeting => this.renderMeetingBlock(meeting)}
				getMeetings={(meetings, room) => this.getMeetings(meetings, room)}
			/>;
		}
	});
};

export default withRouter(hoc(SpaceStatus));