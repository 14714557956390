import './App.less';
import React from 'react';
import AppMenu from './AppMenu';
import AppContext from './AppContext';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import { HashRouter as Router } from 'react-router-dom';
import { UserContext, OtherContext, ProvideAppContext } from './Contexts';
import { useTranslation, withTranslation } from 'react-i18next';
import 'moment/locale/zh-cn';
import moment from 'moment';
import emitter from './emitter';
import Cookies from 'js-cookie';

function App(props) {
	const { t } = useTranslation();
	if (!props.me) return <></>;

	return (
		<ConfigProvider locale={props.locale} autoInsertSpaceInButton={false}>
			<UserContext.Provider value={{ me: props.me }}>
				<OtherContext.Provider>
					<ProvideAppContext>
						<Router>
							<AppMenu
								httpServiceEndpoint={props.httpServiceEndpoint}
								menuRoles={props.menuRoles}
								availablecompanies={props.me.availablecompanies}
								switchCompany={(code) => props.switchCompany(code)}
								me={props.me}
								homePage={props.homePage}
								logout={(locationPathName) => props.logout(locationPathName)}
								changeLocale={props.changeLocale}
								profile={props.profile}
							/>
						</Router>
					</ProvideAppContext >
				</OtherContext.Provider >
			</UserContext.Provider >
		</ConfigProvider >
	);
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends React.Component {
		get t() { return this.props.t; }
		constructor(props) {
			super(props);
			this.state = {
				me: null,
				locale: null
			};
			this.httpServiceEndpoint = AppContext.instance.httpService;
			this.webSocketServiceEndpoint = AppContext.instance.wsService;
			this.homePage = AppContext.instance.homePage;
			this.clientId = AppContext.instance.clientId;
			let backendOrigin = AppContext.instance.httpService;
			this.authorizeEndpoint = `${backendOrigin}/v2/oauth/authorize`;
			this.callbackEndpoint = `${backendOrigin}/v2/oauth/callback/self`;

			//event-changed
			this.event_changes = [];
			let eventUrl = `${this.webSocketServiceEndpoint}/channel/event-changed`;
			this.event_ws = new WebSocket(eventUrl);
			this.event_ws.onmessage = msg => {
				let p = JSON.parse(msg.data);
				if (p) {
					this.event_changes.push(p);
				}
			};

			this.event_ws.onclose = e => {
				this.eventReconnect(eventUrl);
			}

			this.event_ws.onerror = e => {
				this.eventReconnect(eventUrl);
			}

			//booking-changed
			this.booking_changes = [];
			let bookingUrl = `${this.webSocketServiceEndpoint}/channel/booking-changed`;
			this.booking_ws = new WebSocket(bookingUrl);

			this.booking_ws.onmessage = msg => {
				let p = JSON.parse(msg.data);
				if (p) {
					this.booking_changes.push(p);
				}
			};

			this.booking_ws.onclose = e => {
				this.bookingReconnect(bookingUrl);
			}

			this.booking_ws.onerror = e => {
				this.bookingReconnect(bookingUrl);
			}

			//platform-changed
			this.platform_changes = [];
			let platformUrl = `${this.webSocketServiceEndpoint}/channel/platform-changed`;
			this.platform_ws = new WebSocket(platformUrl);
			this.platform_ws.onmessage = msg => {
				let p = JSON.parse(msg.data);
				if (p) {
					this.platform_changes.push(p);
				}
			};

			this.platform_ws.onclose = e => {
				this.platformReconnect(platformUrl);
			}

			this.platform_ws.onerror = e => {
				this.platformReconnect(platformUrl);
			}

			this.timer = setInterval(() => {
				if (this.booking_changes.length > 0) {
					emitter.emit('booking_changed', this.booking_changes);
					this.booking_changes = [];
				}
				if (this.platform_changes.length > 0) {
					emitter.emit('platform_changed', this.platform_changes);
					this.platform_changes = [];
				}
				if (this.event_changes.length > 0) {
					emitter.emit('event_changed', this.event_changes);
					this.event_changes = [];
				}
			}, 1000);
		}

		bookingReconnect = (url) => {
			this.booking_ws = new WebSocket(url);
			this.booking_ws.onmessage = msg => {
				let p = JSON.parse(msg.data);
				if (p) {
					this.booking_changes.push(p);
				}
			};

			this.booking_ws.onclose = e => {
				this.bookingReconnect(url);
			}

			this.booking_ws.onerror = e => {
				this.bookingReconnect(url);
			}
		}

		eventReconnect = (url) => {
			this.event_ws = new WebSocket(url);
			this.event_ws.onmessage = msg => {
				let p = JSON.parse(msg.data);
				if (p) {
					this.event_changes.push(p);
				}
			};

			this.event_ws.onclose = e => {
				this.eventReconnect(url);
			}

			this.event_ws.onerror = e => {
				this.eventReconnect(url);
			}
		}

		platformReconnect = (url) => {
			this.platform_ws = new WebSocket(url);
			this.platform_ws.onmessage = msg => {
				let p = JSON.parse(msg.data);
				if (p) {
					this.platform_changes.push(p);
				}
			};

			this.platform_ws.onclose = e => {
				this.platformReconnect(url);
			}

			this.platform_ws.onerror = e => {
				this.platformReconnect(url);
			}
		}

		async componentDidMount() {
			let token = await AppContext.instance.getToken();
			if (!token) {
				let redirect_uri = `${encodeURIComponent(window.location)}`;
				let url = `${window.location.origin}/api/v2/oauth/authorize?response_type=token&redirect_uri=${redirect_uri}&client_id=${this.clientId}`;
				window.location.href = url;
				return;
			}

			this.oauth = AppContext.instance.oauth;
			this.transportLayer = AppContext.instance.transportLayer;
			let me = await this.transportLayer.getCurrentUser();
			if (!me) {
				let redirect_uri = encodeURIComponent(`${this.callbackEndpoint}?redirect=${encodeURIComponent(window.location.href)}`);
				let url = `${this.authorizeEndpoint}?response_type=code&client_id=${this.clientId}&redirect_uri=${redirect_uri}`;
				window.location = url;
				return;
			}

			let lang = Cookies.get('lang');

			if (!lang && me.lang) {
				lang = me.lang;
				Cookies.set('lang', lang);
			}

			let _l = zhCN;
			if (lang && (lang.toLowerCase() === 'en-us')) _l = enUS;

			this.setState({
				me: me,
				locale: _l
			}, () => {
			})
		}

		componentWillUnmount() {
			if (this.timer) {
				clearInterval(this.timer);
			}
		}

		changeLocale = async () => {
			Cookies.set('lang', this.t('thisLanguageMomentCode') === 'zh-cn' ? 'zh-cn' : 'en-us');
			this.setState({ locale: this.t('thisLanguageMomentCode') === 'zh-cn' ? zhCN : enUS });
			moment.locale(this.t('thisLanguageMomentCode'));
			let userUpdate = {
				id: this.state.me.id,
				lang: this.t('thisLanguageMomentCode') === 'zh-cn' ? 'zh-cn' : 'en-us'
			};
			await this.transportLayer.updateUser(userUpdate);
		}

		render() {
			return <WrappedComponent
				httpServiceEndpoint={this.httpServiceEndpoint}
				homePage={this.homePage}
				me={this.state.me}
				locale={this.state.locale}
				switchCompany={(code) => this.oauth.switchCompany(code)}
				logout={(locationPathName) => this.oauth.logout(locationPathName)}
				changeLocale={this.changeLocale}
				profile={() => this.oauth.profile()}
			/>
		}
	})
}

export default hoc(App);
