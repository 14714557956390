import React, { Component, useState, useEffect, useRef } from 'react';
import { InputNumber, Tooltip, Layout, Button, Table, Form, Input, Select, Modal, Descriptions, message, Popconfirm, Upload, Tree, Tabs, Pagination, Col, Row, Collapse, Card, TreeSelect, Icon, Menu, Dropdown, DatePicker, PageHeader } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, useLocation } from 'react-router-dom';
import Debug from 'debug';
import './style.less';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import _ from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import { LinkOutlined, PlusOutlined, CaretDownOutlined, DownloadOutlined, InboxOutlined, MoreOutlined, FlagOutlined, HomeOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import moment from 'moment';
import Map from './Map';
import { getErrorMessage, formatBuildings } from '../share/Helper';
import SelectUser from '../components/SelectUser';

const NoPictures = require('../images/NoPictures.png');
const { Search } = Input;
const { Sider } = Layout;
const { Dragger } = Upload;
const FormItem = Form.Item;
const Option = Select.Option;
const debug = Debug('app');
const TreeNode = Tree.TreeNode;
const TabPane = Tabs.TabPane;
const { Panel } = Collapse;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

function Spaces(props) {
	if (!props.me) return <></>;
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const { t } = useTranslation();
	const [height, setHeight] = useState(0);
	const ref = useRef(null);
	useEffect(() => {
		setHeight(ref.current.clientHeight - 150)
	});

	return (
		<div className='container' style={{ background: '#fff', overflow: 'hidden' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('spaceConfig')}
			>
			</PageHeader>
			<div style={{
				height: '92%', background: '#fff', display: 'flex',
			}}>
				<div role='sidebar-tree' style={{ background: '#FBFAFA', flex: '0 0 400', padding: '24px 16px', borderRight: '1px solid #E3E9EE', display: 'flex', flexDirection: 'column' }}>
					<div className='d-flex' style={{ marginBottom: 8 }}>
						<Search style={{ marginRight: 8 }} placeholder={t('searchSpacePlaceholder')} value={props.searchBuildingText} onChange={(value) => props.searchBuildingChange(value)} />
					</div>
					<Scrollbars style={{ flex: 1 }} >
						<Tree autoExpandParent={props.autoExpandParent} switcherIcon={<CaretDownOutlined />} onSelect={(selectedKeys, e) => props.onSelect(e)} expandedKeys={props.expandedSpaceKeys} onExpand={(e) => props.onExpand(e)}>
							{props.renderTreeNodes(props.spaces)}
						</Tree>
					</Scrollbars>
				</div>
				<div ref={ref} role='detail-wrapper' style={{ padding: 24, flex: 1, display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
						<div>
							{
								props.spaces &&
								<span style={{ color: 'rgba(68,81,95,0.65)' }}> {t('spaceSummary', { spacesLength: props.spaces.length, cityCount: props.cityCount, buildingCount: props.buildingCount, floorCount: props.floorCount, roomCount: props.roomCount })}</span>
							}
						</div>
						<Pagination
							current={props.page}
							pageSize={props.size}
							showTotal={total => t('itemWithCount', { count: total })}
							total={props.total}
							onChange={props.paginationChange}
						/>
					</div>
					<Table
						size='middle'
						tableLayout='fixed'
						bordered={false}
						pagination={false}
						rowKey='id'
						dataSource={props.allRooms}
						scroll={{ y: height }}
						columns={[
							{ title: t('roomName'), dataIndex: 'name', key: 'name', align: 'left' },
							{ title: t('disableStat'), dataIndex: 'disable', key: 'disable', align: 'center', render: (e) => e ? t('disabled') : t('Enabled') },
							{
								title: t('forbiddenTime'), dataIndex: 'forbidden', key: 'forbidden', align: 'center', width: 310,
								render: (e) => {
									return e ? <div>{moment(e.begin * 1000).format('YYYY-MM-DD')} → {moment(e.end * 1000).format('YYYY-MM-DD')}</div> : t('none')
								}
							},
							{ title: t('needAudit'), dataIndex: 'auditors', key: 'auditors', ellipsis: true, align: 'center', render: (e) => e.length > 0 ? t('yes') : t('no') },
							{ title: t('auditor'), dataIndex: 'auditors', key: 'auditors', ellipsis: true, align: 'center', render: (e) => _.chain(e).map(e => e.name).join(',').value() },
							{
								title: t('actions'), key: 'action', width: 80,
								render: (e, room) => <a onClick={() => props.editRoom(room)}>{t('config')}</a>
							}
						]}
					/>
					<a href="" id="hf" />
				</div>
			</div>
			<ImportModelWrapper
				visible={props.showImport}
				onCancel={props.cancelImport}
				uploadSpaces={props.uploadSpaces}
				httpService={props.httpService}
				company={props.me.company}
				updateSpaces={props.updateSpaces}
				exportSpaces={props.exportSpaces}
			/>
			<EditRoomWrapper
				title={props.title}
				visible={props.roomVisible}
				edit={(space) => props.edit(space)}
				onCancel={props.cancel}
				onCancel={props.cancelRoom}
				target={props.selectSpace}
				isNew={props.isNew}
				treeBuildingDatas={props.treeBuildingDatas}
				renderRoomTreeNodes={props.renderRoomTreeNodes}
				selectedBuilding={props.selectedBuilding}
				selectSpace={props.selectSpace}
				httpService={props.httpService}
				token={props.token}
				findRoomByCode={props.findRoomByCode}
				users={props.users}
				selectUserVisible={props.selectUserVisible}
				onSelectUserCancel={props.onSelectUserCancel}
				onSelectConfirm={props.onSelectConfirm}
			/>

		</div >
	);
}

const ImportModelWrapper = withTranslation()(Form.create()(
	class extends Component {
		downloadTemplate = () => {
			document.getElementById('space_hf').href = `${this.props.httpService}/v2/import/spaces.xlsx`;
			document.getElementById('space_hf').click();
		}

		render() {
			const { visible, onCancel, uploadSpaces, t } = this.props;

			return (
				<Modal
					destroyOnClose
					centered
					visible={visible}
					cancelText={t('cancel')}
					footer={null}
					onCancel={onCancel}
				>
					<div>
						<div>
							<Dragger {...uploadSpaces}>
								<p className="ant-upload-drag-icon"><InboxOutlined /></p>
								<p className="ant-upload-text">{t('uploadFileText')}</p>
								<p className="ant-upload-hint">{t('uploadFileHint')}</p>
							</Dragger>
						</div>

						<Button type="primary" style={{ marginTop: 20, width: '100%' }} onClick={() => this.downloadTemplate()}>
							<DownloadOutlined />{t('downloadTemplate')}
							<a href='' id='space_hf' />
						</Button>
					</div>
				</Modal>);
		}
	}
));

const EditRoomWrapper = withTranslation()(Form.create()(
	class extends Component {
		constructor(props) {
			super(props);
			this.state = {
				floors: [],
				// liveSteamUris: [],
				selectUserVisible: false,
				user: null,
				selectedUser: null
			}
		}

		componentWillReceiveProps(next) {
			if (next.target && next.target !== this.props.target) {
				this.setState({
					user: this.props.users,
					selectedUser: next.target.auditors
				});
			}
		}

		save = async (e) => {
			e.preventDefault();
			const form = this.props.form;
			form.validateFields((err, values) => {
				if (values.forbidden && values.forbidden.length > 0) {
					values.forbidden = {
						begin: moment(_.first(values.forbidden)).startOf('day').unix(),
						end: moment(_.last(values.forbidden)).endOf('day').unix()
					}
				}
				if (this.state.selectedUser) {
					values.auditors = _.map(this.state.selectedUser, u => u.id);
					values.auditors = _.filter(values.auditors, a => a !== null)
				} else {
					values.auditors = []
				}

				this.props.edit(values);
				form.resetFields();
				this.cancelModal();
			});
		}

		cancelModal = () => {
			this.setState({
				floors: [],
				fileList: [],
				avatar: '',
				liveSteamUris: []
			});
			this.props.form.resetFields();
			this.props.onCancel();
		}

		selectedBuilding = (e) => {
			let selected = e.props.dataRef;
			this.setState({ floors: selected.floors });
		}

		showModal = () => {
			this.setState({ selectUserVisible: true })
		}

		onSelectUserCancel = () => {
			this.setState({ selectUserVisible: false })
		}

		onSelectUserConfirm = user => {
			this.setState({ selectUserVisible: false, user: user, selectedUser: user })
		}

		render() {
			const { t } = this.props;
			const { visible, form, title, target, isNew, renderRoomTreeNodes, treeBuildingDatas } = this.props;
			const { getFieldDecorator } = form;

			return (
				<Modal
					destroyOnClose
					centered
					width='600px'
					okText={t('confirm')}
					cancelText={t('cancel')}
					title={title}
					visible={visible}
					onOk={this.save}
					onCancel={this.cancelModal}
				>
					<Form layout='vertical'>
						{target &&
							<Form.Item label='Id' style={{ display: 'none' }}>
								{getFieldDecorator('id', { initialValue: !isNew && target ? target.id : null })(<Input />)}
							</Form.Item>
						}
						<FormItem label={t('spaceName')}>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('enterSpaceNameMessage'), }],
								initialValue: !isNew && target ? target.name : null
							})(
								<Input disabled />
							)}
						</FormItem>
						<FormItem label={t('uniqueCode')} style={{ display: 'none' }}>
							{getFieldDecorator('code', {
								rules: [{ required: true, message: t('enterUniqueCodeMessage') }],
								initialValue: !isNew && target ? target.code : null
							})(
								<Input />
							)}
						</FormItem>
						<FormItem label={t('building')} style={{ display: 'none' }}>
							{getFieldDecorator('building', {
								rules: [{ required: true, message: t('selectBuilding') }],
								initialValue:
									!isNew ?
										(target ? target.building.id : null) :
										(this.props.selectedBuilding ? this.props.selectedBuilding.id : null)
							})(
								<TreeSelect onSelect={(_, e) => this.selectedBuilding(e)}>
									{renderRoomTreeNodes(treeBuildingDatas)}
								</TreeSelect>
							)}
						</FormItem>
						<FormItem label={t('floor')} style={{ display: 'none' }}>
							{getFieldDecorator('floor', {
								rules: [{ required: true, message: t('enterFloor') }],
								initialValue:
									!isNew ?
										(target ? target.floor : null) :
										(this.props.selectedBuilding && this.props.selectedBuilding.selectedFloor ? this.props.selectedBuilding.selectedFloor : null)
							})(
								<Select onChange={e => this.setState({ floor: e })}>
									{
										_.map(this.state.floors.length > 0 ? this.state.floors : !isNew ? (target ? (target.building.floors) : []) : (this.props.selectedBuilding ? this.props.selectedBuilding.floors : []), floor => {
											return <Option value={floor}>{floor}</Option>
										})
									}
								</Select>
							)}
						</FormItem>
						<FormItem label={t('auditor')}  >
							{getFieldDecorator('auditors', {
								initialValue: !isNew && target ? target.auditors : []
							})(
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
									<Input placeholder={t('auditor')} style={{ marginRight: 10 }} value={this.state.selectedUser ? _.chain(this.state.selectedUser).map(u => u.name).join(',') : ''} />
									<Button type='primary' onClick={() => this.showModal()}>{t('select')}</Button>
								</div>
							)}
						</FormItem>
						<FormItem label={t('RoomDisabledLabel')}  >
							{getFieldDecorator('disable', {
								initialValue: !isNew && target ? target.disable : false
							})(
								<Select>
									<Option value={true}>{t('disabled')}</Option>
									<Option value={false}>{t('enabled')}</Option>
								</Select>
							)}
						</FormItem>

						<FormItem label={t('roomForbiddenLabel')}  >
							{getFieldDecorator('forbidden', {
								initialValue: !isNew && target && target.forbidden && target.forbidden.begin && target.forbidden.end ? [moment(target.forbidden.begin * 1000), moment(target.forbidden.end * 1000)] : null
							})(
								<RangePicker style={{ width: '100%' }} />
							)}
						</FormItem>
					</Form>
					<SelectUser
						selectType='muti'
						selectedUser={this.state.selectedUser}
						visible={this.state.selectUserVisible}
						onCancel={this.onSelectUserCancel}
						onConfirm={this.onSelectUserConfirm}
					/>
				</Modal>
			);
		}
	}
));

let hoc = (WrappedComponent) => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;

		constructor(props) {
			super(props);
			this.state = {
				actions: [],
				alarms: [],
				space: null,
				selectSpace: null,
				visible: false,
				visibleDevice: false,
				roomVisible: false,
				isNew: false,
				title: this.t('addSpace'),
				number: 1,
				totalElements: 0,
				dnumber: 1,
				dtotalElements: 0,
				expandedSpaceKeys: [],
				showdevice: null,
				showImport: false,
				previewDialog: false,
				showBuildingModal: false,
				buildings: [],
				treeBuildingDatas: [],
				treeRooms: [],
				rooms: [],
				selectedBuilding: null,
				roomCount: 0,
				cityCount: 0,
				buildingCount: 0,
				floorCount: 0,
				roomPage: 0,
				autoExpandParent: true,
				users: [],
				allRooms: [],
				selectUserVisible: false,
				page: 1,
				size: 20,
				total: 0,
				building: '',
				floor: ''
			};

			this.dataList = [];
			this.roomDisable = 'all';
			this.searchBuildingText = '';
		}

		async pageChange(page) {
			let { size } = this.state;
			this.setState({ number: page });
		}

		async showSizeChange(page, size) {
			this.setState({ size });
		}

		async dpageChange(page) {
			let { size } = this.state;
			this.setState({ dnumber: page });
		}

		async dshowSizeChange(page, size) {
			this.setState({ size });
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.token = AppContextJS.instance.token;
			this.httpService = AppContextJS.instance.httpService;
			this.transportLayer = AppContextJS.instance.transportLayer;

			await this.getTreeRooms();
			await this.getUsers();
			await this.getRoomsByPage(null, null, 1);
			if (this.state.spaces) {
				this.generateList(this.state.spaces);
			}
		}

		onNewState(state) {
		}

		async getUsers() {
			let data = await this.transportLayer.getUsers(null, 1, -1);
			this.setState({ users: data.docs });
		}

		onSelect = async (e) => {
			let building = '';
			let floor = '';

			if (e.selected) {
				let node = e.node.props.dataRef;
				building = _.last(node.code.split('-'));
				if (node.hasRoom) {
					floor = node.name;
				}
			}

			this.setState({ building, floor })
			await this.getRoomsByPage(building, floor, 1)
		}

		onExpand(expandedSpaceKeys) {
			this.setState({ expandedSpaceKeys, autoExpandParent: false });
		}

		renderRoomTreeNodes(node) {
			if (node) {
				return (
					_.map(node, x => {
						{ return this.renderRoomTrees(x); }
					})
				);
			}
		}

		renderRoomTrees(node) {
			if (node) {
				return (
					<TreeNode title={node.name} value={node.code} key={node.code} dataRef={node} selectable={node.selectable ? true : false}>
						{
							node.children && node.children.length > 0 &&
							_.map(node.children, x => {
								return this.renderRoomTrees(x)
							})
						}
					</TreeNode>
				);
			}
		}

		renderTreeNodes(node) {
			if (node) {
				return (
					_.map(node, x => {
						{ return this.renderTrees(x); }
					})
				);
			}
		}

		renderTrees(node) {
			if (node) {
				if (_.isEmpty(node.children)) return;

				let deviceCount = '';
				if (node.deviceCount && node.deviceCount !== 0) {
					deviceCount = ` (${node.deviceCount})`;
				}

				const index = node.name.indexOf(this.searchBuildingText);
				const beforeStr = node.name.substr(0, index);
				const afterStr = node.name.substr(index + this.searchBuildingText.length);

				const title =
					index > -1 ? (
						<span>
							{beforeStr}
							<span style={{ color: 'red' }}>{this.searchBuildingText}</span>
							{afterStr}
						</span>
					) : (
						<span>{`${node.name}${deviceCount ? deviceCount : ''}`}</span>
					);

				return (
					<TreeNode
						selectable={_.includes(node.code, 'building-') || _.includes(node.code, 'floor-')}
						title={
							<div className='tree-node-container'>
								{title}
							</div>
						}
						key={node.code} dataRef={node} >
						{
							node.children && node.children.length > 0 &&
							_.map(node.children, x => { return this.renderTrees(x); })
						}
					</TreeNode >
				);
			}
		}

		async edit(space) {
			space.company = this.me.company.id;
			try {
				if (space.id) {
					await this.transportLayer.updateRoom(space);
					this.setState({ selectSpace: space });
					message.success(this.t('spaceModificationSuccessMessage'));
				}
			} catch (error) {
				debug(error);
				return;
			}

			this.cancel();
			await this.getRoomsByPage(this.state.building, this.state.floor, 1);
		}

		async cancel() {
			this.setState({ visible: false });
		}

		async cancelRoom() {
			this.setState({ roomVisible: false });
		}

		async cancelDevice() {
			this.setState({ visibleDevice: false });
		}

		async cancelShowDevice() {
			this.setState({ visibleShowDevice: false });
		}

		async onShowDevice(device) {
			this.setState({ showdevice: device });
			this.setState({ visibleShowDevice: true });
		}

		async editDashboards() {
			this.setState({ visible: false });
		}

		async sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}

		async showImportModal() {
			this.setState({ showImport: true });
		}

		async handleCancelImport() {
			this.setState({ showImport: false });
		}

		getTreeRooms = async () => {
			if (!this.me || !this.me.permission) return;

			let permission = '';
			if (this.me.permission.booking) {
				permission = 'booking'
			}

			let rooms = await this.transportLayer.getRoomsByCompanyId({ page: 1, size: -1, permission });
			if (rooms) {
				let { docs, totalDocs } = rooms;
				this.setState({ roomCount: totalDocs, rooms: docs, });
				this.formatBuildings(rooms.docs);
			}
		}

		getRoomsByPage = async (buildingId, floorName, page) => {
			if (!this.me || !this.me.permission) return;

			let permission = '';
			if (this.me.permission.booking) {
				permission = 'booking'
			}

			let rooms = await this.transportLayer.getRoomsByCompanyId({ page, size: -1, buildingId, floor: floorName, disable: this.roomDisable, permission });
			if (rooms) {
				let { docs, page, totalDocs, limit } = rooms;
				this.setState({ allRooms: docs, total: totalDocs, page, size: limit });
			}
		}

		formatBuildings = (rooms) => {
			let { spaces, expandedSpaceKeys, cityCount, buildingCount, floorCount } = formatBuildings(rooms);
			this.setState({ spaces, expandedSpaceKeys, cityCount, buildingCount, floorCount });
			this.generateList(rooms);
		}

		editRoom = async (room) => {
			this.setState({ selectSpace: room, isNew: false, title: this.t('spaceConfig'), roomVisible: true });
		}

		searchBuildingChange = e => {
			const { value } = e.target;
			const expandedKeys = this.dataList
				.map(item => {
					if (item.name.indexOf(value) > -1) {
						return this.getParentKey(item.code, this.state.spaces);
					}
					return null;
				})
				.filter((item, i, self) => item && self.indexOf(item) === i);
			this.setState({
				expandedSpaceKeys: expandedKeys,
				autoExpandParent: true,
			});

			this.searchBuildingText = value;
		}

		getParentKey = (key, tree) => {
			let parentKey;
			for (let i = 0; i < tree.length; i++) {
				const node = tree[i];
				if (node.children) {
					if (node.children.some(item => item.code === key)) {
						parentKey = node.code;
					} else if (this.getParentKey(key, node.children)) {
						parentKey = this.getParentKey(key, node.children);
					}
				}
			}

			return parentKey;
		}

		generateList = data => {
			for (let i = 0; i < data.length; i++) {
				const node = data[i];
				if (!_.find(this.dataList, d => d.name === node.name && d.code === node.code)) {
					this.dataList.push({ name: node.name, code: node.code });
					if (node.children) {
						this.generateList(node.children);
					}
				}
			}
		};

		toSpaceInfo = (space) => {
			this.setState({ selectSpace: space, selectedBuilding: null }, async () => {
			})
		}

		exportSpaces = async () => {
			let data = await this.transportLayer.exportSpaces();
			let path = this.httpService + '/v2' + data.path;

			document.getElementById('hf').href = path;
			document.getElementById('hf').click();
		}

		onSelectUserCancel = () => {
			this.setState({
				selectUserVisible: false
			})
		}

		onSelectConfirm = async (users) => {
			this.setState({
				selectUserVisible: false
			})

			await this.transportLayer.createPermissions(_.map(users, 'id'), { admin: true }).then(() => {
				message.success(this.t('permissionAdded'));
			}, error => {
				message.error(getErrorMessage(error));
			})

			this.getUsers(this.state.page, this.state.size, this.state.searchValue);
		}

		paginationChange = async (currentPage, pageSize) => {
			await this.getRoomsByPage(this.state.building, this.state.floor, currentPage);
		}

		render() {
			return <WrappedComponent
				me={this.me}
				selectSpace={this.state.selectSpace}
				alarms={this.state.alarms}
				actions={this.state.actions}
				space={this.state.space}
				title={this.state.title}
				visible={this.state.visible}
				isNew={this.state.isNew}
				visibleDevice={this.state.visibleDevice}

				visibleShowDevice={this.state.visibleShowDevice}
				showdevice={this.state.showdevice}

				totalElements={this.state.totalElements}
				size={this.state.size}
				current={this.state.number}

				dtotalElements={this.state.dtotalElements}
				dcurrent={this.state.dnumber}

				updateSpaces={this.updateSpaces}
				renderTreeNodes={(space) => this.renderTreeNodes(space)}
				cancel={() => this.cancel()}
				cancelRoom={() => this.cancelRoom()}
				onSelect={(space) => this.onSelect(space)}
				onChange={(value) => this.onChange(value)}
				onExpand={(e) => this.onExpand(e)}
				expandedSpaceKeys={this.state.expandedSpaceKeys}
				edit={(space) => this.edit(space)}
				onSelectDevice={(device) => this.onSelectDevice(device)}
				unbind={(device) => this.unbind(device)}

				pageChange={(page) => this.pageChange(page)}
				showSizeChange={(page, size) => this.showSizeChange(page, size)}

				dpageChange={(page) => this.dpageChange(page)}
				dshowSizeChange={(page, size) => this.dshowSizeChange(page, size)}

				showImport={this.state.showImport}
				showImportModal={() => this.showImportModal()}
				cancelImport={() => this.handleCancelImport()}
				httpService={this.httpService}
				previewDialog={this.state.previewDialog}

				showBuilding={this.showBuilding}
				showBuildingModal={this.state.showBuildingModal}
				buildingTitle={this.state.buildingTitle}
				selectedBuilding={this.state.selectedBuilding}
				spaces={this.state.spaces}
				treeBuildingDatas={this.state.treeBuildingDatas}
				loadDatas={this.loadDatas}
				renderRoomTreeNodes={(building) => this.renderRoomTreeNodes(building)}
				roomCount={this.state.roomCount}
				cityCount={this.state.cityCount}
				buildingCount={this.state.buildingCount}
				floorCount={this.state.floorCount}
				rooms={this.state.rooms}
				delete={this.delete}
				roomPage={this.state.roomPage}
				roomDisable={this.roomDisable}
				searchBuildingChange={this.searchBuildingChange}
				searchBuildingText={this.searchBuildingText}
				toSpaceInfo={this.toSpaceInfo}
				token={this.token}
				httpService={this.httpService}
				exportSpaces={this.exportSpaces}
				autoExpandParent={this.state.autoExpandParent}
				users={this.state.users}
				allRooms={this.state.allRooms}

				editRoom={(room) => this.editRoom(room)}

				onSelectUserCancel={this.onSelectUserCancel}
				onSelectConfirm={this.onSelectConfirm}
				selectUserVisible={this.state.selectUserVisible}
				roomVisible={this.state.roomVisible}
				page={this.state.page}
				size={this.state.size}
				total={this.state.total}
				paginationChange={(currentPage, pageSize) => this.paginationChange(currentPage, pageSize)}
			/>;
		}
	});
};

export default hoc(Spaces);
