import axios from 'axios';
import _ from 'lodash';
import Debug from 'debug';
import AppContext from '../AppContext';
import Cookies from 'js-cookie';

const debug = Debug('s365:transportLayer');

export default class TransportLayer {
	constructor(token) {
		this.client = axios.create();
		let backendOrigin = AppContext.instance.httpService;
		this.clientId = AppContext.instance.clientId;
		this.meEndpoint = `${backendOrigin}/v2/user`;
		this.authorizeEndpoint = `${backendOrigin}/v2/oauth/authorize`;

		this.client.interceptors.request.use(cfg => {
			cfg.baseURL = AppContext.instance.httpService;

			if (token) {
				cfg.headers = _.assign(cfg.headers, { Authorization: `Bearer ${token}` });
			}
			return cfg;
		});

		this.client.interceptors.response.use(response => response, error => {
			debug(error);
			if (error.response.status === 401) {
				Cookies.remove('token');

				let url = `${this.authorizeEndpoint}?response_type=token&client_id=${this.clientId}&redirect_uri=${encodeURIComponent(window.location.href)}`;
				window.location = url;
			}
			return Promise.reject(error);
		});
	}

	async getCurrentUser() {
		let response = await this.client.get(`${this.meEndpoint}`);
		let me = response.data;
		if (me) {
			me.company = await this.getUserCompany();
			return me;
		}
	}

	async getUserCompany() {
		let response = await this.client.get('/v2/user/company');
		return response.data;
	}

	async getCompany(companyId) {
		let response = await this.client.get(`/v2/companies/${companyId}`);
		return response.data;
	}

	async updateCompany(companyId, company) {
		return await this.client.patch(`/v2/companies/${companyId}`, company);
	}

	async createCompany(company) {
		return await this.client.post('/v2/companies', company);
	}

	async deleteCompany(companyId) {
		return await this.client.delete(`/v2/companies/${companyId}`);
	}

	async getCompanyLicense(companyId) {
		let response = await this.client.get(`/v2/companies/${companyId}/license`);
		return response.data;
	}

	async getDeviceTypes() {
		let response = await this.client.get('/v2/deviceTypes');
		return response.data;
	}

	async getCompanies(key, page, size) {
		let response = null;
		let url = `/v2/companies?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		response = await this.client.get(url);
		return response.data;
	}

	async getSpacesById(spaceId, stat) {
		let url = `/v2/rooms/${spaceId}`
		if (stat) {
			stat = `?stat=${stat}`
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getRoom(roomId) {
		let response = await this.client.get(`/v2/rooms/${roomId}`);
		return response.data;
	}

	async getSpaces({ page, size }) {
		let url = new URL(`${AppContext.instance.httpService}/v2/buildings?page=${page}&size=${size}`)

		let response = await this.client.get(url);
		return response.data;
	}

	async getRoomsByParams({ page, size, key, buildingId, floorName, deviceTag, capacity }) {
		let url = new URL(`${AppContext.instance.httpService}/v2/rooms?page=${page}&size=${size}`);
		if (key) {
			url.searchParams.set('key', key)
		}
		if (buildingId) {
			url.searchParams.set('buildingId', buildingId)
		}
		if (floorName) {
			url.searchParams.set('floor', floorName)
		}
		if (deviceTag) {
			url.searchParams.set('deviceTag', deviceTag)
		}
		if (capacity) {
			url.searchParams.set('capacity', capacity)
		}
		let response = await this.client.get(url);

		return response.data;
	}

	async getRoomByIdOrCode(roomIdOrCode) {
		let url = new URL(`${AppContext.instance.httpService}/v2/rooms/${roomIdOrCode}`);
		let response = await this.client.get(url);

		return response.data;
	}

	async getDevices(key, page, size, online, alarm, product, hasSpace) {
		let url = `/v2/devices?page=${page}&size=${size}&state=true`;
		if (key) {
			url += `&key=${key}`;
		}
		if (online === true || online === false) {
			url += `&online=${online}`;
		}
		if (alarm === true || alarm === false) {
			url += `&alarm=${alarm}`;
		}
		if (product && product !== 'all') {
			url += `&product=${product}`;
		}
		if (hasSpace === true | hasSpace === false) {
			url += `&hasSpace=${hasSpace}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getDevice(deviceId) {
		let response = await this.client.get(`/v2/devices/${deviceId}?state=true`);
		return response.data;
	}

	async getDevicesByRoomboard({ key, page, size, building, room, floor, deviceType, online }) {
		let url = `/v2/devices/?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (building) {
			url += `&building=${building}`;
		}
		if (room) {
			url += `&room=${room}`;
		}
		if (floor) {
			url += `&floor=${floor}`;
		}
		if (deviceType) {
			url += `&deviceType=${deviceType}`;
		}

		if (online === true || online === false) {
			url += `&online=${online}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getDevicesBySpace(spaceId, page, size, key, online, alarm, product) {
		let url = `/v2/spaces/${spaceId}/devices?page=${page}&size=${size}&state=true`;
		if (key) {
			url += `&key=${key}`;
		}
		if (online === true || online === false) {
			url += `&online=${online}`;
		}

		if (alarm === true || alarm === false) {
			url += `&alarm=${alarm}`;
		}
		if (product && product !== 'all') {
			url += `&product=${product}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getDevicesByRoom(roomId, page, size, key, online, deviceType) {
		let url = `/v2/rooms/${roomId}/devices?page=${page}&size=${size}&state=true`;
		if (key) {
			url += `&key=${key}`;
		}
		if (online === true || online === false) {
			url += `&online=${online}`;
		}
		if (deviceType) {
			url += `&deviceType=${deviceType}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getDevicesByProduct(product, page, size, key, online, alarm,) {
		let url = `/v2/products/${product}/devices?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (online === true || online === false) {
			url += `&online=${online}`;
		}

		if (alarm === true || alarm === false) {
			url += `&alarm=${alarm}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async updateDevice(device) {
		return await this.client.patch(`/v2/devices/${device.id}`, device);
	}

	async createDevice(companyId, device) {
		return await this.client.post(`/v2/companies/${companyId}/devices`, device);
	}

	async deleteDevice(deviceId) {
		return await this.client.delete(`/v2/devices/${deviceId}`);
	}

	async getEvents(key, dates, page, size, companyId) {
		let url = `/v2/companies/${companyId}/events?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (!_.isEmpty(dates)) {
			url += `&begin=${dates[0].unix() * 1000}&end=${dates[1].unix() * 1000}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getEventsBySpace(page, size, space, key, begin, end) {
		let url = `/v2/spaces/${space}/events?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getEventsByDevice(deviceId, page, size) {
		let response = await this.client.get(`/v2/devices/${deviceId}/events?page=${page}&size=${size}`);
		return response.data;
	}

	async getEvent(eventId) {
		let response = await this.client.get(`/v2/events/${eventId}`);
		return response.data;
	}

	async clearEvents() {
		return await this.client.delete('/v2/events');
	}

	async getProducts(companyId, page, size, value, health, deviceType, brand) {
		let url = `/v2/companies/${companyId}/products?page=${page}&size=${size}`;
		if (value) {
			url += `&key=${value}`;
		}
		if (health && health !== 'all') {
			url += `&health=${health}`;
		}

		if (deviceType && deviceType !== 'all') {
			url += `&deviceType=${deviceType}`;
		}

		if (brand && brand !== 'all') {
			url += `&brand=${brand}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getAllProducts(page, size, value, health, deviceType, brand) {
		let url = `/v2/products?page=${page}&size=${size}`;
		if (value) {
			url += `&key=${value}`;
		}
		if (health && health !== 'all') {
			url += `&health=${health}`;
		}

		if (deviceType && deviceType !== 'all') {
			url += `&deviceType=${deviceType}`;
		}

		if (brand && brand !== 'all') {
			url += `&brand=${brand}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getProduct(productId) {
		let response = await this.client.get(`/v2/products/${productId}`);
		return response.data;
	}

	async getDevicesQuery(page, size, key, online, deviceType, room) {
		let url = `/v2/devices?page=${page}&size=${size}&state=true`;
		if (key) {
			url += `&key=${key}`;
		}

		if (online === true || online === false) {
			url += `&online=${online}`;
		}
		if (deviceType) {
			url += `&deviceType=${deviceType}`;
		}
		if (room) {
			url += `&room=${room}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async updateProduct(product) {
		return await this.client.patch(`/v2/products/${product.id}`, product);
	}

	async createProduct(product, companyId) {
		return await this.client.post(`/v2/companies/${companyId}/products`, product);
	}

	async deleteProduct(productId) {
		return await this.client.delete(`/v2/products/${productId}`);
	}

	async getCrashes(companyId, page, size, key, dates, space) {
		let url = `/v2/companies/${companyId}/crashes?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (!_.isEmpty(dates)) {
			url += `&begin=${dates[0].unix()}&end=${dates[1].unix()}`;
		}

		if (space) {
			url += `&space=${space}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getCrashesByDevice(deviceId, page, size) {
		let response = await this.client.get(`/v2/devices/${deviceId}/crashes?page=${page}&size=${size}`);
		return response.data;
	}

	async updateCrash(crash) {
		return await this.client.patch(`/v2/crashes/${crash.id}`, crash);
	}

	async createCrash(crash) {
		return await this.client.post(`/v2/devices/${crash.device}/crashes`, crash);
	}

	async getCrash(crashId) {
		let response = await this.client.get(`/v2/crashes/${crashId}`);
		return response.data;
	}

	async getRepairs(companyId, page, size, key, dates, space) {
		let url = `/v2/companies/${companyId}/repairs?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (dates !== null && !_.isEmpty(dates)) {
			url += `&begin=${dates[0].unix()}&end=${dates[1].unix()}`;
		}

		if (space) {
			url += `&space=${space}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async updateRepair(repair) {
		return await this.client.patch(`/v2/repairs/${repair.id}`, repair);
	}

	async createRepair(repair) {
		return await this.client.post(`/v2/devices/${repair.device}/repairs`, repair);
	}

	async getRepair(repairId) {
		let response = await this.client.get(`/v2/repairs/${repairId}`);
		return response.data;
	}

	async getRepairsByDevice(deviceId, page, size) {
		let respone = await this.client.get(`/v2/devices/${deviceId}/repairs?page=${page}&size=${size}`);
		return respone.data;
	}

	async getUsers(key, page, size, role) {
		let url = `/v2/users?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (role) {
			url += `&role=${role}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async updateUser(user) {
		return await this.client.patch(`/v2/users/${user.id}`, user);
	}

	async createUser(user) {
		return await this.client.post(`/v2/companies/${user.company}/users`, user);
	}

	async deleteUser(userId) {
		return await this.client.delete(`/v2/users/${userId}`);
	}

	async getRoles(companyId, page, size, key) {
		let url = `/v2/companies/${companyId}/roles?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getMenuRolesByClientId() {
		let response = await this.client.get(`/v2/menuRoles/${this.clientId}`);
		return response.data;
	}

	async updateRole(role) {
		return await this.client.patch(`/v2/roles/${role.id}`, role);
	}

	async createRole(companyId, role) {
		return await this.client.post(`/v2/companies/${companyId}/roles`, role);
	}

	async deleteRole(roleId) {
		return await this.client.delete(`/v2/roles/${roleId}`);
	}

	async getFiles() {
		let response = await this.client.get('/v2/files');
		return response.data;
	}

	async getAlarms(companyId, key, page, size, begin, end, spaceId, productId, deviceId) {
		let url = `/v2/companies/${companyId}/alarms?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (spaceId && spaceId !== 'all') {
			url += `&space=${spaceId}`;
		}
		if (productId && productId !== 'all') {
			url += `&product=${productId}`;
		}
		if (deviceId && deviceId !== 'all') {
			url += `&device=${deviceId}`;
		}

		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getAlarmsBySpace(spaceId, page, size, key, productId, deviceId, begin, end) {
		let url = `/v2/spaces/${spaceId}/alarms?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (productId && productId !== 'all') {
			url += `&product=${productId}`;
		}
		if (deviceId && deviceId !== 'all') {
			url += `&device=${deviceId}`;
		}
		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getAlarmsByDevice(deviceId, page, size, key, begin, end) {
		let url = `/v2/devices/${deviceId}/alarms?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async updateAlarm(alarm) {
		return await this.client.patch(`/v2/alarms/${alarm.id}`, alarm);
	}

	async deviceAction(deviceId, actionName, body) {
		return await this.client.post(`/v2/devices/${deviceId}/actions/${actionName}`, body);
	}

	async getBookingsByBuildingId({ page, size, buildingId, begin, end, floor, capacity, deviceTag }) {
		let url = new URL(`${AppContext.instance.httpService}/v2/buildings/${buildingId}/bookings?page=${page}&size=${size}`);
		if (buildingId) {
			url.searchParams.set('buildingId', buildingId)
		}

		if (floor) {
			url.searchParams.set('floor', floor)
		}

		if (capacity) {
			url.searchParams.set('capacity', capacity)
		}

		if (deviceTag) {
			url.searchParams.set('deviceTag', deviceTag)
		}

		if (begin) {
			url.searchParams.set('begin', begin)
		}

		if (end) {
			url.searchParams.set('end', end)
		}


		let response = await this.client.get(url);
		return response.data;
	}

	async getBookingsByRoomIdOrCode({ roomIdOrCode, begin, end }) {
		let url = new URL(`${AppContext.instance.httpService}/v2/rooms/${roomIdOrCode}/bookings`);
		if (begin) {
			url.searchParams.set('begin', begin)
		}

		if (end) {
			url.searchParams.set('end', end)
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getBookingsByCompany(companyId, currentPage, size, begin, end, key) {
		let url = `/v2/companies/${companyId}/room/bookings?page=${currentPage}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getBookingById(id) {
		let response = await this.client.get(`/v2/bookings/${id}`);
		return response.data;
	}

	async getMyBookings(currentPage, size, begin, end, key, score, state) {
		let url = `/v2/user/bookings?page=${currentPage}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (score) {
			url += `&score=${score}`;
		}
		if (begin) {
			url += `&begin=${begin}`;
		}
		if (end) {
			url += `&end=${end}`;
		}
		if (state && state !== 'all') {
			url += `&state=${state}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getMyBookingsByCondition(begin, end, key, currentPage, pageSize, state, serviceState, room, owner, meetingType, permission) {
		let url = `/v2/user/bookings/dispatcher?page=${currentPage}&size=${pageSize}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (begin) {
			url += `&begin=${begin}`;
		}
		if (end) {
			url += `&end=${end}`;
		}
		if (state && state !== 'all') {
			url += `&state=${state}`;
		}
		if (room) {
			url += `&room=${room}`;
		}
		if (owner) {
			url += `&owner=${owner}`;
		}
		if (permission) {
			url += `&permission=${permission}`
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async createBooking(roomIdOrCode, body) {
		return await this.client.post(`/v2/rooms/${roomIdOrCode}/bookings`, body);
	}

	async editBooking(bookingId, body) {
		return await this.client.patch(`/v2/bookings/${bookingId}`, body);
	}

	async getSystemDiagrams(companyId) {
		let response = await this.client.get(`/v2/companies/${companyId}/systemDiagrams?page=1&size=-1`);
		return response.data;
	}

	async getSystemDiagramsBySpaceId(spaceId) {
		let response = await this.client.get(`/v2/spaces/${spaceId}/systemDiagrams?page=1&size=-1`);
		return response.data;
	}

	async createSystemDiagram(spaceId, systemDiagram) {
		return await this.client.post(`/v2/spaces/${spaceId}/systemDiagrams`, systemDiagram);
	}

	async deleteSystemDiagram(systemDiagramId) {
		return await this.client.delete(`/v2/systemDiagrams/${systemDiagramId}`);
	}

	async updateSystemDiagram(systemDiagram) {
		return await this.client.patch(`/v2/systemDiagrams/${systemDiagram.id}`, systemDiagram);
	}

	async getSystemDiagram(diagramId) {
		let response = await this.client.get(`/v2/systemDiagrams/${diagramId}`);
		return response.data;
	}

	async getDocumentsBySpaceId(spaceId, page, size, key) {
		let url = `/v2/spaces/${spaceId}/documents?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async createDocument(spaceId, document) {
		return await this.client.post(`/v2/spaces/${spaceId}/documents`, document);
	}

	async updateDocument(document) {
		return await this.client.patch(`/v2/documents/${document.id}`, document);
	}

	async deleteDocument(documentId) {
		return await this.client.delete(`/v2/documents/${documentId}`);
	}

	async createDocumentFile(documentId, file) {
		return await this.client.post(`/v2/documents/${documentId}/files`, file);
	}

	async updateDocumentFile(documentId, file) {
		return await this.client.patch(`/v2/documents/${documentId}/files/${file.id}`, file);
	}


	async deleteDocumentFile(documentId, fileId) {
		return await this.client.delete(`/v2/medias`);
	}

	async createMedia(media) {
		return await this.client.post(`/v2/medias`, media);
	}

	async updateMedia(media) {
		return await this.client.patch(`/v2/medias/${media.id}`, media);
	}

	async deleteMedia(mediaId) {
		return await this.client.delete(`/v2/medias/${mediaId}`);
	}

	async getDocument(documentId) {
		let response = await this.client.get(`/v2/documents/${documentId}`);
		return response.data;
	}

	async getDocuments() {
		let response = await this.client.get(`/v2/documents/`);
		return response.data;
	}

	async getAlarmRules(companyId, key, ruleSource, page, size) {
		let url = `/v2/companies/${companyId}/alarmRules?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (ruleSource && ruleSource !== 'all') {
			url += `&source=${ruleSource}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async createAlarmRule(alarmRule) {
		return await this.client.post(`/v2/products/${alarmRule.product}/alarmRules`, alarmRule);
	}

	async updateAlarmRule(alarmRule) {
		return await this.client.patch(`/v2/alarmRules/${alarmRule.id}`, alarmRule);
	}

	async deleteAlarmRule(alarmRuleId) {
		return await this.client.delete(`/v2/alarmRules/${alarmRuleId}`);
	}

	async getAlarmRule(alarmRuleId) {
		let response = await this.client.get(`/v2/alarmRules/${alarmRuleId}`);
		return response.data;
	}

	async getActionLogs(page, size, tag, dates) {
		let url = `/v2/actionLogs?page=${page}&size=${size}`;
		if (tag) {
			url += `&tag=${tag}`;
		}
		if (dates !== null && !_.isEmpty(dates)) {
			url += `&begin=${dates[0].unix()}&end=${dates[1].unix()}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getStatisticsByBooking(begin, end, space, page, size) {
		let url = `/v2/statistics/booking?page=${page}&size=${size}`;
		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}

		if (space) {
			url += `&space=${space}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getSpaceStatistics(page, size, begin, end, space) {
		let url = `/v2/spaceStatistics?page=${page}&size=${size}`;
		if (begin) {
			url += `&begin=${begin}`;
		}

		if (end) {
			url += `&end=${end}`;
		}

		if (space) {
			url += `&space=${space}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getRatingStatistics(begin, end, space, serviceStaff) {
		let url = `/v2/statistics/scoreBooking?`;
		if (begin) {
			url += `&begin=${begin}`;
		}
		if (end) {
			url += `&end=${end}`;
		}
		if (space) {
			url += `&space=${space}`;
		}
		if (serviceStaff) {
			url += `&serviceStaff=${serviceStaff}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async getProductDocuments(productId, key) {
		let url = `/v2/products/${productId}/documents`;
		if (key) {
			url += `?key=${key}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async createProductDocument(productId, document) {
		return await this.client.post(`/v2/products/${productId}/documents`, document);
	}

	async updateProductDocument(document) {
		return await this.client.patch(`/v2/documents/${document.id}`, document);
	}

	async deleteProductDocument(documentid) {
		return await this.client.delete(`/v2/documents/${documentid}`);
	}

	async getDocumentFiles(documentId, key) {
		let url = `/v2/documents/${documentId}/files`
		if (key) {
			url += `?key=${key}`
		}
		let files = await this.client.get(url);
		return files.data;
	}

	async createDocumentFile(documentId, file) {
		return await this.client.post(`/v2/documents/${documentId}/files`, file);
	}

	async updateDocumentFile(documentId, file) {
		return await this.client.patch(`/v2/documents/${documentId}/files/${file.id}`, file);
	}

	async deleteDocumentFile(documentId, fileId) {
		return await this.client.delete(`/v2/documents/${documentId}/files/${fileId}`);
	}

	async cancelMeeting(booking) {
		return await this.client.post(`/v2/bookings/${booking.id}/cancel`, booking)
	}

	async earlyEndMeeting(bookingId) {
		return await this.client.post(`/v2/bookings/${bookingId}/finish`)
	}

	async deleteMeeting(bookingId) {
		return await this.client.delete(`/v2/bookings/${bookingId}`)
	}
	async getVideoMeetings(currentPage, size, begin, end, key, score, state) {
		let url = `/v2/user/vcs?page=${currentPage}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (score) {
			url += `&score=${score}`;
		}
		if (begin) {
			url += `&begin=${begin}`;
		}
		if (end) {
			url += `&end=${end}`;
		}
		if (state && state !== 'all') {
			url += `&state=${state}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async createVideoMeeting(body) {
		return this.client.post(`/v2/vcs`, body);
	}

	async getVideoMeeting(id) {
		let response = await this.client.get(`/v2/vcs/${id}`);
		return response.data;
	}

	async updateVideoMeeting(id, body) {
		return await this.client.patch(`/v2/vcs/${id}`, body);
	}

	async setUserRule(rule) {
		return await this.client.post('/v2/rules/userRule', rule);
	}

	async updateUserRule(ruleId, rule) {
		return await this.client.patch(`/v2/rules/userRule/${ruleId}`, rule);
	}

	async deleteUserRule(ruleId) {
		return await this.client.delete(`/v2/rules/userRule/${ruleId}`);
	}

	async getUserRules(key, type, page, size) {
		let url = `/v2/rules/userRule?type=${type}&page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async editServiceStaff(spaceId, body) {
		return await this.client.patch(`/v2/rules/serviceStaff/${spaceId}`, body);
	}

	async resetServiceStaff(spaceId) {
		return await this.client.delete(`/v2/rules/serviceStaff/${spaceId}`);
	}

	async setSpaceRule(spaceId, body) {
		return await this.client.post(`/v2/rules/spaceRule/${spaceId}`, body);
	}

	async getBookingsByDispatcher(begin, end, key, page, size, state, serviceState) {
		let url = `/v2/user/bookings/dispatcher?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (begin) {
			url += `&begin=${begin}`;
		}

		if (end) {
			url += `&end=${end}`;
		}

		if (state) {
			url += `&state=${state}`
		}

		if (serviceState === true) {
			url += `&serviceState=${true}`
		}
		if (serviceState === false) {
			url += `&serviceState=${false}`
		}


		let response = await this.client.get(url);
		return response.data;
	}

	async getAuditMeetings(currentPage, size, begin, end, key, state) {
		let url = `/v2/user/bookings/auditor?page=${currentPage}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		if (begin) {
			url += `&begin=${begin}`;
		}
		if (end) {
			url += `&end=${end}`;
		}
		if (state && state !== 'all') {
			url += `&state=${state}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async auditMeeting(bookingId) {
		let url = `/v2/bookings/${bookingId}/resolve`;
		let response = await this.client.post(url);
		return response.data;
	}

	async auditReject(bookingId, data) {
		let url = `/v2/bookings/${bookingId}/reject`;
		let response = await this.client.post(url, data);
		return response.data;
	}

	async getBookingsByServiceStaff(begin, end, key, page, size, state, serviceState) {
		let url = `/v2/user/bookings/serviceStaff?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (begin) {
			url += `&begin=${begin}`;
		}

		if (end) {
			url += `&end=${end}`;
		}
		if (state) {
			url += `&state=${state}`
		}

		if (serviceState === true) {
			url += `&serviceState=${true}`
		}
		if (serviceState === false) {
			url += `&serviceState=${false}`
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getBookingsbyScore(begin, end, key, page, size) {
		let url = `/v2/user/bookings/score?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (begin) {
			url += `&begin=${begin}`;
		}

		if (end) {
			url += `&end=${end}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async deleteVideoMeeting(id) {
		return await this.client.delete(`/v2/vcs/${id}`);
	}

	async getVideoMeeting(id) {
		let response = await this.client.get(`v2/vcs/${id}`);
		return response.data;
	}

	async getForbiddenTimesBySpaceId(spaceId) {
		let response = await this.client.get(`/v2/rules/spaceRule/${spaceId}`);
		return response.data;
	}

	// 调度员确认任务
	async dispatchMeeting(bookingid) {
		await this.client.post(`/v2/bookings/${bookingid}/dispatcherSure`);
	}

	// 服务人员执行完成
	async executeCompleteMeeting(bookingid, body) {
		await this.client.post(`/v2/bookings/${bookingid}/serviceStaffSure`, { ...body });
	}

	async getBuildingsByCompanyId(page, size, key, sort) {
		let url = `/v2/buildings?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (sort) {
			url += `&sort=${sort}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getBuildingsByCompanyId(page, size, key, sort) {
		let url = `/v2/buildings?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (sort) {
			url += `&sort=${sort}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async getRoomsByCompanyId({ page, size, key, buildingId, floor, deviceTag, capacity, category, allowVC, disable, forbidden, alarm, active, sort, permission }) {
		let url = `/v2/rooms?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}

		if (buildingId) {
			url += `&buildingId=${buildingId}`;
		}

		if (floor) {
			url += `&floor=${floor}`;
		}

		if (deviceTag) {
			url += `&deviceTag=${deviceTag}`;
		}

		if (capacity) {
			url += `&capacity=${capacity}`;
		}

		if (category) {
			url += `&category=${category}`;
		}

		if (allowVC) {
			url += `&allowVC=${allowVC}`;
		}

		if (disable && disable !== 'all') {
			url += `&disable=${disable}`;
		}

		if (forbidden) {
			url += `&forbidden=${forbidden}`;
		}

		if (alarm) {
			url += `&alarm=${alarm}`;
		}

		if (active) {
			url += `&active=${active}`;
		}

		if (sort) {
			url += `&sort=${sort}`;
		}

		if (permission) {
			url += `&permission=${permission}`
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async updateRoom(room) {
		return await this.client.patch(`/v2/rooms/${room.id}`, room);
	}

	async exportSpaces() {
		let response = await this.client.post(`/v2/rooms/export`);
		return response.data;
	}

	async updateNotice(notice) {
		return await this.client.patch(`/v2/notices/${notice.id}`, notice);
	}

	async fetchNotices({ page, size, user, type, key, begin, end, unread }) {
		let url = `/v2/notices?page=${page}&size=${size}`;
		if (type) {
			url += `&type=${type}`;
		}
		if (user) {
			url += `&user=${user}`;
		}
		if (key) {
			url += `&key=${key}`;
		}
		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}

		if (unread === 'true' || unread === 'false' || unread === true || unread === false) {
			url += `&unread=${unread}`;
		}

		let response = await this.client.get(url);
		return response.data;
	}

	async readAll(id) {
		return await this.client.patch(`/v2/notices/read/all?user=${id}`);
	}

	async readPages(idList) {
		return await this.client.patch(`/v2/notices/read/${idList}`);
	}

	async read(id, unread) {
		return await this.client.patch(`/v2/notices/${id}`, { unread });
	}

	async getMedia(page, size, key) {
		let url = `/v2/medias?page=${page}&size=${size}`;
		if (key) {
			url += `&key=${key}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

	async publishPost(deviceId, data) {
		return await this.client.post(`/v2/devices/${deviceId}/actions/publish`, data);
	}

	async rebootDevice(deviceId, action) {
		return await this.client.post(`/v2/devices/${deviceId}/actions/reboot`);
	}

	async publishPosts(data) {
		return await this.client.post(`/v2/signage/publish`, data);
	}

	async rebootDevices(data) {
		return await this.client.post(`/v2/signage/reboot`, data);
	}

	async getBookingStat(begin, end, type, group, unit, sort) {
		let url = `/v2/statistics/${type}/total?`;
		if (begin && end) {
			url += `&begin=${begin}&end=${end}`;
		}
		if (group) {
			url += `&group=${group}`;
		}
		if (unit) {
			url += `&unit=${unit}`;
		}
		if (sort) {
			url += `&sort=${sort}`;
		}
		let response = await this.client.get(url);
		return response.data;
	}

}

