import React, { useState, Component } from 'react';
import { TranslationOutlined, BellOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Col, Row, Card, Popover, Badge } from 'antd';
import { Switch, Route, useLocation, Link, Redirect } from 'react-router-dom';
import myAuditIcon from './icons/nav-icon-my-audit.svg';
import myBookingIcon from './icons/nav-icon-my-booking.svg';
import newBookingIcon from './icons/nav-icon-new-booking.svg';
import meetingMgtIcon from './icons/nav-icon-meeting-mgmt.svg';
import deviceIcon from './icons/nav-icon-device.svg';
import sReportIcon from './icons/nav-icon-system-report.svg';
import appIcon from './icons/clarity_applications-solid.svg';
import aboutIcon from './icons/nav-icon-about.svg';
import adeviceIcon from './icons/nav-icon-device-new.svg'
import bookingIcon from './icons/nav-icon-booking.svg'
import consoleIcon from './icons/nav-icon-admin-new.svg'
import 'moment/locale/zh-cn';
import { useTranslation } from 'react-i18next';
import CreateBooking from './meetings/CreateBooking';
import ViewBooking from './meetings/ViewBooking';
import EditBooking from './meetings/EditBooking';
import AppPermission from './AppPermission';
import NoticeCard from './components/NoticeCard';
import Notifications from './components/Notifications';
import VideoMeetingDetail from './meetings/VideoMeetingDetail';
import AppContextJS from './AppContext';
import { AppContext } from './Contexts';
import emitter from './emitter';

const logo = require('./images/s365logo.png');

const { SubMenu } = Menu;

function AppMenu(props) {

	const { t, i18n } = useTranslation();
	let location = useLocation();
	let menus = [
		{
			name: t('createBooking'),
			path: '/createBooking',
			icon: newBookingIcon,
			subMenu: [{
				name: t('bookSpace'),
				path: '/createBooking/local',
			},
			{
				name: t('bookVideoConference'),
				path: '/createBooking/video',
			}]
		},
		{
			name: t('myBookings'),
			path: '/my',
			icon: myBookingIcon,
			subMenu: [{
				name: t('mySpaceBooking'),
				path: '/myBooking/local',
			},
			{
				name: t('myVideoConferenceBooking'),
				path: '/myBooking/video',
			}]
		},
		{
			name: t('myAudit'),
			path: '/audit',
			icon: myAuditIcon,
		},
		{
			name: t('dispatch'),
			path: '/dispatch',
			icon: meetingMgtIcon,
		},
		{
			name: t('spaceConfig'),
			path: '/space',
			icon: deviceIcon,
		},
		// {
		// 	name: t('dashboard'),
		// 	path: '/dashboard',
		// 	icon: bookingIcon,
		// 	subMenu: [{
		// 		name: t('mediaManager'),
		// 		path: '/dashboard/media',
		// 	},
		// 	{
		// 		name: t('roomboardManager'),
		// 		path: '/dashboard/roomboard',
		// 	}
		// 	]
		// },
		{
			name: t('statistics'),
			path: '/statistics/meetingReport',
			icon: sReportIcon
		}
	];

	if (!props.me.permission || (!props.me.permission.admin && !props.me.permission.booking && props.me.permission.device)) {
		menus = _.filter(menus, r => r.path !== '/dispatch' && r.path !== '/space' && r.path !== '/statistics/meetingReport')
	}

	if (props.me.permission && !props.me.permission.booking && !props.me.permission.admin) {
		menus = _.filter(menus, r => r.path !== '/dispatch' && r.path !== '/space' && r.path !== '/statistics/meetingReport')
	}

	const [selectedKeys, setSelectedKeys] = useState('/createBooking');
	const [breadItems, setBreadItems] = useState([]);

	React.useEffect(() => {
		let routerList = [];
		_.each(menus, r => {
			_.each(r.subMenu, s => {
				if (_.includes(location.pathname, s.path) && !_.includes(_.map(routerList, 'name'), s.name)) {
					if (r.name && r.path && !_.includes(_.map(routerList, 'name'), r.name)) {
						routerList.push({ path: r.path, name: r.name });
					}
					routerList.push({ path: s.path, name: s.name });
				}
			});
		});

		let selectedKey = _.last(routerList);
		if (selectedKey) {
			setSelectedKeys(selectedKey.path)
		}

		if (!selectedKey && (location.pathname === '/about')) {
			setSelectedKeys('about')
		} else {
			if (location.pathname === '/') setSelectedKeys('/booking/local')
			else setSelectedKeys(location.pathname);
		}

		if (location.pathname === '/') {
			setSelectedKeys('/createBooking')
		}

		setBreadItems(routerList);
	}, [location]);

	let logoSrc = props.me && props.me.company && props.me.company.logo ?
		(
			_.includes(props.me.company.logo, 'http') ?
				props.me.company.logo : `${props.httpServiceEndpoint}/v2${props.me.company.logo}`
		) : logo;
	const content = (
		props.me.permission && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
			{
				(props.me.permission.device || props.me.permission.admin) &&
				<Card bordered={false} hoverable={true} onClick={() => { window.location.href = `${props.homePage}/app/device` }} >
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<img src={adeviceIcon} style={{ marginBottom: 8, width: 32, height: 32, cursor: 'pointer' }} />
						<span>{t('device')}</span>
					</div>
				</Card>

			}
			{
				props.me.permission.admin &&
				<Card bordered={false} hoverable={true} onClick={() => { window.location.href = `${props.homePage}/app/admin` }}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<img src={consoleIcon} style={{ marginBottom: 8, width: 32, height: 32, cursor: 'pointer' }} />
						<span>{t('admin')}</span>
					</div>
				</Card>
			}
		</div>
	);

	return (
		<div className="App">
			<div className='header'>
				{
					props.me.permission && (props.me.permission.device || props.me.permission.admin)  ? <Popover placement="bottomLeft" content={content} trigger="click">
						<img src={appIcon} style={{ margin: '32px 16px', cursor: 'pointer' }} />
					</Popover> : <img src={appIcon} style={{ margin: '32px 16px', cursor: 'pointer' }} />
				}

				<img src={logoSrc} style={{ width: 34, margin: 8 }} />
				{props.me.company.name}
				<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'center' }}>
					<Popover placement={'bottomRight'} title={<div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<h3>{t('notification')}</h3>
						<Link to='/notifications'><h3 style={{ color: '#0052cc' }}>{t('viewAllNotifications')}</h3></Link>
					</div>
					}
						content={<NoticeCard notices={props.notices} />}>
						<Badge count={props.noticeCount} style={{ marginRight: 10, cursor: 'pointer' }}>
							<BellOutlined style={{ fontSize: 26, alignItems: 'center', cursor: 'pointer' }} />
						</Badge>
					</Popover>

					<TranslationOutlined style={{ fontSize: 26, alignItems: 'center', marginLeft: 20 }}
						onClick={async () => {
							i18n.changeLanguage(t('targetLangCode'));
							props.changeLocale();
						}} />
					<Dropdown overlay={
						<Menu>
							<Menu.Item key="/about/profile" onClick={props.profile}>{t('changePassword')}</Menu.Item>
							<Menu.Item key="/about/logout" onClick={() => props.logout(location.pathname)}>{t('logout')}</Menu.Item>
						</Menu>
					} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div style={{ marginLeft: 20, fontSize: 16, marginRight: 20 }}>
							{props.me.name}
						</div>
					</Dropdown>
				</div>
			</div>
			<div style={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
				<div className='nav-wrapper'>
					<Menu
						mode="inline"
						theme="dark"
						onClick={({ key }) => { setSelectedKeys(key) }}
						inlineCollapsed="collapsed"
						style={{ width: 100, height: '100%', background: '#373948', overflow: 'auto' }}
					>
						{
							_.map(menus, menu => {
								if (menu.name && menu.subMenu) {
									if (!props.canExecute(menu.path)) return;
									return (
										<SubMenu
											style={{ display: props.canExecute(menu.path) ? 'flex' : 'none' }}
											key={menu.path}
											title={<><img src={menu.icon} alt={menu.name} />{menu.name}</>}
											className={`${selectedKeys.includes(menu.path) ? 'selecteable' : ''}`}
										>
											{
												_.map(menu.subMenu, subMenu => {
													return <Menu.Item
														key={subMenu.path}
														style={{
															display: props.canExecute(subMenu.path) ? 'flex' : 'none',
															marginTop: 0,
															marginBottom: 0,
															padding: '4px 16px',
															background: selectedKeys.includes(subMenu.path) ? '#2F54EB' : ''
														}}>
														<Link to={subMenu.path}>{subMenu.name}</Link>
													</Menu.Item>
												})
											}
										</SubMenu>
									);
								} else {
									return <Menu.Item
										key={menu.path}
										className={`menuItemParent ${selectedKeys === menu.path && 'selecteable'}`}
										title={menu.name}
										style={{
											display: props.canExecute(menu.path) ? 'flex' : 'none',
											marginBottom: 0
										}}
									>
										<img src={menu.icon} />
										<Link to={menu.path}>{menu.name}</Link>
									</Menu.Item>
								}
							})
						}
						<Menu.Item
							className={`menuItemParent ${selectedKeys === 'about' && 'selecteable'}`}
							style={{ display: 'flex' }}
							key="about"
							title={t('about')}
						>
							<img src={aboutIcon} />
							<Link to='/about'>{t('about')}</Link>
						</Menu.Item>
					</Menu>
				</div>
				<div className='content'>
					<Switch>
						<Route path="/local/booking/edit/:roomid/:bookingid/:start/:end/:flag">
							<EditBooking />
						</Route>
						<Route path="/local/booking/create/:roomid/:start/:end/:timelineMode">
							<CreateBooking />
						</Route>
						<Route path="/local/booking/:bookingId/view">
							<ViewBooking />
						</Route>
						<Route path='/notifications'>
							<Notifications reload={props.fetchNotices} />
						</Route>
						<Route path='/video/meeting/:meetingId/view'>
							<VideoMeetingDetail />
						</Route>
						{
							AppPermission.instance.intercept(location, props.me.permission)
						}
						<Route exact path="/" render={() => <Redirect to="/createBooking/local" />} />
					</Switch>
				</div>
			</div>
		</div>
	)
}

let hoc = (WrappedComponent) => {
	return class EnhancedComponent extends Component {
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				noticeCount: 0,
				notices: []
			}
		}

		componentDidMount = async () => {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;

			await this.fetchNotices();
			emitter.on('platform_changed', this.onNewEvent);
		}

		onNewEvent = async () => {
			await this.fetchNotices();
		}

		componentWillUnmount() {
			emitter.off('platform_changed', this.onNewEvent);
		}

		fetchNotices = async () => {
			let data = await this.transportLayer.fetchNotices({ page: 1, size: -1, user: this.me.id, type: 'platform', unread: true });
			this.setState({ noticeCount: data.totalDocs, notices: _.take(data.docs, 20) });
		}

		canExecute = (content) => {
			return AppPermission.instance.canExecute(this.props.me.permission, content);
		}

		render() {
			return <WrappedComponent
				{...this.props}
				notices={this.state.notices}
				noticeCount={this.state.noticeCount}
				canExecute={this.canExecute}
				fetchNotices={this.fetchNotices}
			/>
		}
	}
}

export default hoc(AppMenu);
