import _ from 'lodash';
import React, { Component } from 'react';
import { Modal, Input, Table, Pagination, Select } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import moment from 'moment';

const { Option } = Select;

function SelectMedia(props) {
	const { t } = useTranslation();
	const rowSelection = {
		type: props.selectType === 'muti' ? 'checkbox' : 'radio',
		selectedRowKeys: props.selectedRowKeys,
		onChange: (selectedRowKeys, selectedRows) => props.SelectMedia(selectedRowKeys, selectedRows)
	}

	return (
		<Modal
			centered
			destroyOnClose
			width={1280}
			title={t('selectMedia')}
			visible={props.visible}
			onCancel={props.onCancel}
			onOk={props.onConfirm}
			okText={t('confirm')}
			cancelText={t('cancel')}
		>
			<div >
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
					<Input.Search placeholder={t('searchByFileName')} style={{ width: '50%' }} onSearch={e => props.change(e)} />
					<Pagination
						current={props.page}
						pageSize={props.size}
						total={props.total}
						showTotal={total => t('itemWithCount', { count: total })}
						onChange={props.paginationChange}
					/>
				</div>
				<Table
					size='middle'
					tableLayout='fixed'
					className='userSelectTable'
					bordered
					rowKey={record => record.id}
					rowSelection={rowSelection}
					columns={[
						{ title: t('index'), dataIndex: 'index', key: 'index', width: 60, align: 'center', render: (e, record, index) => (index + 1) + (props.page - 1) * props.size },
						{
							title: t('fileName'), dataIndex: 'name', key: 'name', ellipsis: true
						},
						{
							title: t('type'), dataIndex: 'type', key: 'type', width: 50, ellipsis: true,
							render: e => e === 'video' ? t('video') : t('image')
						},
						{
							title: t('size'), dataIndex: 'size', key: 'size', width: 80, ellipsis: true,
							render: e => e / 1000 < 1000 ? Math.round(e / 1000) + 'KB' : e / 1000000 < 1000 ? Math.round(e / 1000000) + 'MB' : e
						},
						{
							title: t('lastUpdated'), dataIndex: 'updatedAt', key: 'updatedAt', width: 150,
							render: e => moment(e * 1000).format('YYYY-MM-DD HH:mm')
						}
					]}
					pagination={false}
					dataSource={props.persons}
				/>
			</div>
		</Modal>
	)
}

let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				selectedRowKeys: [],
				persons: [],
				searchText: '',
				page: 1,
				size: 10,
				total: 0,
				selectedUsers: []
			};
		}

		componentWillReceiveProps = async (nextProps) => {
			if (nextProps.selectedUser) {
				let users = _.filter(nextProps.selectedUser, u => u);
				this.setState({
					selectedRowKeys: _.map(nextProps.selectedUser, 'id'),
					selectedUsers: users
				})
				await this.getMedia(1, 10);

			}
		}

		componentWillMount = async () => {
			this.me = this.context.userContext.me;
			this.company = this.me.company;
			this.transportLayer = AppContextJS.instance.transportLayer;
			if (this.props.selectedUser) {
				let users = _.filter(this.props.selectedUser, u => u);
				this.setState({
					selectedRowKeys: _.map(this.props.selectedUser, 'id'),
					selectedUsers: users
				})
			}

			await this.getMedia(this.state.page, this.state.size);// this,props.role
		}

		getMedia = async (currentPage, pageSize, key) => {
			let data = await this.transportLayer.getMedia(currentPage, pageSize, key)
			let { page, totalDocs } = data;
			this.setState({ persons: data.docs, page, total: totalDocs });
		}

		SelectMedia = selectedRowKeys => {
			let ids = _.uniqBy(selectedRowKeys);
			let users = _.filter(this.state.selectedUsers, user => _.includes(ids, user.id));
			this.setState({
				selectedRowKeys: ids
			});

			let persons = _.filter(this.state.persons, person => _.includes(ids, person.id));
			_.each(persons, person => {
				if (!_.find(users, user => user.id === person.id)) {
					users.push(person);
				}
			})

			this.setState({ selectedUsers: users })
		}

		change = value => {
			this.setState({ searchText: value }, async () => {
				await this.getMedia(this.state.page, this.state.size, value);
			});
		}

		mutiOptionDataOnChange = (selectedRowKeys) => {
			let users = [];
			_.each(this.state.selectedUsers, user => {
				if (_.includes(selectedRowKeys, user.id)) {
					users.push(user);
				}
			})
			this.setState({ selectedUsers: users, selectedRowKeys });
		}

		onCancel = () => {
			this.setState({ selectedRowKeys: [] });
			this.props.onCancel()
		}

		onConfirm = () => {
			let users = [];
			_.each(this.state.selectedUsers, user => {
				if (!_.isArray(user)) {
					users.push(user);
				}
			})
			this.props.onConfirm(users);
			this.setState({
				selectedUsers: [],
				selectedRowKeys: []
			})
		}

		paginationChange = async (page, pageSize) => {
			await this.getMedia(page, pageSize, this.state.searchText)
		}

		render() {
			return <WrappedComponent
				{...this.props}
				page={this.state.page}
				size={this.state.size}
				total={this.state.total}
				selectedRowKeys={this.state.selectedRowKeys}
				selectedUsers={this.state.selectedUsers}
				change={value => this.change(value)}
				persons={this.state.persons}
				onCancel={this.onCancel}
				selectType={this.props.selectType}
				onConfirm={() => this.onConfirm()}
				paginationChange={this.paginationChange}
				mutiOptionDataOnChange={this.mutiOptionDataOnChange}
				SelectMedia={(selectedRowKeys, selectedRows) => this.SelectMedia(selectedRowKeys, selectedRows)}
			/>
		}
	})
}

export default hot(SelectMedia);