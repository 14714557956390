import React, { Component } from 'react';
import { DatePicker, Radio, Button, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next';

const { MonthPicker, WeekPicker } = DatePicker;

function DateTimePickerGroup(props) {
	const { t } = useTranslation();
	return (
		<div className='d-flex' style={{ justifyContent: 'flex-start', margin: '20px 0' }}>
			<Radio.Group
				onChange={e => props.onChangeRangeType(e.target.value)}
				value={props.rangeType}>
				<Radio.Button value="week">{t('week')}</Radio.Button>
				<Radio.Button value="month">{t('month')}</Radio.Button>
				<Radio.Button value="year">{t('year')}</Radio.Button>
			</Radio.Group>
			<Button style={{ marginLeft: 8 }} onClick={props.prevClick}><LeftOutlined /></Button>
			{
				props.rangeType === 'week' &&
				<WeekPicker disabledDate={props.disabledWeekDate} allowClear={false} style={{ marginLeft: 5 }} value={props.selectedWeek} onChange={props.onWeekChange} placeholder={t('selectWeek')} />
			}
			{
				props.rangeType === 'month' &&
				<MonthPicker disabledDate={props.disabledMonthDate} allowClear={false} style={{ marginLeft: 5 }} value={props.selectedMonth} onChange={props.onMonthChange} placeholder={t('selectMonth')} />
			}
			{
				props.rangeType === 'year' &&
				<DatePicker
					allowClear={false}
					style={{ marginLeft: 5 }}
					value={props.selectedYear}
					open={props.isopen}
					mode="year"
					placeholder={t('selectYear')}
					onOpenChange={props.setOpen}
					onPanelChange={props.onPanel}
					format={'YYYY '+t('year')}
				/>
			}
			<Button style={{ marginLeft: 8 }} onClick={props.nextClick}><RightOutlined /></Button>
		</div>
	);
}

let hoc = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		constructor(props) {
			super(props);
			this.state = {
				rangeType: this.props.rangeType ? this.props.rangeType : 'month',
				selectedWeek: this.props.defaultWeek ? this.props.defaultWeek : moment(),
				selectedMonth: this.props.defaultMonth ? this.props.defaultMonth : moment(),
				selectedYear: this.props.defaultYear ? this.props.defaultYear : moment(),
				isopen: false
			}
		}

		componentDidMount() {
			this.onChange();
		}

		onChangeRangeType = (rangeType) => {
			this.setState({ rangeType }, () => {
				this.onChange();
			})
		}

		onWeekChange = (e) => {
			this.setState({ selectedWeek: e }, () => {
				this.onChange();
			})
		}

		onMonthChange = (e) => {
			this.setState({ selectedMonth: e }, () => {
				this.onChange();
			})
		}

		setOpen = (isopen) => {
			this.setState({ isopen })
		}

		onPanel = (e) => {
			if (moment(e) > moment()) {
				message.info(this.t('outOfRange'));
				return;
			}

			this.setState({ isopen: false, selectedYear: e }, () => {
				this.onChange();
			})
		}

		prevClick = () => {
			let { selectedWeek, selectedMonth, selectedYear } = this.state;

			switch (this.state.rangeType) {
				case 'week':
					selectedWeek = moment(selectedWeek).add(-1, 'week');
					break;
				case 'month':
					selectedMonth = moment(selectedMonth).add(-1, 'month');
					break;
				case 'year':
					selectedYear = moment(selectedYear).add(-1, 'year');
					break;
				default:
					break;
			}

			if (moment(selectedWeek) > moment() || moment(selectedMonth) > moment() || moment(selectedYear) > moment()) {
				message.info(this.t('outOfRange'));
				return;
			}

			this.setState({ selectedWeek, selectedMonth, selectedYear }, () => {
				this.onChange()
			})
		}

		nextClick = () => {
			let { selectedWeek, selectedMonth, selectedYear } = this.state;

			switch (this.state.rangeType) {
				case 'week':
					selectedWeek = moment(selectedWeek).add(1, 'week');
					break;
				case 'month':
					selectedMonth = moment(selectedMonth).add(1, 'month');
					break;
				case 'year':
					selectedYear = moment(selectedYear).add(1, 'year');
					break;
				default:
					break;
			}

			if (moment(selectedWeek) > moment() || moment(selectedMonth) > moment() || moment(selectedYear) > moment()) {
				message.info(this.t('outOfRange'));
				return;
			}

			this.setState({ selectedWeek, selectedMonth, selectedYear }, () => {
				this.onChange()
			})
		}

		onChange = () => {
			const { selectedWeek, selectedMonth, selectedYear, rangeType } = this.state;
			let data = {
				week: moment(selectedWeek),
				month: moment(selectedMonth),
				year: moment(selectedYear),
				type: rangeType
			}

			this.props.onChange(data);
		}

		disabledWeekDate = (current) => {
			return current && current > moment();
		}

		disabledMonthDate = (current) => {
			return current && current >= moment();
		}

		render() {
			return <WrappedComponent
				onMonthChange={this.onMonthChange}
				onWeekChange={this.onWeekChange}
				onChangeRangeType={this.onChangeRangeType}
				rangeType={this.state.rangeType}
				selectedYear={this.state.selectedYear}
				selectedMonth={this.state.selectedMonth}
				selectedWeek={this.state.selectedWeek}
				isopen={this.state.isopen}
				disabledMonthDate={this.disabledMonthDate}
				disabledWeekDate={this.disabledWeekDate}
				setOpen={this.setOpen}
				onPanel={this.onPanel}
				prevClick={this.prevClick}
				nextClick={this.nextClick}
			/>
		}
	})
}

export default hoc(DateTimePickerGroup);

