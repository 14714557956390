import _ from 'lodash';
import Debug from 'debug';
import Cookies from 'js-cookie';
import emitter from '../emitter';
import { Link } from 'react-router-dom';
import { AppContext } from '../Contexts';
import AppContextJS from '../AppContext';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import React, { Component, useCallback, useState } from 'react';
import { Badge, Tree, Tabs, Popover, Input, Select, Pagination, Table, Tag, Typography, message, PageHeader, Button, Menu, Dropdown, Tooltip, Form, Modal } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, CaretDownOutlined, FlagOutlined, HomeOutlined, MoreOutlined, UploadOutlined, RetweetOutlined, ReloadOutlined } from '@ant-design/icons';
import { formatBuildings } from '../share/Helper';
import SelectMedia from '../components/SelectMedia';

const { Search } = Input;
const Option = Select.Option;
const debug = Debug('app');
const TreeNode = Tree.TreeNode;
const TabPane = Tabs.TabPane;
const { Text } = Typography;
const FormItem = Form.Item;
const { TextArea } = Input;

function RoomboardManager(props) {
	if (!props.me) return <></>;

	const { t } = useTranslation();
	let location = useLocation();

	Cookies.set('location', location.pathname, { expires: 1 });
	const [height, setHeight] = useState(0);
	const measuredRef = useCallback(node => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);

	const rowSelection = {
		type: 'checkbox',
		selectedRowKeys: props.selectedDevices,
		onChange: (selectedRowKeys, selectedRows) => props.selectDevices(selectedRowKeys, selectedRows),
	}


	let mediaChecked = (record) => {
		let mediaChcked = false;
		let marqueeChecked = false;
		if ((!record.playlist || (!record.playlist.media && !record.playlist.marquee)) && (!record.states || (!record.states.media && !record.states.marquee))) {
			return true;
		}
		mediaChcked = (record.playlist && !record.playlist.media && record.states && !record.states.media) || (record.playlist && record.playlist.media && record.states && record.states.media && record.playlist.media.url == record.states.media);
		marqueeChecked = (record.playlist && !record.playlist.marquee && record.states && !record.states.marquee) || (record.playlist && record.states && record.playlist.marquee == record.states.marquee);
		return mediaChcked && marqueeChecked;
	}

	let columns = [
		{ title: t('roomboardName'), dataIndex: 'name', key: 'name', align: 'left', ellipsis: true, width: 200 },
		{ title: t('location'), dataIndex: 'room', key: 'room', align: 'center', ellipsis: true, width: 200, render: e => e && e.building && e.floor && e.name ? e.building.name + '-' + e.floor + '-' + e.name : '' },
		{
			title: t('onlineStat'), dataIndex: 'online', key: 'online', align: 'center', width: 100,
			render: (e, record) => { return e ? <CheckCircleFilled style={{ color: '#53B28A', cursor: 'pointer', fontSize: 16 }} /> : <CloseCircleFilled style={{ color: '#D4380D', cursor: 'pointer', fontSize: 16 }} />; }
		},
		{
			title: t('ScreenStat'), dataIndex: 'states', key: 'states', ellipsis: true, align: 'center', width: 100,
			render: (e, record) => {
				return (
					<div>
						<Tooltip title={<div>
							<p>{t('currentPlaying')}{e && e.media ? e.media : ''}</p>
							<p>{t('currentSubtitle')}{e && e.marquee ? e.marquee : ''}</p>
						</div>}>
							{mediaChecked(record) ? <CheckCircleFilled style={{ color: '#53B28A', cursor: 'pointer', fontSize: 16 }} /> : <CloseCircleFilled style={{ color: '#D4380D', cursor: 'pointer', fontSize: 16 }} />}
						</Tooltip>
					</div>);
			}
		},
		{ title: t('mediaName'), dataIndex: 'playlist.media.name', key: 'playlist.media', ellipsis: true, align: 'center', render: (e) => <Tooltip title={e}>{e}</Tooltip> },
		{ title: t('subtitle'), dataIndex: 'playlist.marquee', key: 'playlist.marquee', ellipsis: true, align: 'center', render: (e) => <Tooltip title={e}>{e}</Tooltip> },
		{
			title: t('actions'), key: 'action', width: 80,
			render: (e, record) => {
				const menus = (
					<Menu>
						<Menu.Item>
							<a onClick={() => props.showMediaModal('single', record)}>{t('publishPost')}</a>
						</Menu.Item>
						<Menu.Item>
							<a onClick={() => props.unpublishPost(record.id)}>{t('unpublishPost')}</a>
						</Menu.Item>
						<Menu.Item>
							<a onClick={() => props.showSubtitleDialog('single', record)}>{t('publishSubtitle')}</a>
						</Menu.Item>
						<Menu.Item>
							<a onClick={() => props.unpublishSubtitle(record.id)}>{t('unpublishSubtitle')}</a>
						</Menu.Item>
						<Menu.Item>
							<a onClick={() => props.rebootDevice(record.id)}>{t('rebootDevice')}</a>
						</Menu.Item>
					</Menu>
				);
				return <Dropdown overlay={menus} placement="bottomLeft">
					<MoreOutlined />
				</Dropdown>
			},
		},
	];

	let columnsTatalWidth = _.sum(_.map(columns, 'width'));
	const menuPub = (
		<Menu>
			<Menu.Item>
				<a onClick={() => props.showMediaModal('multiple')}>{t('publishPost')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={() => props.showSubtitleDialog('multiple')}>{t('publishSubtitle')}</a>
			</Menu.Item>
		</Menu>
	);
	const menuCancel = (
		<Menu>
			<Menu.Item>
				<a onClick={() => props.unpublishPosts()}>{t('unpublishPost')}</a>
			</Menu.Item>
			<Menu.Item>
				<a onClick={() => props.unpublishSubtitles()}>{t('unpublishSubtitle')}</a>
			</Menu.Item>
		</Menu>
	);
	return (
		<div className='container' style={{ background: '#FFF', overflow: 'hidden' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('roomboardManager')}
				extra={[
					<Button type='primary' onClick={() => props.rebootDevices()}><RetweetOutlined />{t('bulkReboot')}</Button>,
					<Dropdown overlay={menuPub} placement="bottomCenter">
						<Button type='primary'><UploadOutlined />{t('bulkPublish')}</Button>
					</Dropdown>,
					<Dropdown overlay={menuCancel} placement="bottomCenter">
						<Button type='primary'><ReloadOutlined />{t('bulkCancel')}</Button>
					</Dropdown>,
				]}
			>
			</PageHeader>
			<div style={{
				height: '100%', background: '#FBFAFA', overflow: 'hidden', display: 'flex', boxShadow: '0px 0px 8px rgba(221, 229, 234, 0.5)'
			}}>
				<div role='sidebar-tree' style={{ background: '#FBFAFA', flex: '0 0 312px', padding: '24px 4px 44px 16px', borderRight: '1px solid #E3E9EE' }}>
					<div className='d-flex' style={{ marginBottom: 8 }}>
						<Search style={{ marginRight: 8 }} placeholder={t('searchSpacePlaceholder')}
							onSearch={value => props.searchBuildingChange(value)} />
					</div>
					<Scrollbars style={{ height: height - 150 }}>
						<Tree
							autoExpandParent={props.autoExpandParent}
							switcherIcon={<CaretDownOutlined />}
							selectedKeys={props.selectedSpace && [props.selectedSpace.code]}
							onSelect={(selectedKeys, e) => props.onSelect(e)}
							expandedKeys={props.expandedSpaceKeys}
							onExpand={(e) => props.onExpand(e)}>
							{props.renderTreeNodes(props.spaces)}
						</Tree>
					</Scrollbars>
				</div>
				<div ref={measuredRef} id='detail-wrapper' style={{ height: '100%', background: '#ffffff', padding: 24, flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} >
						<div style={{ marginBottom: 16 }}>
							<Search
								placeholder={t('searchDevice')}
								style={{ width: 240, marginRight: 8, marginBottom: 8 }}
								onSearch={value => props.deviceNameOrIdOnSearch(value)}
							/>
							<Select value={props.selectedOnline} style={{ minWidth: 120, marginRight: 8 }} onChange={value => props.onlineOnChange(value)}>
								<Option value="all">{t('allNetStats')}</Option>
								<Option value={true}>{t('online')}</Option>
								<Option value={false}>{t('offline')}</Option>
							</Select>
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: '2', marginBottom: 16 }}>
							<Select value={props.size} style={{ minWidth: 50, marginRight: 8 }} onChange={value => props.onPageSizeChange(value)}>
								<Option value={10}>{t('sizeChangerSelector', { size: 10 })}</Option>
								<Option value={20}>{t('sizeChangerSelector', { size: 20 })}</Option>
								<Option value={50}>{t('sizeChangerSelector', { size: 50 })}</Option>
							</Select>
							<Pagination
								current={props.deviceCurrent}
								pageSize={props.size}
								showTotal={total => t('itemWithCount', { count: total })}
								total={props.deviceTotal}
								onChange={props.fetchDevices}
								onShowSizeChange={props.onShowSizeChange}
							/>
						</div>
					</div>
					<div>
						<Table size='middle'
							bordered={true}
							pagination={false}
							rowKey={record => record.id}
							rowSelection={rowSelection}
							dataSource={props.devices}
							scroll={{ x: columnsTatalWidth + 200, y: height - 210 }}
							columns={columns} />
					</div>
				</div>
			</div>
			<SelectMedia
				mediaMode={props.mediaMode}
				selectedMedia={props.selectedMedia}
				visible={props.selectMediaVisible}
				onCancel={props.onSelectMediaCancel}
				onConfirm={props.onSelectMediaConfirm}
			/>
			<EditSubtitleWrapper
				title={t('subtitle')}
				mode={props.subMode}
				visible={props.subtitleDialog}
				save={props.onSaveSubtitle}
				onCancel={props.cancelSubtitleDialog}
				target={props.selectedDevice}
				uploadProps={props.uploadProps}
			/>
		</div>
	);
}

const EditSubtitleWrapper = withTranslation()(Form.create()(
	class extends Component {
		get t() { return this.props.t; }

		constructor(props) {
			super(props);
			this.state = {
				url: '',
				subtitleList: [],
				size: 0,
				subtitleDialog: false,
				subtitleName: ''
			};
		}

		componentWillReceiveProps(nextProps) {
			if (nextProps.target && nextProps.target !== this.props.target) {
				this.setState({
					subtitleList: [{
						uid: nextProps.target.url,
						name: nextProps.target.url,
						url: nextProps.target.url,
						status: 'done'
					}],
					url: nextProps.target.url,
					size: nextProps.target.size
				});
			}
		}

		async componentDidMount() {
			this.httpService = AppContextJS.instance.httpService;
			this.token = AppContextJS.instance.token;
		}

		handleChange = ({ subtitle, subtitleList }) => {
			let url = '', size = 0;
			if (subtitle.status === 'done') {
				url = subtitle.response.path;
				size = subtitle.response.size;
			}
			if (subtitle.status === 'removed') {
				url = '';
			}
			this.setState({ size, url, subtitleList: subtitleList.length > 0 ? [_.last(subtitleList)] : [] });
		}

		save = e => {
			e.preventDefault();
			const { form } = this.props;
			form.validateFields(async (err, values) => {
				if (err) return;
				this.props.save(values);
				this.cancel();
			});
		}

		cancel = () => {
			this.setState({ subtitleList: [], size: 0, url: '' });
			this.props.form.resetFields();
			this.props.onCancel(false);
		}

		render() {
			const { visible, title, target, onCancel, edit, form, t } = this.props;
			const { getFieldDecorator } = form;
			return (
				<Modal centered
					okText={this.t('confirm')}
					cancelText={this.t('cancel')}
					width="597px"
					title={title}
					visible={visible}
					onOk={this.save}
					onCancel={this.cancel}
				>
					<Form>
						{target &&
							<Form.Item style={{ display: 'none' }}>
								{getFieldDecorator('id', { initialValue: target.id })(<Input />)}
							</Form.Item>
						}
						<FormItem label={t('subtitle')} >
							{getFieldDecorator('subtitle', {
								rules: [{ required: true, message: t('enterSubtitleNameMessage') }],
								initialValue: target && target.playlist ? target.playlist.marquee:null
							})(
								<TextArea maxlength="50"/>
							)}
						</FormItem>
					</Form>
				</Modal>
			);
		}
	}
));


let hoc = (WrappedComponent) => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;

		constructor(props) {
			super(props);
			this.state = {
				allRooms: [],
				selectedSpace: null,
				expandedSpaceKeys: [],
				spaces: [],
				page: 1,
				size: 20,
				devices: null,
				deviceCurrent: 0,
				deviceTotal: 0,
				isDevice: true,
				treeRooms: [],
				autoExpandParent: true,
				selectedDevice: null,
				selectedDevices: [],
				selectedMedia: null,
				selectMediaVisible: false,
				subtitleDialog: false,
				selectedDialog: null,
				mediaMode: null,
				subMode: null
			};
			this.query = '';
			this.selectedDeviceType = 'all';
			this.selectedOnline = 'all';
			this.selectedActive = 'all';
			this.selectedAlarm = 'all';
			this.searchBuildingText = '';
			this.dataList = [];
		}

		async componentDidMount() {
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;

			await this.getRoomsByPage(null, null, 1);
			await this.fetchDevices(1);

			emitter.on('event_changed', this.onNewEvent);

			if (this.state.spaces) {
				this.generateList(this.state.spaces);
			}
		}

		componentWillUnmount() {
			emitter.off('event_changed', this.onNewEvent);
		}

		getRoomsByPage = async (buildingId, floorName, page) => {
			if (!this.me || !this.me.permission) return;

			let permission = '';
			if (this.me.permission.booking) {
				permission = 'booking'
			}

			let rooms = await this.transportLayer.getRoomsByCompanyId({ page, size: -1, buildingId, floor: floorName, disable: this.roomDisable, permission });
			if (rooms) {
				this.setState({ roomCount: rooms.totalDocs, rooms: rooms.docs, roomPage: rooms.page })
				this.formatBuildings(rooms.docs)
			}
		}

		formatBuildings = (rooms) => {
			let { expandedSpaceKeys, spaces } = formatBuildings(rooms);
			this.setState({ expandedSpaceKeys, spaces });

			this.generateList(rooms);
		}

		onNewEvent = async (events) => {
			let changedIds = _.intersection(_.map(this.state.devices, 'id'), _.map(events, 'device'));
			if (changedIds.length > 0) {
				let devices = [];

				_.map(this.state.devices, device => {
					let newEvent = _.find(events, event => event.device === device.id);
					if (newEvent) {
						device.online = newEvent.body.online;
						device.states = newEvent.body.states;
					}
					devices.push(device);
				})

				this.setState({ devices });
			}
		}

		onSelect = async (e) => {
			let selected = '';
			let building = '';
			let floor = '';
			let room = '';

			if (e.selected) {
				selected = e.node.props.dataRef;
				building = _.last(selected.code.split('-'));
				if (selected.hasRoom) {
					floor = selected.name;
				}
			}

			if (selected.floor) {
				room = selected.id;
				floor = '';
				building = '';
			}

			this.setState({ selectSpace: selected, building, floor, room }, async () => {
				await this.fetchDevices(1);
			});
		}


		onExpand(expandedSpaceKeys) {
			this.setState({ expandedSpaceKeys, autoExpandParent: false });
		}

		renderTreeNodes(node) {
			if (node) {
				return (
					_.map(node, x => {
						{ return this.renderTrees(x); }
					})
				);
			}
		}

		renderTrees(node) {
			if (node) {
				// let deviceCount = '';
				// if (node.deviceCount && node.deviceCount !== 0) {
				// 	deviceCount = ` (${node.deviceCount})`;
				// }

				const index = node.name.indexOf(this.searchBuildingText);
				const beforeStr = node.name.substr(0, index);
				const afterStr = node.name.substr(index + this.searchBuildingText.length);

				const title =
					index > -1 ? (
						<span>
							{node.building ? <HomeOutlined style={{ marginRight: 8 }} /> : <FlagOutlined style={{ marginRight: 8 }} />}
							{beforeStr}
							<span style={{ color: 'red' }}>{this.searchBuildingText}</span>
							{afterStr}
						</span>
					) : (
						<span>{node.building ? <HomeOutlined style={{ marginRight: 8 }} /> : <FlagOutlined style={{ marginRight: 8 }} />}{node.name}</span>
					);

				return (
					<TreeNode
						selectable={_.includes(node.code, 'building-') || _.includes(node.code, 'floor-') || !_.isEmpty(node.id)}
						isLeaf={node.building ? true : false}
						title={
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								{node.building ?
									<>
										<span>{
											// _.isNumber(node.deviceCount) ?
											// (node.deviceCount === 0 ? title : <>{title}({node.deviceCount})</>)
											// :
											title}
										</span>
									</>
									: <span>{title} </span>}
							</div>
						}
						key={node.code} dataRef={node} >
						{
							node.children && node.children.length > 0 &&
							_.map(node.children, x => { return this.renderTrees(x); })
						}
					</TreeNode >
				);
			}
		}

		async fetchDevices(page) {
			let data = await this.transportLayer.getDevicesByRoomboard({ key: this.query, online: this.selectedOnline, page: this.state.page, size: this.state.size, building: this.state.building, floor: this.state.floor, room: this.state.room, deviceType: 'Roomboard' });
			let devices = data.docs;


			this.setState({ devices, deviceCurrent: data.page, deviceTotal: data.totalDocs });
		}

		async deviceNameOrIdOnSearch(value) {
			this.query = value;
			await this.fetchDevices(1);
		}

		async onlineOnChange(e) {
			this.selectedOnline = e;
			await this.fetchDevices(1);
		}

		searchBuildingChange = value => {
			const expandedKeys = this.dataList
				.map(item => {
					if (item.name.indexOf(value) > -1) {
						return this.getParentKey(item.code, this.state.spaces);
					}
					return null;
				})
				.filter((item, i, self) => item && self.indexOf(item) === i);
			this.setState({
				expandedSpaceKeys: expandedKeys,
				autoExpandParent: true,
			});

			this.searchBuildingText = value;
		}

		getParentKey = (key, tree) => {
			let parentKey;
			for (let i = 0; i < tree.length; i++) {
				const node = tree[i];
				if (node.children) {
					if (node.children.some(item => item.code === key)) {
						parentKey = node.code;
					} else if (this.getParentKey(key, node.children)) {
						parentKey = this.getParentKey(key, node.children);
					}
				}
			}

			return parentKey;
		}

		generateList = data => {
			for (let i = 0; i < data.length; i++) {
				const node = data[i];
				if (!_.find(this.dataList, d => d.name === node.name && d.code === node.code)) {
					this.dataList.push({ name: node.name, code: node.code });
					if (node.children) {
						this.generateList(node.children);
					}
				}
			}
		};

		onShowSizeChange = (current, pageSize) => {
			this.setState({ size: pageSize }, async () => {
				await this.fetchDevices(1)
			})
		}

		showMediaModal = (mode, device) => {
			console.log('input mode', mode)
			this.setState({ mediaMode: mode, selectedDevice: device, selectMediaVisible: true })
		}

		onSelectMediaCancel = () => {
			this.setState({ mediaMode: null, selectedDevice: null, selectMediaVisible: false })
		}

		onSelectMediaConfirm = async (media) => {
			let selectedMedia = _.first(media);
			if (this.state.mediaMode === 'single') {
				// let data = {
				// 	"arg": selectedMedia.id
				// }
				// await this.transportLayer.deviceAction(this.state.selectedDevice.id, 'publish', data);

				let data = {
					signage: [
						{
							deviceId: this.state.selectedDevice.id,
							playlist: {
								media: selectedMedia.id
							},
						}
					]
				}
				this.transportLayer.publishPosts(data).then(() => {
					message.success(this.t('postPublished'));
				}, error => {
					message.error(this.t('postPublishFailed'));
				});
			} else if (this.state.mediaMode === 'multiple') {
				let data = {
					signage: _.map(this.state.selectedDevices, x => {
						return {
							deviceId: x,
							playlist: {
								media: selectedMedia.id
							},
						}
					})
				}

				this.transportLayer.publishPosts(data).then(() => {
					message.success(this.t('postPublished'));
				}, error => {
					message.error(this.t('postPublishFailed'));
				});
			}
			this.setState({ mediaMode: null, selectedDevice: null, selectMediaVisible: false })
			this.fetchDevices();
		}

		selectDevices = selectedRowKeys => {
			this.setState({ selectedDevices: selectedRowKeys })
		}

		showSubtitleDialog(mode, device) {
			this.setState({ subMode: mode, selectedDevice: device, subtitleDialog: true });
		}


		cancelSubtitleDialog() {
			this.setState({ subMode: null, selectedDevice: null, subtitleDialog: false });
		}

		onSaveSubtitle = async (values) => {
			if (this.state.subMode === 'single') {
				let data = {
					signage: [
						{
							deviceId: this.state.selectedDevice.id,
							playlist: {
								marquee: values.subtitle
							},
						}
					]
				}
				this.transportLayer.publishPosts(data).then(() => {
					message.success(this.t('subtitleUpdated'));
				}, error => {
					message.error(this.t('subtitlePublishFailed'));
				});
			} else if (this.state.subMode === 'multiple') {
				let data = {
					signage: _.map(this.state.selectedDevices, x => {
						return {
							deviceId: x,
							playlist: {
								marquee: values.subtitle
							},
						}
					})
				}
				this.transportLayer.publishPosts(data).then(() => {
					message.success(this.t('subtitleUpdated'));
				}, error => {
					message.error(this.t('subtitlePublishFailed'));
				});
			}
			this.fetchDevices();
		}

		unpublishPost = async (id) => {
			let data = {
				signage: [
					{
						deviceId: id,
						playlist: {
							media: null
						},
					}
				]
			}
			this.transportLayer.publishPosts(data).then(() => {
				message.success(this.t('postUnpublished'));
			}, error => {
				message.error(this.t('postUnpublisheFailed'));
			});
			await this.fetchDevices();
		}

		unpublishSubtitle = async (id) => {
			let data = {
				signage: [
					{
						deviceId: id,
						playlist: {
							marquee: null
						},
					}
				]
			}
			this.transportLayer.publishPosts(data).then(() => {
				message.success(this.t('subtitleCanceled'));
			}, error => {
				message.error(this.t('subtitleCancelFailed'));
			});
			await this.fetchDevices();
		}

		rebootDevice = async (id) => {
			let data = {
				deviceId: id
			}
			await this.transportLayer.rebootDevices(data).then(() => {
				message.success(this.t('deviceRebooted'));
			}, error => {
				message.error(this.t('deviceRebootFailed'));
			});
			await this.fetchDevices();
		}


		unpublishPosts = async () => {
			let data = {
				signage: _.map(this.state.selectedDevices, x => {
					return {
						deviceId: x,
						playlist: {
							media: null
						},
					}
				})
			}
			this.transportLayer.publishPosts(data).then(() => {
				message.success(this.t('postUnpublished'));
			}, error => {
				message.error(this.t('postUnpublisheFailed'));
			});
			await this.fetchDevices();
		}

		publishSubtitles = async () => {
			let data = {
				signage: _.map(this.state.selectedDevices, x => {
					return {
						deviceId: x,
						playlist: {
							marquee: null
						},
					}
				})
			}
			await this.transportLayer.publishPosts(data).then(() => {
				message.success(this.t('subtitleUpdated'));
			}, error => {
				message.error(this.t('subtitlePublishFailed'));
			});
			await this.fetchDevices();
		}

		unpublishSubtitles = async () => {
			let data = {
				signage: _.map(this.state.selectedDevices, x => {
					return {
						deviceId: x,
						playlist: {
							marquee: null
						},
					}
				})
			}
			this.transportLayer.publishPosts(data).then(() => {
				message.success(this.t('subtitleCanceled'));
			}, error => {
				message.error(this.t('subtitleCancelFailed'));
			});
			await this.fetchDevices();
		}

		rebootDevices = async () => {
			let data = {
				deviceId: this.state.selectedDevices
			}
			this.transportLayer.rebootDevices(data).then(() => {
				message.success(this.t('deviceRebooted'));
			}, error => {
				message.error(this.t('deviceRebootFailed'));
			});
			await this.fetchDevices();
		}

		onPageSizeChange = async (value) => {
			await this.setState({ size: value });
			await this.fetchDevices(1);
		}

		render() {
			return <WrappedComponent
				me={this.me}
				selectedSpace={this.state.selectSpace}
				spaces={this.state.spaces}
				renderTreeNodes={(space) => this.renderTreeNodes(space)}
				onSelect={(space) => this.onSelect(space)}
				onRadioChange={(e) => this.onRadioChange(e)}
				onExpand={(e) => this.onExpand(e)}
				expandedSpaceKeys={this.state.expandedSpaceKeys}

				size={this.state.size}
				fetchDevices={(page) => this.fetchDevices(page)}
				deviceCurrent={this.state.deviceCurrent}
				deviceTotal={this.state.deviceTotal}
				devices={this.state.devices}

				selectedDeviceType={this.selectedDeviceType}
				selectedOnline={this.selectedOnline}
				selectedActive={this.selectedActive}
				selectedAlarm={this.selectedAlarm}
				query={this.query}
				searchBuildingChange={this.searchBuildingChange}
				searchBuildingText={this.searchBuildingText}

				deviceNameOrIdOnSearch={(value) => this.deviceNameOrIdOnSearch(value)}
				onlineOnChange={(value) => this.onlineOnChange(value)}
				autoExpandParent={this.state.autoExpandParent}

				onShowSizeChange={(current, pageSize) => this.onShowSizeChange(current, pageSize)}
				selectedDevice={this.state.selectedDevice}
				selectedDevices={this.state.selectedDevices}
				selectDevices={(selectedRowKeys) => this.selectDevices(selectedRowKeys)}
				onPageSizeChange={(value) => this.onPageSizeChange(value)}

				showMediaModal={(mode, device) => this.showMediaModal(mode, device)}
				selectedMedia={this.state.selectedMedia}
				selectMediaVisible={this.state.selectMediaVisible}
				onSelectMediaCancel={() => this.onSelectMediaCancel()}
				onSelectMediaConfirm={(media) => this.onSelectMediaConfirm(media)}

				subtitleDialog={this.state.subtitleDialog}
				showSubtitleDialog={(visible, subtitle) => this.showSubtitleDialog(visible, subtitle)}
				cancelSubtitleDialog={() => this.cancelSubtitleDialog()}
				onSaveSubtitle={values => this.onSaveSubtitle(values)}
				selectedDialog={this.state.selectedDialog}

				unpublishPost={(id) => this.unpublishPost(id)}
				unpublishSubtitle={(id) => this.unpublishSubtitle(id)}
				rebootDevice={(id) => this.rebootDevice(id)}
				unpublishPosts={() => this.unpublishPosts()}
				publishSubtitles={() => this.publishSubtitles()}
				unpublishSubtitles={() => this.unpublishSubtitles()}
				rebootDevices={() => this.rebootDevices()}
				subMode={this.state.subMode}
				mediaMode={this.state.mediaMode}
			/>;
		}
	});
};

export default withRouter(hoc(RoomboardManager));
