import _ from 'lodash';
import React from 'react';
import { Route } from "react-router-dom";
import Bookings from './meetings/Bookings';
import MyBookings from './meetings/MyBookings';
import VideoMeeting from './meetings/VideoMeeting';
import CreateVideoMeeting from './meetings/CreateVideoMeeting';
import VideoMeetingDetail from './meetings/VideoMeetingDetail';
import About from './About';
import MeetingSchedules from './meetings/MeetingSchedules';
import EditMeetingSchedule from './meetings/EditMeetingSchedule';
import Auditor from './settings/Auditor';
import MeetingReport from './meetings/Reports/MeetingReport';
import NoAccess from './NoAccess';
import SpaceConfig from './meetings/Spaces';
import MediaManager from './dashboard/MediaManager';
import RoomboardManager from './dashboard/RoomboardManager';

const routerMap = [
	{
		path: "/operation/schedule/:type/:roomid/:start/:end",
		component: EditMeetingSchedule
	},
	{
		path: "/operation/meeting/schedule",
		component: MeetingSchedules
	},
	{
		path: "/video/meeting/:meetingId/view",
		component: VideoMeetingDetail
	},
	{
		path: "/video/meeting/:type/:id/:start/:end",
		component: CreateVideoMeeting
	},
	{
		path: "/video/meeting/create",
		component: CreateVideoMeeting
	},
	{
		path: "/myBooking/local",
		component: MyBookings
	},
	{
		path: "/myBooking/video",
		component: VideoMeeting
	},
	{
		path: "/createBooking/local",
		component: Bookings
	},
	{
		path: "/createBooking/video",
		component: CreateVideoMeeting
	},
	{
		path: "/space",
		component: SpaceConfig
	},
	{
		path: "/audit",
		component: Auditor
	},
	{
		path: "/dispatch",
		component: MeetingSchedules
	},
	{
		path: "/dashboard/media",
		component: MediaManager
	},
	{
		path: "/dashboard/roomboard",
		component: RoomboardManager
	},
	{
		path: "/statistics/meetingReport",
		component: MeetingReport
	},
	{
		path: "/noAccess",
		component: NoAccess
	},
	{
		path: "/about",
		component: About
	}
]

class AppPermission {
	static get instance() {
		if (!this._instance) {
			this._instance = new AppPermission();
		}
		return this._instance;
	}

	constructor() {
		if (AppPermission._instance) {
			return AppPermission._instance;
		}

		this.init();
	}

	init() {
		this.rules = [
			'/dispatch',
			'/space',
			'/dashboard', '/dashboard/media', '/dashboard/roomboard',
			'/statistics', '/statistics/meetingReport', '/statistics/meetingRatingReport', '/statistics/roomRatingReport',
		];
	}

	canExecute = (permission, content) => {
		if (content === '/about') return true;
		let index = _.indexOf(this.rules, content);
		return index === -1 ? true : (permission ? (permission.booking || permission.admin) : false);
	}

	intercept(location, permission) {
		const { pathname } = location;
		let routers = []
		const targetRouterConfig = routers.find(
			(item) => item.path === pathname)
		
		if (targetRouterConfig) {
			if (this.canExecute(permission, pathname)) {
				// 如果路由合法，就跳转到相应的路由
				return <Route path={pathname} component={targetRouterConfig.component} />
			} else {
				return <Route path={pathname} component={pathname === '/' ? Bookings : NoAccess} />
			}
		} else {
			let item = _.find(routerMap, r => r.path === pathname);
			return <Route path={pathname} component={item ? item.component : Bookings} />
		}
	}
}

export default AppPermission;