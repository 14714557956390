import React, { Component, useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import AppContextJS from '../AppContext';
import { AppContext } from '../Contexts';
import { withRouter, useLocation, Link } from 'react-router-dom';
import { Button, Table, Divider, Modal, Form, Input, message, Popconfirm, Pagination, Upload, Select, Checkbox, Menu, Dropdown, DatePicker, PageHeader } from 'antd';
import { PlusOutlined, MoreOutlined, InboxOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Dragger } = Upload;
const FormItem = Form.Item;
const { TextArea } = Input;

function MediaManager(props) {
	if (!props.me) return <></>
	const { t } = useTranslation();
	let location = useLocation();
	Cookies.set('location', location.pathname, { expires: 1 });
	const [height, setHeight] = useState(0);
	const measuredRef = useCallback(node => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);
	const routes = [
		{
			path: '/my/booking',
			breadcrumbName: t('myBookings'),
		},
		{
			breadcrumbName: t('mySpaceBooking'),
		}
	]
	return (
		<div className='container' style={{ background: '#fff', overflow: 'hidden' }}>
			<PageHeader
				style={{ borderBottom: '1px solid #f1f1f1' }}
				title={t('mediaManager')}
				// breadcrumb={{ routes }}
				extra={<Button type='primary' icon='plus' onClick={() => props.showFileDialog(true)}>{t('addFile')}</Button>}
			>
			</PageHeader>
			<div ref={measuredRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', background: '#FFFFFF', padding: 24, boxShadow: '0px 0px 8px rgba(221, 229, 234, 0.5)', overflow: 'hidden' }}>
				<div style={{ display: 'flex', marginBottom: 24, justifyContent: 'space-between' }}>
					<div>
						<Input.Search
							style={{ width: 240 }}
							placeholder={t('searchByFileName')}
							// value={props.searchText}
							// onChange={e => props.onKeyChange(e.target.value)}
							onSearch={e => props.search(e)}
						/>
					</div>
					<Pagination
						current={props.page}
						pageSize={props.size}
						showTotal={total => t('itemWithCount', { count: total })}
						total={props.total}
						onChange={props.paginationChange}
					/>
				</div>
				<div style={{ flex: 1 }}>
					<Table
						size='middle'
						bordered={true}
						dataSource={props.meetings}
						pagination={false}
						scroll={{ y: height - 220 }}
						columns={[
							{ title: t('index'), dataIndex: 'index', key: 'index', width: 60, align: 'center', render: (e, record, index) => (index + 1) + (props.page - 1) * props.size },
							{
								title: t('fileName'), dataIndex: 'name', key: 'name', ellipsis: true,
								render: (e, record) => <a href={props.httpService + '/v2' + record.url}>{record.name}</a>
							},
							{
								title: t('type'), dataIndex: 'type', key: 'type', width: 120, ellipsis: true,
								render: e => e === 'video' ? t('video') : t('image')
							},
							{
								title: t('size'), dataIndex: 'size', key: 'size', width: 150, ellipsis: true,
								render: e => e / 1000 < 1000 ? Math.round(e / 1000) + 'KB' : e / 1000000 < 1000 ? Math.round(e / 1000000) + 'MB' : e
							},
							{
								title: t('lastUpdated'), dataIndex: 'updatedAt', key: 'updatedAt', width: 180,
								render: e => moment(e * 1000).format('YYYY-MM-DD HH:mm')
							},
							{
								title: t('actions'), key: 'action', width: 70,
								render: (e, record) => {
									const menus = (
										<Menu>
											<Menu.Item>
												<a onClick={() => props.showFileDialog(true, record)}>{t('modify')}</a>
											</Menu.Item>
											<Menu.Item>
												<a onClick={() => {
													confirm({
														title: t('confirmToDeleteMedia'),
														content: t('thisCannotBeUndone'),
														onOk: () => {
															props.deleteMedia(record.id)
														},
														onCancel() {
														},
													});
												}}>{t('delete')}</a>
											</Menu.Item>
										</Menu>
									);
									return <Dropdown overlay={menus} placement="bottomLeft">
										<MoreOutlined />
									</Dropdown>
								},
							},
						]}
					/>
					<EditFileWrapper
						title={props.selectedFile ? t('modifyFile') : t('addFile')}
						visible={props.fileDialog}
						save={props.onSaveFile}
						onCancel={props.hideFileDialog}
						target={props.selectedFile}
						uploadProps={props.uploadProps}
					/>
				</div>
			</div>
		</div>
	);
}

const EditFileWrapper = withTranslation()(Form.create()(
	class extends Component {
		get t() { return this.props.t; }

		constructor(props) {
			super(props);
			this.state = {
				url: '',
				fileList: [],
				size: 0,
				fileDialog: false,
				fileName: ''
			};
		}

		componentWillReceiveProps(nextProps) {
			if (nextProps.target && nextProps.target !== this.props.target) {
				this.setState({
					fileList: [{
						uid: nextProps.target.url,
						name: nextProps.target.url,
						url: nextProps.target.url,
						status: 'done'
					}],
					url: nextProps.target.url,
					size: nextProps.target.size
				});
			}
		}

		async componentDidMount() {
			this.httpService = AppContextJS.instance.httpService;
			this.token = AppContextJS.instance.token;
		}

		handleChange = ({ file, fileList }) => {
			let url = '', size = 0;
			if (file.status === 'done') {
				url = file.response.path;
				size = file.response.size;
			}
			if (file.status === 'removed') {
				url = '';
			}
			this.setState({ size, url, fileList: fileList.length > 0 ? [_.last(fileList)] : [] });
		}

		save = e => {
			e.preventDefault();
			const { form } = this.props;
			form.validateFields(async (err, values) => {
				if (err) return;
				if (!this.state.url) return message.info(this.t('uploadFileMessage'));
				values.url = this.state.url;
				this.props.save(values);
				this.cancel();
			});
		}

		cancel = () => {
			this.setState({ fileList: [], size: 0, url: '' });
			this.props.form.resetFields();
			this.props.onCancel(false);
		}

		render() {
			const { visible, title, target, onCancel, edit, form, uploadProps, t } = this.props;
			const { getFieldDecorator } = form;
			return (
				<Modal centered
					okText={this.t('confirm')}
					cancelText={this.t('cancel')}
					width="597px"
					title={title}
					visible={visible}
					onOk={this.save}
					onCancel={this.cancel}
				>
					<Form>
						{target &&
							<Form.Item style={{ display: 'none' }}>
								{getFieldDecorator('id', { initialValue: target.id })(<Input />)}
							</Form.Item>
						}
						<FormItem label={t('fileName')} >
							{getFieldDecorator('name', {
								rules: [{ required: true, message: t('enterFileNameMessage') }],
								initialValue: target ? target.name : null
							})(
								<Input />
							)}
						</FormItem>
						<FormItem>
							{getFieldDecorator('url', {
								rules: [{ required: true, message: t('uploadFileMessage') }],
								initialValue: target ? target.url : null
							})(
								<Dragger
									name='file'
									accept='.jpg,.mp4'
									headers={{ Authorization: `Bearer ${this.token}` }}
									action={`${this.httpService}/v2/files/upload`}
									onChange={this.handleChange}
									fileList={this.state.fileList}
								>
									<p className="ant-upload-drag-icon"><InboxOutlined /></p>
									<p className="ant-upload-text">{t('uploadFileText')}</p>
									<p className="ant-upload-hint">{t('uploadFileHint')}</p>
								</Dragger>
							)}
						</FormItem>
					</Form>
				</Modal>
			);
		}
	}
));

let hot = WrappedComponent => {
	return withTranslation()(class EnhancedComponent extends Component {
		get t() { return this.props.t; }
		static contextType = AppContext;
		constructor(props) {
			super(props);
			this.state = {
				searchText: '',
				page: 1,
				size: 20,
				total: 0,
				range: [moment(), moment().clone().add(7, 'days')],
				meetings: [],
				selectedMeetingState: null,
				selectedFile: null
			};
		}

		async componentDidMount() {
			this.httpService = AppContextJS.instance.httpService;
			this.me = this.context.userContext.me;
			this.transportLayer = AppContextJS.instance.transportLayer;
			await this.loadMedia(this.state.page, this.state.size, this.state.range[0].startOf('day').unix(), this.state.range[1].endOf('day').unix());
		}

		async loadMedia() {
			let myBookings = await this.transportLayer.getMedia(this.state.page, this.state.size, this.state.searchText);
			let { docs, page, totalDocs, limit } = myBookings;
			this.setState({ meetings: docs, page, total: totalDocs });
		}

		onKeyChange = async value => {
			this.setState({ searchText: value });
		}


		search = value => {
			this.setState({ searchText: value, page: 1 }, async () => {
				await this.loadMedia();
			});
		}

		paginationChange = async (currentPage, pageSize) => {
			this.setState({ page: currentPage }, async () => {
				await this.loadMedia();
			});
		}

		deleteMedia = async id => {
			await this.transportLayer.deleteMedia(id);
			await this.loadMedia()
		}

		showFileDialog(visible, file) {
			this.setState({ selectedFile: file, fileDialog: true });
		}

		hideFileDialog() {
			this.setState({ selectedFile: null, fileDialog: false });
		}

		onSaveFile = async (values) => {
			console.log('values', values);
			if (values.id) {
				await this.transportLayer.updateMedia(values).then(() => {
					message.success(this.t('fileModificationSucessMessage'));
				}, error => {
					message.error(this.t('fileModificationFailureMessage'));
				});
			} else {
				await this.transportLayer.createMedia(values).then(() => {
					message.success(this.t('fileAdditionSuccessMessage'));
				}, error => {
					message.error(this.t('fileAdditionFailureMessage'));
				});
			}
			await this.loadMedia()
		}

		render() {
			return (
				<WrappedComponent
					me={this.me}
					range={this.state.range}
					page={this.state.page}
					size={this.state.size}
					total={this.state.total}
					meetings={this.state.meetings}
					searchText={this.state.searchText}
					onKeyChange={value => this.onKeyChange(value)}
					search={value => this.search(value)}
					deleteMedia={this.deleteMedia}
					handleQuery={this.handleQuery}
					paginationChange={(currentPage, pageSize) => this.paginationChange(currentPage, pageSize)}
					fileDialog={this.state.fileDialog}
					showFileDialog={(visible, file) => this.showFileDialog(visible, file)}
					hideFileDialog={() => this.hideFileDialog()}
					onSaveFile={values => this.onSaveFile(values)}
					selectedFile={this.state.selectedFile}
					httpService={this.httpService}
				/>
			);
		}
	});
};

export default withRouter(hot(MediaManager));
